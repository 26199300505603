import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import icLogout from "@assets/imgs/ic_logout.png";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Wrapper = styled.div`
  position: absolute;
  right: -100px;
  top: 57px;
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  padding: 40px;
  border-radius: 36px;
  z-index: 100;
  ${mediaQueries("desktop")} {
    right: 0;
  }
`;

const TextLink = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  color: #713bdb;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
  letter-spacing: -0.3px;
`;

const LogoutWrapper = styled.div`
  border-radius: 18px;
  background-color: #713bdb;
  display: flex;
  padding: 5px 30px;
  margin-top: 29px;
  align-items: center;
  cursor: pointer;
  width: 149px;
  height: 36px;
`;

const IcLogout = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

const LogoutText = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-weight: bold;
  color: white;
  font-size: 15px;
`;

const ProfileMenu = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const logout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  const changeLanguage = () => {
    if (i18next.language === "en") {
      history.push("/?lng=ko");
    } else if (i18next.language === "ko") {
      history.push("/?lng=en");
    } else {
      history.push("/?lng=en");
    }
    window.location.reload();
    // if (i18next.language === "en") {
    //   i18next.changeLanguage("ko-KR");
    // } else {
    //   i18next.changeLanguage("en");
    // }
    // window.location.reload();
  };

  return (
    <Wrapper>
      <TextLink onClick={() => history.push("/mypage")}>
        {t("profile.mypage")}
      </TextLink>
      {/*<TextLink>*/}
      {/*  서비스 이용약관*/}
      {/*</TextLink>*/}
      {/*<TextLink>*/}
      {/*  개인정보처리방침*/}
      {/*</TextLink>*/}
      <TextLink
        onClick={() =>
          window.open(
            "https://bo5stars.notion.site/FAQ-f74acd074a644caaa45b39f11339bb3b"
          )
        }
      >
        FAQ
      </TextLink>
      <TextLink onClick={changeLanguage}>한국어 / English</TextLink>
      <LogoutWrapper onClick={logout}>
        <IcLogout src={icLogout} />
        <LogoutText>{t("profile.logout")}</LogoutText>
      </LogoutWrapper>
    </Wrapper>
  );
};

export default ProfileMenu;
