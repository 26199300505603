import { FC, useState } from "react";
import styled from "@emotion/styled";
import Link from "@components/atoms/Link";
import logo from "@assets/imgs/logo_beta_web.png";
import logo_beta_mobile from "@assets/imgs/logo_beta_mobile.png";
import icHamburger from "@assets/imgs/ic_hamburger.png";
import SearchIconImg from "@assets/imgs/search_purple.png";
import { DesktopView, mediaQueries, MobileView } from "@styles/common";
import { useHistory } from "react-router";
import { useUsersState } from "@contexts/users.context";
import Balance from "@components/atoms/Balance";
import ProfileIcon from "@components/atoms/ProfileIcon";
import ProfileMenu from "@components/molecules/ProfileMenu";
import { useTranslation } from "react-i18next";
import MobileHeaderMore from "../MobileHeaderMore";
import SearchBtnImg from "@assets/imgs/btn_search.png";

import MobileSearch from "../MobileSearch";
import useHashQuery from "@query/useHashQuery";
import SmallDesktopSearch from "../SmallDesktopSearch";

export interface HeaderMainProps {}

const Header: FC<HeaderMainProps> = ({}: HeaderMainProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userInfo } = useUsersState();
  const [hover, setHover] = useState<boolean>(false);
  const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false);
  const [showMobileMore, setShowMobileMore] = useState<boolean>(false);
  const [showMobileSearch, setShowMobileSearch] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>("");

  const chooseLogo = () => {
    if (window.screen.width > 768) {
      return logo;
    } else {
      return logo_beta_mobile;
    }
  };
  const search = async (sampleKeyword?: string) => {
    if (history.location.pathname.includes("/search")) {
      history.replace("/search", { keyword: sampleKeyword || keyword });
      setKeyword("");
      return;
    }
    history.push("/search", { keyword: sampleKeyword || keyword });
    setKeyword("");
  };

  const {
    data: hashTags,
    isLoading: isHashTagsLoading,
    isError: isHashTagsError,
  } = useHashQuery();
  return (
    <Container onMouseLeave={() => setHover(false)}>
      <Wrapper>
        <Logo src={chooseLogo()} onClick={() => history.push("/")} />
        <DesktopView>
          <MenuContainer>
            {userInfo && userInfo.is_once_modified && (
              <>
                <StyledLink to="/births">{t("header.birth")}</StyledLink>
                <StyledLink to="/results">{t("header.result")}</StyledLink>
                <StyledLink to="/shop">{t("header.shop")}</StyledLink>
              </>
            )}
          </MenuContainer>

          <MenuContainer>
            <DesktopSearchView>
              <SearchBarWrapper>
                <SearchBarBox isStartedTyping={!!keyword}>
                  <SearchInput
                    value={keyword}
                    isStartedTyping={!!keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    placeholder={t("search.word")}
                  />

                  <SearchBtn onClick={() => search()}>
                    <SearchImg src={SearchBtnImg} />
                  </SearchBtn>
                  {keyword && (
                    <SampleKeywordWapper>
                      {!isHashTagsLoading || !isHashTagsError ? (
                        hashTags?.map((item: any) => {
                          return (
                            <SmableKeywordBtn
                              onClick={() => {
                                setKeyword(item.name);
                                search(item.name);
                              }}
                            >
                              <SampleKeyword key={item.hashtag_id}>
                                {item.name}
                              </SampleKeyword>
                            </SmableKeywordBtn>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </SampleKeywordWapper>
                  )}
                </SearchBarBox>
              </SearchBarWrapper>
            </DesktopSearchView>
            <DesktopSmallSearchView>
              <SearchBtnDesktopSmall
                onClick={() => {
                  setShowSearch((prev) => !prev);
                }}
              >
                <SearchIcon src={SearchIconImg} />
              </SearchBtnDesktopSmall>
            </DesktopSmallSearchView>
            {showSearch && <SmallDesktopSearch />}
            {userInfo && userInfo.is_once_modified ? (
              <FlexNav>
                <Balance
                  balance={
                    (userInfo.bonus_token_balance || 0) +
                    (userInfo.normal_token_balance || 0)
                  }
                  onClick={() => history.push("/shop")}
                />
                <ProfileIcon
                  user_id={userInfo.id + ""}
                  img_url={"https://picsum.photos/200"}
                  onClick={() => setShowProfileMenu((v) => !v)}
                />
              </FlexNav>
            ) : (
              <>
                {/*<StyledLink to='/births'>{t('header.birth')}</StyledLink>*/}
                {/*<StyledLink to='/results'>{t('header.result')}</StyledLink>*/}
                <StyledLink to="/login">{t("header.login")}</StyledLink>
              </>
            )}
          </MenuContainer>

          {showProfileMenu && <ProfileMenu />}
        </DesktopView>
        <MobileView>
          <HamburerWrapper onClick={() => setShowMobileMore(true)}>
            <IcHamburger src={icHamburger} />
          </HamburerWrapper>
          <SearchWrapper>
            <SearchBtnMobile onClick={() => setShowMobileSearch(true)}>
              <SearchIcon src={SearchIconImg} />
            </SearchBtnMobile>
          </SearchWrapper>
        </MobileView>
      </Wrapper>
      <MobileHeaderMore
        show={showMobileMore}
        userInfo={userInfo}
        setShowMobileMore={setShowMobileMore}
      />
      <MobileSearch
        show={showMobileSearch}
        onDismiss={() => setShowMobileSearch(false)}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 56px;
  position: fixed;
  z-index: 1000;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaQueries("mobileL")} {
    z-index: 9;
  }
`;

const Wrapper = styled.div`
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  min-width: 1200px;
  position: relative;
  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    margin: 0 auto;
  }

  ${mediaQueries("tablet")} {
    justify-content: center;
  }
`;

const StyledLink = styled(Link)`
  margin-right: 3rem;
  white-space: nowrap;
  ${mediaQueries("desktopSmall")} {
    margin-right: 1.5rem;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: NotoSansCJKkr-Bold, sans-serif;
  font-size: 14px;
`;

const Logo = styled.img`
  cursor: pointer;
  width: 144.5px;
  ${mediaQueries("mobileL")} {
    width: 128px;
  }
`;

const FlexNav = styled.nav`
  display: flex;
  align-items: center;
`;

const HamburerWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
`;

const SearchWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
const IcHamburger = styled.img`
  width: 20px;
`;

interface ISearchBarWrapper {
  isStartedTyping: boolean;
}

const SearchBarWrapper = styled.div`
  position: relative;
  width: 320px;
  height: 34px;
  margin-right: 32px;
  z-index: 1;
  ${mediaQueries("desktopSmall")} {
    width: 250px;
  }
`;
const SearchBarBox = styled.div<ISearchBarWrapper>`
  display: flex;
  justify-content: space-between;
  width: 320px;
  border: solid 2px #d8d8d8;
  background-color: #ffffff;
  height: ${({ isStartedTyping }) => (isStartedTyping ? "auto" : "34px")};
  border-radius: 17px;
  padding-left: 16px;
  position: absolute;
`;

interface ISearchInput {
  isStartedTyping: boolean;
}

const SearchInput = styled.input<ISearchInput>`
  font-family: "Noto Sans KR", sans-serif;
  outline: none;
  width: 250px;
  font-size: 12px;
  font-weight: bold;
  border: none;
  height: 30px;
  border-bottom: ${({ isStartedTyping }) =>
    isStartedTyping ? "2px solid #d8d8d8" : "none"};
  position: absolute;
  top: 0px;
  &::placeholder {
    color: #d8d8d8;
  }
  ${mediaQueries("desktopSmall")} {
    width: 200px;
  }
`;

const SearchBtn = styled.button`
  cursor: pointer;
  position: absolute;
  height: 34px;
  width: 34px;
  top: -2px;
  right: -2px;
  margin: 0px;
  padding: 0px;
  border: none;
  background-color: transparent;
`;
const SearchImg = styled.img`
  height: 34px;
`;

const SampleKeywordWapper = styled.div`
  padding-top: 34px;
`;
const SmableKeywordBtn = styled.button`
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
const SampleKeyword = styled.p`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  margin-bottom: 7px;
  margin-top: 7px;
  font-weight: bold;
  color: #d8d8d8;
  text-align: left;
`;

const SearchBtnDesktopSmall = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
const SearchBtnMobile = styled.button`
  border: none;
  background-color: transparent;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const SearchIcon = styled.img`
  width: 18px;
`;

const DesktopSearchView = styled.div`
  @media (max-width: 1130px) {
    display: none;
  }
`;
const DesktopSmallSearchView = styled.div`
  display: none;
  @media (max-width: 1130px) {
    display: block;
    margin-right: 32px;
  }
`;
export default Header;
