const dev = {
  API_URL: 'https://hl5b8vzfkf.execute-api.ap-northeast-2.amazonaws.com/dev',
  // API_URL: 'http://localhost:8000',
  GOOGLE_GEOCODE_KEY: 'AIzaSyCty2BQ0IHvotKcg7rIut4WJ1AQiLtpw9A',
  KAKAO_KEY: 'ae62d23782cc54962babd3ff47be9ebb',
  // PAYPAL_CLIENT_ID: 'AS_0NeVollFlkzx8_034ebjWjh15u4KeLsqKTjEr3dIiaBalKtBUm_VHhT4XUZQ-U8_q7U181aWccoSs',  // sandbox
  PAYPAL_CLIENT_ID: 'AQ5rM52M710-bBiVH2EMF7rGrMFA_L2RnCiwL6WSJfoLdl7JRBzJoEyS7oJ7mrVShk335Fh-RIu29M-x',
  NAVER_LOGIN: {
    CLIENT_ID: 'mGpejs08A8xCauIN3ys3',
    CALLBACK_URL: 'http://localhost:3000/login',
  }
};

const staging = {
  API_URL: 'https://jmstr0pyi2.execute-api.ap-northeast-2.amazonaws.com/dev',
  // API_URL: 'http://localhost:8000',
  GOOGLE_GEOCODE_KEY: 'AIzaSyCty2BQ0IHvotKcg7rIut4WJ1AQiLtpw9A',
  KAKAO_KEY: 'ae62d23782cc54962babd3ff47be9ebb',
  PAYPAL_CLIENT_ID: 'AQ5rM52M710-bBiVH2EMF7rGrMFA_L2RnCiwL6WSJfoLdl7JRBzJoEyS7oJ7mrVShk335Fh-RIu29M-x',
  NAVER_LOGIN: {
    CLIENT_ID: 'mGpejs08A8xCauIN3ys3',
    CALLBACK_URL: 'https://spoon5.com/login',
  }
};

const prod = {
  API_URL: 'https://jmstr0pyi2.execute-api.ap-northeast-2.amazonaws.com/dev',
  // API_URL: 'http://localhost:8000',
  GOOGLE_GEOCODE_KEY: 'AIzaSyCty2BQ0IHvotKcg7rIut4WJ1AQiLtpw9A',
  KAKAO_KEY: 'ae62d23782cc54962babd3ff47be9ebb',
  PAYPAL_CLIENT_ID: 'AQ5rM52M710-bBiVH2EMF7rGrMFA_L2RnCiwL6WSJfoLdl7JRBzJoEyS7oJ7mrVShk335Fh-RIu29M-x',
  NAVER_LOGIN: {
    CLIENT_ID: 'mGpejs08A8xCauIN3ys3',
    CALLBACK_URL: 'https://spoon5.com/login',
  }
};

const getDynamicConfig = () => {
  const env = process.env.REACT_APP_ENV;
  if (env === 'staging') {
    return staging;
  } else if (env === 'production') {
    return prod;
  } else {
    return dev;
  }
};

const config = getDynamicConfig();

export default {
  ...config,
};
