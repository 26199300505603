import styled from "@emotion/styled";
import ExceptionPng from "@assets/imgs/exception.png";
import { mediaQueries } from "@styles/common";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
`;

const ExceptionImg = styled.img`
  width: 300px;
  height: 300px;
  ${mediaQueries("mobileL")} {
    width: 230px;
    height: 230px;
  }
`;

const ErrorMessage = styled.p`
  font-size: 20px;
  font-weight: 700;
  ${mediaQueries("mobileL")} {
    font-size: 15px;
  }
`;
const Exception = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <div>
          <ExceptionImg src={ExceptionPng} />
          <ErrorMessage>{t("error.exception_error")}</ErrorMessage>
        </div>
      </Container>
    </>
  );
};

export default Exception;
