import styled from "@emotion/styled";
import React from "react";

interface ITab {
  tabs: string[];
  selectedTab: string;
  onTabClick: (selectedTab: string) => void;
}

const DateTab: React.FC<ITab> = (props) => {
  const { selectedTab, onTabClick, tabs } = props;

  return (
    <Wrapper>
      {tabs.map((item: string) => (
        <Content
          selected={item === selectedTab}
          onClick={() => onTabClick(item)}
        >
          <Title selected={item === selectedTab}>{item}</Title>
        </Content>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;
interface IContent {
  selected: boolean;
}
const Content = styled.div<IContent>`
  width: 50%;
  display: block;
  border-bottom: ${({ selected }) =>
    `5px solid ${selected ? "#2d2d2d" : "#eeeeee"}`};
  cursor: pointer;
  transition: 0.2s;
`;

interface ITitle {
  selected: boolean;
}
const Title = styled.p<ITitle>`
  font-size: 22px;
  color: ${({ selected }) => (selected ? "#2d2d2d" : "#b8b8b8")};
  font-weight: bold;
  font-family: NotoSansCJKkr-Bold, sans-serif;
  margin-bottom: 18px;
  transition: 0.2s;
`;
export default DateTab;
