import { GetCardResultResModel } from "@api/card.result.api";
import icRevision from "@assets/imgs/revision-regular@2x.png";
import ResultShare from "@components/molecules/ResultShare";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Stormy from "@assets/imgs/stormy.png";
import Rainy from "@assets/imgs/rainy.png";
import Cloudy from "@assets/imgs/cloudy.png";
import PartlySunny from "@assets/imgs/partlysunny.png";
import VeryBright from "@assets/imgs/verybright.png";
import ArrowDown from "@assets/imgs/arrow_down.png";
import useIsLoggedIn from "@hooks/useIsLoggedIn";
import CardResultLoginForm from "../CardResultLoginForm";

interface LoveLucksResultProps {
  result: GetCardResultResModel;
  result_id: string;
  thumbnail_url: string;
  type: string;
}

const LoveLucks = ({
  result,
  result_id,
  thumbnail_url,
  type,
}: LoveLucksResultProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const isHarmony = type === "today_love_harmony";

  const isLoggedIn = useIsLoggedIn();

  const getIcon = useCallback((totalScore: number) => {
    if (totalScore <= 35) {
      return <IconImage src={VeryBright} />;
    }
    if (totalScore > 35 && totalScore <= 60) {
      return <IconImage src={PartlySunny} />;
    }
    if (totalScore > 60 && totalScore <= 80) {
      return <IconImage src={Cloudy} width="270px" height="230px" />;
    }
    if (totalScore > 80 && totalScore <= 95) {
      return (
        <IconImage
          src={Rainy}
          width="200px"
          height="280px"
          mobileWidth="100px"
          mobileHeight="120px"
        />
      );
    }
    return <IconImage src={Stormy} width="230px" height="300px" />;
  }, []);
  const getIconForHarmony = useCallback((totalScore: number) => {
    if (totalScore <= 35) {
      return <IconImage src={Stormy} width="230px" height="300px" />;
    }
    if (totalScore > 35 && totalScore <= 60) {
      return (
        <IconImage
          src={Rainy}
          width="200px"
          height="280px"
          mobileWidth="100px"
          mobileHeight="120px"
        />
      );
    }
    if (totalScore > 60 && totalScore <= 80) {
      return <IconImage src={Cloudy} width="270px" height="230px" />;
    }
    if (totalScore > 80 && totalScore <= 95) {
      return <IconImage src={PartlySunny} />;
    }
    return <IconImage src={VeryBright} />;
  }, []);

  const getScoreCaption = useCallback((totalScore: number) => {
    if (totalScore <= 35) {
      return "아주 낮음";
    }
    if (totalScore > 35 && totalScore <= 60) {
      return "낮음";
    }
    if (totalScore > 60 && totalScore <= 80) {
      return "살짝 높음";
    }
    if (totalScore > 80 && totalScore <= 95) {
      return "높음";
    }
    return "아주 높음";
  }, []);

  const getGradientColors = useCallback((totalScore: number): string => {
    if (totalScore <= 35) {
      return "#ff8c68, #ec2e45";
    }
    if (totalScore > 35 && totalScore <= 60) {
      return "#fbeb99, #efbb36";
    }
    if (totalScore > 60 && totalScore <= 80) {
      return "#B7FEC63B, #306e76";
    }
    if (totalScore > 80 && totalScore <= 95) {
      return "#b0dbe16b, #082f67";
    }
    return "#b2abec, #333040";
  }, []);
  const getGradientColorsForHarmony = useCallback(
    (totalScore: number): string => {
      if (totalScore <= 35) {
        return "#b2abec, #333040";
      }
      if (totalScore > 35 && totalScore <= 60) {
        return "#b0dbe16b, #082f67";
      }
      if (totalScore > 60 && totalScore <= 80) {
        return "#B7FEC63B, #306e76";
      }
      if (totalScore > 80 && totalScore <= 95) {
        return "#fbeb99, #efbb36";
      }
      return "#ff8c68, #ec2e45";
    },
    []
  );

  const formatScore = useCallback((score: number) => {
    return +score?.toFixed(1) ?? 0;
  }, []);

  const totalScore = formatScore(
    result.relation_lucks?.쌍방_점수?.Final_종합점수
  );

  const myScore = formatScore(result.relation_lucks?.쌍방_점수?.최종_지수_본인);

  const opponentScore = formatScore(
    result.relation_lucks?.쌍방_점수?.최종_지수_상대
  );

  const loveLucksType = isHarmony ? "조화" : "갈등";
  return (
    <>
      <Wrapper>
        <TopWrapper
          colors={
            isHarmony
              ? getGradientColorsForHarmony(totalScore)
              : getGradientColors(totalScore)
          }
        >
          <TopInnerWrapper>
            <TopIconWrapper>
              {isHarmony ? getIconForHarmony(totalScore) : getIcon(totalScore)}
            </TopIconWrapper>
            <TopContentWrapper>
              <TopContentTitle>{`종합 ${loveLucksType} 지수`}</TopContentTitle>
              <TotalScore>{totalScore}</TotalScore>
              <TopContentSubTitle>{`${loveLucksType} 지수 ${getScoreCaption(
                totalScore
              )}`}</TopContentSubTitle>
              <SolutionWrapper>
                <SolutionDescription>
                  {result?.relation_lucks?.솔루션.map((text: string) => (
                    <p>{text}</p>
                  ))}
                </SolutionDescription>
              </SolutionWrapper>
              <TopScoreWrapper>
                <ScoreContent>
                  <TopContentSubTitle>{`나의 ${loveLucksType} 지수`}</TopContentSubTitle>
                  <Score>{myScore}</Score>
                </ScoreContent>
                <ScoreContent>
                  <TopContentSubTitle>{`상대의 ${loveLucksType} 지수`}</TopContentSubTitle>
                  <Score>{opponentScore}</Score>
                </ScoreContent>
              </TopScoreWrapper>
            </TopContentWrapper>
          </TopInnerWrapper>
          <div>
            <Description>속마음은 이럴 수 있어.</Description>
            <img src={ArrowDown} alt="arrow" />
          </div>
        </TopWrapper>

        <ContentWrapper>
          <MainContentWrapper>
            {/* <MainTitle>{`나의 환경`}</MainTitle> */}
            {/* <MainTitle>{`나의 ${loveLucksType} 지수`}</MainTitle>
            <MainScore>{myScore}</MainScore> */}
            {/* 
            <MainContent>
              <MainSubTitle>활동환경</MainSubTitle>
              <MainDescription>
                {result.relation_lucks.쌍방_text.본인의_활동환경.map(
                  (text: string) => (
                    <p>{text}</p>
                  )
                )}
              </MainDescription>
            </MainContent>

            <MainContent>
              <MainSubTitle>정신적 추구성</MainSubTitle>
              <MainDescription>
                {result.relation_lucks.쌍방_text.본인의_정신적_추구성.map(
                  (text: string) => (
                    <p>{text}</p>
                  )
                )}
              </MainDescription>
            </MainContent>
            <MainContent>
              <MainSubTitle>현실상황</MainSubTitle>
              <MainDescription>
                {result.relation_lucks.쌍방_text.본인의_현실상황.map(
                  (text: string) => (
                    <p>{text}</p>
                  )
                )}
              </MainDescription>
            </MainContent> */}
            {!!result.relation_lucks.쌍방_text.본인의_속마음.length && (
              <MainContent>
                <MainSubTitle>나의 속마음</MainSubTitle>
                <MainDescription>
                  {result.relation_lucks.쌍방_text.본인의_속마음.map(
                    (text: string) => (
                      <p>{text}</p>
                    )
                  )}
                </MainDescription>
              </MainContent>
            )}

            {/* {!!result.relation_lucks.쌍방_text.본인의_특이상황.length && (
              <MainContent>
                <MainSubTitle>특이상황</MainSubTitle>
                <MainDescription>
                  {result.relation_lucks.쌍방_text.본인의_특이상황.map(
                    (text: string) => (
                      <p>{text}</p>
                    )
                  )}
                </MainDescription>
              </MainContent>
            )} */}

            <Spacing />
            {/* <MainTitle>{`상대의 환경`}</MainTitle> */}
            {/* <MainTitle>{`상대의 ${loveLucksType} 지수`}</MainTitle>
            <MainScore>{opponentScore}</MainScore> */}
            {/* 
            <MainContent>
              <MainSubTitle>활동환경</MainSubTitle>
              <MainDescription>
                {result.relation_lucks.쌍방_text.상대의_활동환경.map(
                  (text: string) => (
                    <p>{text}</p>
                  )
                )}
              </MainDescription>
            </MainContent>

            <MainContent>
              <MainSubTitle>정신적 추구성</MainSubTitle>
              <MainDescription>
                {result.relation_lucks.쌍방_text.상대의_정신적_추구성.map(
                  (text: string) => (
                    <p>{text}</p>
                  )
                )}
              </MainDescription>
            </MainContent>
            <MainContent>
              <MainSubTitle>현실상황</MainSubTitle>
              <MainDescription>
                {result.relation_lucks.쌍방_text.상대의_현실상황.map(
                  (text: string) => (
                    <p>{text}</p>
                  )
                )}
              </MainDescription>
            </MainContent> */}
            {!!result.relation_lucks.쌍방_text.상대의_속마음.length && (
              <MainContent>
                <MainSubTitle>상대의 속마음</MainSubTitle>
                <MainDescription>
                  {result.relation_lucks.쌍방_text.상대의_속마음.map(
                    (text: string) => (
                      <p>{text}</p>
                    )
                  )}
                </MainDescription>
              </MainContent>
            )}
            {/* 
            {!!result.relation_lucks.쌍방_text.상대의_특이상황.length && (
              <MainContent>
                <MainSubTitle>특이상황</MainSubTitle>
                <MainDescription>
                  {result.relation_lucks.쌍방_text.상대의_특이상황.map(
                    (text: string) => (
                      <p>{text}</p>
                    )
                  )}
                </MainDescription>
              </MainContent>
            )} */}
          </MainContentWrapper>
          {isLoggedIn && (
            <ResultShare
              link={result.link}
              thumbnail_url={thumbnail_url}
              description={result.description}
            />
          )}
          <CardResultLoginForm type={result.type} />
        </ContentWrapper>
      </Wrapper>
      <BottomWrapper style={{ marginTop: "30px" }}>
        {/* <CommentAgain>{result?.comment[3]}</CommentAgain> */}
        <Button onClick={() => history.push(`/cards/${result_id}`)}>
          <IcRevision src={icRevision} />
          <pre>
            <Text>{t("card_result.button")}</Text>
          </pre>
        </Button>
      </BottomWrapper>
    </>
  );
};

interface ButtonProps {
  displayed?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-width: 768px;
  margin: 0 auto;
  border-radius: 36px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  overflow: hidden;

  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("tablet")} {
    max-width: calc(100%);
    min-width: calc(100%);
  }
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    margin: 0;
    padding-top: 0;
  }
`;

interface IIconImage {
  width?: string;
  height?: string;
  mobileWidth?: string;
  mobileHeight?: string;
}
const IconImage = styled.img<IIconImage>`
  width: ${({ width }) => (width ? width : "250px")};
  height: ${({ height }) => (height ? height : "250px")};
  ${mediaQueries("tablet")} {
    width: ${({ mobileWidth }) => (mobileWidth ? mobileWidth : "120px")};
    height: ${({ mobileHeight }) => (mobileHeight ? mobileHeight : "120px")};
  }
`;

interface ITopWrapper {
  colors: string;
}
const TopWrapper = styled.div<ITopWrapper>`
  width: 100%;
  padding-bottom: 40px;
  background: ${({ colors }) => `linear-gradient(${colors})`};
`;
const TopInnerWrapper = styled.div`
  width: 100%;
  padding: 70px;
  display: flex;
  border-bottom-right-radius: 36px;
  ${mediaQueries("tablet")} {
    flex-direction: column;
    padding: 40px;
    border-bottom-right-radius: 0px;
  }
`;
const TopIconWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  ${mediaQueries("tablet")} {
    width: 100%;
    justify-content: center;
  }
`;
const TopContentWrapper = styled.div`
  width: 50%;
  text-align: left;
  ${mediaQueries("tablet")} {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
`;

const TopContentTitle = styled.p`
  font-family: NotoSansCJKkr;
  font-size: 22px;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 0px;
`;
const TotalScore = styled.p`
  font-family: Montserrat;
  font-weight: bold;
  color: #ffffff;
  font-size: 80px;
  margin-bottom: 8px;
  margin-top: 8px;
`;
const TopContentSubTitle = styled.p`
  font-family: NotoSansCJKkr;
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 8px;
`;
const SolutionWrapper = styled.div`
  border-bottom: 1.5px solid #ffffff;
`;
const SolutionDescription = styled.p`
  font-family: NotoSansCJKkr;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 24px;
  letter-spacing: -0.16px;
`;
const TopScoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
const ScoreContent = styled.div`
  width: 50%;
`;
const Score = styled.p`
  font-family: Montserrat;
  font-weight: bold;
  color: #ffffff;
  font-size: 46px;
  margin-top: 4px;
  margin-bottom: 4px;
`;
const BottomWrapper = styled(Wrapper)`
  padding: 50px 100px;
  ${mediaQueries("mobileL")} {
    padding: 15px;
  }
`;
const ContentWrapper = styled.div`
  padding: 50px 100px;
  ${mediaQueries("mobileL")} {
    padding: 72px 15px;
  }
`;

const CommentAgain = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  text-align: center;
  margin-top: 10px;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    margin-top: 40px;
  }
`;
const Button = styled.div<ButtonProps>`
  margin: 35px 135px 20px;
  height: 50px;
  padding: 25px 35px;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  ${mediaQueries("mobileL")} {
    margin: 35px 22px 20px;
  }
`;
const IcRevision = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 14px;
`;
const Text = styled.div`
  margin: 3px 0 1px 14px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: -0.36px;
  text-align: center;
  color: #ffffff;
`;
const MainContentWrapper = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 100px;
  ${mediaQueries("tablet")} {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 0px;
  }
`;
const MainContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  ${mediaQueries("tablet")} {
    flex-direction: column;
  }
`;
const MainTitle = styled.p`
  font-family: NotoSansCJKkr-Black;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.6px;
  color: #2d2d2d;
  margin-bottom: 20px;
  white-space: nowrap;
`;
const MainScore = styled.p`
  font-family: Montserrat;
  font-weight: bold;
  color: #2d2d2d;
  font-size: 50px;
  margin-bottom: 20px;
  margin-top: 8px;

  ${mediaQueries("tablet")} {
    margin-bottom: 30px;
  }
`;
const MainSubTitle = styled.p`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
  color: #2d2d2d;
  min-width: 200px;
  white-space: nowrap;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 0px;
  ${mediaQueries("tablet")} {
    min-width: auto;
    width: 100%;
    margin-top: 0px;
  }
`;
const MainDescription = styled.p`
  font-family: NotoSansCJKkr-Light;
  font-size: 16px;
  color: #2d2d2d;
  margin-bottom: 0px;
  margin-top: 0px;
  ${mediaQueries("tablet")} {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;

const Spacing = styled.div`
  width: 100%;
  border-bottom: 1.5px solid #eaeaea;
  margin-top: 48px;
  margin-bottom: 60px;
  ${mediaQueries("tablet")} {
    margin-top: 30px;
    margin-bottom: 50px;
  }
`;

const Description = styled.p`
  font-family: NotoSansCJKkr;
  font-size: 16px;
  color: #ffffff;
`;

export default LoveLucks;
