import styled from '@emotion/styled';
import { mediaQueries } from '@styles/common';
import React from "react";

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 24px;
  ${mediaQueries('desktop')} {
    margin-right: 12px;
  }
  &:last-child {
    margin-right: 0px;
  }
  ${mediaQueries('mobileL')} {
    margin-bottom: 10px;
  }
`;
const Wrapper = styled.div<Partial<RadioProps>>`
  width: 20px;
  height: 20px;
  border-radius: 24px;
  border: ${({ selected }) => selected ? 'solid 2px #642ad6' : 'solid 2px #d8d8d8'};
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 12px;
  background-color: #642ad6;
`;

const Label = styled.div<Partial<RadioProps>>`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 0.88;
  letter-spacing: -0.32px;
  text-align: left;
  color: ${({ selected }) => selected ? '#642ad6' : '#d8d8d8'};
  margin-left: 12px;
  ${mediaQueries('desktop')} {
    margin-left: 7px;
  }
`;
const AgreementLabel = styled.text`
  text-decoration: underline;
`;

export interface RadioProps {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  url?: string;
  agreement?: string;
  selected: boolean;
  label: string;
  style?: React.CSSProperties;
}

export default function Radio({
  onClick,
  selected,
  label,
  agreement,
  url,
  style,
}: RadioProps) {
  return (
    <Container onClick={onClick} style={style}>
      <Wrapper selected={selected}>
        {selected && <Circle />}
      </Wrapper>
      {!!label && <Label onClick={onClick} selected={selected}>{label}  <AgreementLabel onClick={() => window.open(url)}>{agreement}</AgreementLabel></Label>}
    </Container>
  );
}
