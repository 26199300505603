import { GetCardResultResModel } from "@api/card.result.api";
import icRevision from "@assets/imgs/revision-regular@2x.png";
import ListWithSideBar from "@components/molecules/ListWithSideBar";
import ResultShare from "@components/molecules/ResultShare";

import styled from "@emotion/styled";
import useIsLoggedIn from "@hooks/useIsLoggedIn";
import { mediaQueries } from "@styles/common";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

interface ButtonProps {
  displayed?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-width: 768px;
  margin: 0 auto;
  border-radius: 36px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  overflow: hidden;

  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("tablet")} {
    max-width: calc(100%);
    min-width: calc(100%);
    border-radius: 0;
  }
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    margin: 0;
    padding-top: 0;
    padding-bottom: 40px;
  }
`;
const TopImg = styled.img`
  width: 100%;
  height: 100%;
  ${mediaQueries("tablet")} {
    border-bottom-right-radius: 36px;
  }
`;
const BottomWrapper = styled(Wrapper)`
  padding: 50px 100px;
  ${mediaQueries("mobileL")} {
    padding: 15px;
  }
`;
const ContentWrapper = styled.div`
  padding: 50px 100px;
  ${mediaQueries("mobileL")} {
    padding: 50px 40px;
  }
`;
const Title = styled.div`
  font-family: Noto Sans KR;
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 60px;
`;
const Comment = styled.div`
  margin-top: 60px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.16px;
  text-align: left;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    margin-top: 40px;
  }
`;
const CommentAgain = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  text-align: center;
  margin-top: 10px;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    margin-top: 40px;
  }
`;
const Button = styled.div<ButtonProps>`
  margin: 35px 135px 20px;
  height: 50px;
  padding: 25px 35px;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  ${mediaQueries("mobileL")} {
    margin: 35px 22px 20px;
  }
`;
const IcRevision = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 14px;
`;
const Text = styled.div`
  margin: 3px 0 1px 14px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: -0.36px;
  text-align: center;
  color: #ffffff;
`;

const ShowMoreWrapper = styled.div`
  width: 100%;
  align-items: center;
`;
const ShowMoreBox = styled.div`
  width: 296px;
  height: 152px;
  border-radius: 20px;
  background-color: #ffcfdf;
`;
interface ObesessionResultProps {
  result: GetCardResultResModel;
  result_id: string;
  scoop_button_displayed?: boolean;
  thumbnail_url: string;
  title?: string;
  result_img?: string;
  characters?: any;
}

const HiddenCharacters = ({
  result,
  result_id,
  result_img,
  scoop_button_displayed,
  thumbnail_url,

  title,
  characters,
}: ObesessionResultProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [cardResult, setCardResult] = useState<
    { title: string; content: string[] }[]
  >([]);
  console.log(characters);

  useEffect(() => {
    for (const prop in characters) {
      setCardResult((prev) =>
        prev.concat({
          title: prop,
          content: characters[prop],
        })
      );
    }
  }, [characters]);

  return (
    <>
      <Wrapper>
        <TopImg src={result_img} />
        <ContentWrapper>
          {title && <Title>{title}</Title>}
          {cardResult.map((item, index) => (
            <ListWithSideBar title={item.title} result={item.content} />
          ))}

          {result.comment && result.comment.length > 0 && (
            <Comment style={{ marginTop: "0px", marginBottom: "80px" }}>
              {result.comment[0]}
            </Comment>
          )}
          <ResultShare
            link={result.link}
            thumbnail_url={thumbnail_url}
            description={result.description}
          />
        </ContentWrapper>
      </Wrapper>
      <BottomWrapper style={{ marginTop: "30px" }}>
        <CommentAgain>{result.comment[3]}</CommentAgain>
        <Button onClick={() => history.push(`/cards/${result_id}`)}>
          <IcRevision src={icRevision} />
          <pre>
            <Text>{t("card_result.button")}</Text>
          </pre>
        </Button>
      </BottomWrapper>
    </>
  );
};

export default HiddenCharacters;
