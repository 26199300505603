import styled from "@emotion/styled";
import logo from "@assets/imgs/logo_beta_web.png";
import { DesktopView, mediaQueries } from "@styles/common";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useCardIdDispatch } from "@contexts/cardId.context";
import { useCallback } from "react";

const Wrapper = styled.div`
  background-color: #713bdb;
  color: white;
  padding: 67px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mediaQueries("mobileL")} {
    padding: 67px 10px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  min-width: 1200px;
  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  text-align: left;
  width: 358px;
`;

const InfoWrapper = styled(ContentWrapper)`
  ${mediaQueries("mobileL")} {
    width: 275px;
  }
`;
const Logo = styled.img`
  margin-bottom: 40px;
  width: 250px;
  cursor: pointer;
  ${mediaQueries("mobileL")} {
    width: 200px;
    margin-bottom: 20px;
  }
`;
const Title = styled.div`
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 25px;
  ${mediaQueries("mobileL")} {
    width: 215px;
  }
`;
const ContentText = styled.div`
  font-size: 15px;
  margin-bottom: 25px;
  cursor: pointer;
  ${mediaQueries("mobileL")} {
    width: 215px;
  }
`;
const Gutter = styled.div`
  flex: 0.2;
`;
const ContactWrapper = styled(ContentWrapper)`
  margin-top: 105px;
  flex: 1;
  ${mediaQueries("mobileL")} {
    margin-top: 50px;
    width: 275px;
  }
`;
const ServicesWrapper = styled(ContentWrapper)`
  font-weight: bold;
  margin-top: 105px;
  flex: 1;
  ${mediaQueries("mobileL")} {
    margin-top: 50px;
    width: 275px;
  }
`;

const Footer = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const cardIdDispatch = useCardIdDispatch();

  const resetCardId = useCallback(() => {
    cardIdDispatch({ type: "SET_CARD_ID", cardId: "" });
  }, [cardIdDispatch]);

  const goToBirths = () => {
    resetCardId();
    history.push("/births");
  };

  const goToResults = () => {
    resetCardId();
    history.push("/results");
  };

  return (
    <Wrapper>
      <Container>
        <InfoWrapper>
          <Logo src={logo} onClick={() => history.push("/")} />
          <DesktopView>
            <Title style={{ fontSize: "18px", marginBottom: "21px" }}>
              {t("footer.service_name")}
            </Title>
          </DesktopView>
          <DesktopView>
            <ContentText style={{ lineHeight: "1.5" }}>
              {t("footer.company")}
            </ContentText>
          </DesktopView>
          <ContentText style={{ fontWeight: "bold", marginBottom: "0px" }}>
            {t("footer.brand")}
          </ContentText>
          <ContentText style={{ fontWeight: "bold" }}>
            Copyright Ⓒ 2021 Spoon5.
            <br /> All Rights Reserved
          </ContentText>
        </InfoWrapper>
        <Gutter />
        <ContactWrapper>
          <Title
            onClick={() =>
              window.open(
                "https://bo5stars.notion.site/cf7d4cb911654c9790e226afc8912593"
              )
            }
          >
            {t("footer.about_us")}
          </Title>
          <Title
            onClick={() =>
              window.open(
                "https://bo5stars.notion.site/d902eb51118d459da9ec9f0b489c1542"
              )
            }
          >
            {t("footer.partnership")}
          </Title>
          <Title
            onClick={() =>
              window.open(
                "https://bo5stars.notion.site/Contact-Us-caf7c60d43bc4ef6ab27f59d6c13bd7c"
              )
            }
          >
            {t("footer.contact")}
          </Title>
          <Title
            onClick={() =>
              window.open(
                "https://bo5stars.notion.site/Recruitment-bfddccaf77ba4d06ac63784897c28cca"
              )
            }
          >
            {t("footer.recruitment")}
          </Title>
          <Title
            onClick={() =>
              window.open(
                "https://bo5stars.notion.site/SERVICE-GUIDE-157fef28e6374911ab48b3a5d827d3d3"
              )
            }
          >
            {t("footer.service")}
          </Title>
          <ContentText style={{ lineHeight: "1.5" }}></ContentText>
        </ContactWrapper>
        <Gutter />
        <ServicesWrapper>
          <ContentText onClick={goToBirths}>{t("footer.birth")}</ContentText>
          <ContentText onClick={goToResults}>{t("footer.result")}</ContentText>
          {/*<ContentText>*/}
          {/*  블로그*/}
          {/*</ContentText>*/}
          <ContentText
            onClick={() =>
              window.open(
                "https://bo5stars.notion.site/FAQ-f74acd074a644caaa45b39f11339bb3b"
              )
            }
          >
            {t("footer.faq")}
          </ContentText>
          <ContentText
            onClick={() =>
              window.open(
                "https://www.termsfeed.com/live/d45ca735-59ec-4336-acc6-11a08e17ebe4"
              )
            }
          >
            {t("footer.terms_of_service")}
          </ContentText>
          <ContentText
            onClick={() =>
              window.open(
                "https://www.termsfeed.com/live/d316303d-4b6a-4ac7-ad66-1de702d86e7c"
              )
            }
          >
            {t("footer.personal")}
          </ContentText>
        </ServicesWrapper>
      </Container>
    </Wrapper>
  );
};

export default Footer;
