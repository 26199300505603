import i18next from "i18next";
import axios, { AxiosError } from "axios";
import { User } from "../models/users/User";
import apiAgent from "./api.agent";
import { BirthModel } from "./births.api";
import config from "./config";

type SnsType = "naver" | "kakao" | "google" | "facebook";

interface LoginReqModel {
  sns_type: SnsType;
  sns_token: string;
}
interface LoginResModel {
  access_token: string;
  email: string;
  is_success: boolean;
  nickname: string;
  is_once_modified: boolean;
}

interface SignupReqModel {
  sns_type: SnsType;
  sns_token: string;
}
interface SignupResModel {
  access_token: string;
  email: string;
  is_success: boolean;
  nickname: string;
}

interface GetUserReqModel {
  token?: string;
}
interface GetUserResModel extends User {}

export const login = async (req: LoginReqModel) => {
  try {
    const { data } = await apiAgent.axios.post<LoginResModel>(
      config.API_URL + "/v1/users/signin",
      req
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    if (e.response) {
      const { status } = e.response;
      if (status === 422) return "NOT_REGISTERED";
    }
    return null;
  }
};

export const signup = async (req: SignupReqModel) => {
  const lang = i18next.language.slice(0, 2);
  try {
    const { data } = await apiAgent.axios.post<SignupResModel>(
      `${config.API_URL}/v1/users/signup?lang=${lang}`,
      req
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};

export const getUserInfo = async (req: GetUserReqModel) => {
  const token = req.token || localStorage.getItem("token");
  if (!token) return;
  try {
    const { data } = await apiAgent.axios.get<GetUserResModel>(
      config.API_URL + "/v1/users"
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};

interface EditUserReqModel {
  nickname: string;
  birth_datetime: string;
  is_marketing_agreed: boolean;
  is_terms_of_service_agreed?: boolean;
  is_privacy_policy_agreed?: boolean;
  gender: string;
  latitude: number;
  longitude: number;
  lunar: string;
  is_time_known: boolean;
}

export const editUser = async (req: EditUserReqModel) => {
  const token = localStorage.getItem("token");
  if (!token) return;
  try {
    const { data } = await apiAgent.axios.patch<EditUserReqModel>(
      config.API_URL + "/v1/users",
      req
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};
