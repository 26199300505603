import React from "react";
import { UsersProvider } from "./users.context";
import { CardsProvider } from "./cards.context";
import { BirthsProvider } from "./births.context";
import { CommonProvider } from "./common.context";
import { CardIdProvider } from "./cardId.context";

export const combinedContexts = [
  UsersProvider,
  CardsProvider,
  BirthsProvider,
  CommonProvider,
  CardIdProvider,
];

interface IAppProvider {
  contexts: any[];
  children: React.ReactNode;
}
export const AppProvider = ({ contexts, children }: IAppProvider) =>
  contexts.reduce(
    (prev, context) =>
      React.createElement(context, {
        children: prev,
      }),
    children
  );
