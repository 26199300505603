import { useCardIdDispatch, useCardIdState } from "@contexts/cardId.context";
import { useHistory } from "react-router-dom";

const useRedirectCardDetail = () => {
  const { cardId } = useCardIdState();
  const history = useHistory();
  const cardIdDispatch = useCardIdDispatch();

  const redirectCardDetail = () => {
    if (cardId) {
      history.push(`/cards/${cardId}`);
      cardIdDispatch({ type: "SET_CARD_ID", cardId: "" });
      return;
    }
    history.push("/");
  };
  return { redirectCardDetail };
};

export default useRedirectCardDetail;
