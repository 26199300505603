import axios from "axios";
import apiAgent from "./api.agent";
import config from "./config";
import i18next from "i18next";

interface MBTIDesc {
  title: string;
  desc: string;
  image: string;
}

interface GetCardResult {
  title: string;
  percentage: number;
  image: string;
}

export interface Graph {
  title: string;
  desc: string;
  percentage: number;
  color: string;
}

interface GraphType {
  born: Graph[];
  desc: string;
  withluck: Graph[];
}

interface Withluck {
  mbti: string[];
  title: string;
  desc: string;
}

interface Born {
  mbti: string[];
  title: string;
  desc: string;
}

interface Mbti {
  born: Born;
  withluck: Withluck;
}

interface TitleDesc {
  title: string;
  desc: string;
}

export interface GetCardResultResModel {
  card_result_id: number;
  description: string;
  thumbnail_url: string;
  mbti: Mbti;
  born: Born;
  withluck: Withluck;
  spoons: GetCardResult[];
  pros: MBTIDesc[];
  cons: MBTIDesc[];
  graph: GraphType[];
  type: string;
  link: string;
  title: string[];
  comment: string[];
  score_scoop: TitleDesc[];
  list_format_only: string[];
  result_image: string;
  today_love_lucks: any;
  special_day_love_lucks: any;
  relation_lucks: any;
  month_lucks: MonthLucks[][];
  marriage_lucks: MariageLucks[];
  marriage_timing: MariageLucks[];
  card_id: number;
  total_characters: any;
  stocker: string[];
  violence: string[];
  characters: any;
  hate: any;
}

export interface MariageLucks {
  year: number;
  result: any;
}
export interface MonthLucks {
  day: string;
  score: number;
}
interface PersonalInfoModel {
  gender: string;
  latitude: number;
  longitude: number;
  lunar: string;
  is_time_known: boolean;
  birth_datetime: Date;
}

interface GetCardResultReqModel {
  personal_info?: PersonalInfoModel;
  personal_info_2?: PersonalInfoModel;
  endpoint?: string;
  required_starcoin?: number;
  card_id?: string;
  day_info?: string;
}

export const getCardResult = async (req: GetCardResultReqModel) => {
  const token = localStorage.getItem("token");

  const lang = i18next.language.slice(0, 2);

  if (!token) {
    return getCardResultWithoutLogin(req);
  }
  if (!token) return;
  let payload: any = {
    personal_info: req.personal_info,
    endpoint: req.endpoint,
    required_starcoin: req.required_starcoin,
    card_id: req.card_id,
  };
  if (req.personal_info_2) {
    payload["personal_info_2"] = req.personal_info_2;
  }
  if (req.day_info) {
    payload["day_info"] = req.day_info;
  }
  try {
    const { data } = await apiAgent.axios.post<GetCardResultResModel>(
      config.API_URL + "/v1/cards/result?lang=" + lang,
      payload
    );
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCardResultWithoutLogin = async (req: GetCardResultReqModel) => {
  const lang = i18next.language.slice(0, 2);
  let payload: any = {
    personal_info: req.personal_info,
    endpoint: req.endpoint,
    card_id: req.card_id,
  };
  if (req.personal_info_2) {
    payload["personal_info_2"] = req.personal_info_2;
  }
  if (req.day_info) {
    payload["day_info"] = req.day_info;
  }

  try {
    const { data } = await apiAgent.axios.post<GetCardResultResModel>(
      config.API_URL + "/v1/card-results/without-login?lang=" + lang,
      payload
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};

export const getCalendarCardResult = async (req: {
  cardResultId: number;
  date: string;
}) => {
  const token = localStorage.getItem("token");
  const lang = i18next.language.slice(0, 2);
  if (!token) return;

  try {
    const { data } = await apiAgent.axios.get<GetCardResultResModel>(
      config.API_URL +
        `/v1/card-results/${req.cardResultId}/month-date?lang=${lang}&date=${req.date}`
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};

interface GetCardResultListReqModel {
  results_per_page: number;
  page: number;
}
interface GetCardResultListResModel {
  cards: CardResultDetail[];
  page: number;
  results_per_page: number;
  total_results: number;
}

export interface CardResultDetail {
  card_result_id: string;
  card_description: string;
  thumbnail_url: string;
  name: string;
  gender: string;
  birth_datetime: string;
  name_2?: string;
  gender_2?: string;
  birth_datetime_2?: string;
}

export const getCardResultList = async (req: GetCardResultListReqModel) => {
  const token = localStorage.getItem("token");
  if (!token) return;
  try {
    const { data } = await apiAgent.axios.get<GetCardResultListResModel>(
      config.API_URL +
        `/v1/cards/results/user?results_per_page=${req.results_per_page}&page=${req.page}`
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};
interface SearchCardResultListReqModel {
  query: string;
}
interface SearchCardResultListResModel extends GetCardResultListResModel {}

export const searchCardResultList = async (
  req: SearchCardResultListReqModel
) => {
  const { query } = req;
  if (!query) return;

  const token = localStorage.getItem("token");
  if (!token) return;
  try {
    const { data } = await apiAgent.axios.get<SearchCardResultListResModel>(
      config.API_URL + "/v1/search/result?query=" + query
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};

interface GetCardResultDetailReqModel {
  resultId: string;
}
interface GetCardResultDetailResModel extends GetCardResultResModel {}

export const getCardResultDetail = async (req: GetCardResultDetailReqModel) => {
  const token = localStorage.getItem("token");
  if (!token) return;
  try {
    const { data } = await apiAgent.axios.get<GetCardResultDetailResModel>(
      config.API_URL + "/v1/cards/result/" + req.resultId,
      { timeout: 30000 }
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};

export const getCardResultByLink = async (link: string) => {
  try {
    const { data } = await apiAgent.axios.get<GetCardResultResModel>(
      config.API_URL + "/v1/share?link=" + link
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};
