import { GetCardResultResModel } from "@api/card.result.api";
import imgArrowLeft from "@assets/imgs/chevron-down-solid-3@2x.png";
import icRevision from "@assets/imgs/revision-regular@2x.png";
import ResultShare from "@components/molecules/ResultShare";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

interface InputColor {
  inputColor?: string;
}

interface ButtonProps {
  displayed?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-width: 768px;
  margin: 0 auto;
  padding: 50px 100px;
  border-radius: 36px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;

  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("tablet")} {
    padding: 15px;
    max-width: 100%;
    min-width: 100%;
  }
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    margin: 0;
    padding-top: 0;
    padding-bottom: 40px;
  }
`;
const Title = styled.div`
  font-size: 36px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: -0.4px;
  margin: 30px 10px 60px;
  text-align: center;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    margin: 50px 10px 40px 10px;
  }
`;
const SubFlavor = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 0 100px 0;
  ${mediaQueries("mobileL")} {
    flex-direction: column;
    margin: 0 0 75px 0;
  }
`;
const SubFlavorTitle = styled.div`
  width: 128px;
  font-size: 15px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #2d2d2d;
`;
const FlavorDescWrapper = styled.div`
  margin: 20px 0;
  //max-width: 768px;
  //min-width: 768px;
  ${mediaQueries("desktop")} {
    margin: 0 0 0 10px;
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("tablet")} {
    margin: 0px;
    max-width: 100%;
    min-width: 100%;
  }
`;
const FlavorDescTitle = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  margin: 0 120px 20px 120px;
  font-stretch: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  text-align: center;
  color: #2d2d2d;
  // ${mediaQueries("desktop")} {
  //   font-size: 32px;
  //   max-width: calc(100% - 40px);
  //   min-width: calc(100% - 40px);
  // }
  ${mediaQueries("mobileL")} {
    font-size: 16px;
    width: 200px;
    margin: 0 auto;
    line-height: 2;
    margin-bottom: 10px;
  }
`;
const QuotationWrapper = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 50px;
  font-weight: 900;
`;
const FlavorDescSubTitle = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin: 0 0 100px 0;
  //width: 250px;
  font-stretch: normal;
  font-style: italic;
  line-height: 2;
  letter-spacing: -0.32px;
  text-align: center;
  color: #2d2d2d;
  ${mediaQueries("desktop")} {
    font-size: 16px;
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("tablet")} {
    font-size: 16px;
    max-width: 100%;
    min-width: 100%;
  }
  ${mediaQueries("mobileL")} {
    padding: 0 30px;
    margin: 0 0 100px 0;
  }
`;
const ProsCons = styled.div<InputColor>`
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  text-align: left;
  color: ${(props) => props.inputColor || "#713bdb"};
  margin-bottom: 40px;
  ${mediaQueries("tablet")} {
    margin-left: 30px;
  }
  ${mediaQueries("mobileL")} {
    margin: 0 10px 30px;
  }
`;
const Strengths = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 0 50px;
  padding: 20px 0;

  ${mediaQueries("tablet")} {
    //display: flex;
    //align-items: center;
    margin: 0 0 0 20px;
  }
`;
const IcecreamWrapper = styled.div`
  text-align: left;
  ${mediaQueries("tablet")} {
    display: flex;
    margin: 0 0 20px 0;
  }
`;
const IcecreamImage = styled.img`
  height: 90px;
  ${mediaQueries("tablet")} {
    margin: 20px 20px 10px 0px;
  }
  ${mediaQueries("mobileL")} {
    margin: 20px 40px 10px 30px;
  }
`;
const SpoonImage = styled.img`
  width: 116.8px;
  height: 140px;
  margin-right: 77px;
`;
const SpoonImage1 = styled.img`
  height: 140px;
  display: block;
  margin-right: 77px;
  margin-top: 46px;
  ${mediaQueries("tablet")} {
    margin-top: 16px;
    height: 80px;
    margin-right: 50px;
    margin-left: 30px;
  }
  ${mediaQueries("mobileSE")} {
    margin-top: 16px;
    height: 80px;
    margin-right: 20px;
    margin-left: 10px;
  }
`;
const IcecreamPercentageTitle = styled.div`
  width: 125px;
`;
const Percentage = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 40px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.4px;
  text-align: left;
  color: #2d2d2d;
`;
const PercentageIcon = styled.text`
  font-family: Montserrat, sans-serif;
  margin-left: 3px;
  font-size: 20px;
`;
const SpoonTitle = styled.div<InputColor>`
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.inputColor || "#713bdb"};
  ${mediaQueries("mobileL")} {
    margin: 19px 10px 0 13px;
  }
`;
const SpoonDesc = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  margin: 19px 30px 0 0;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.32px;
  text-align: left;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    margin: 19px 10px 0 13px;
  }
`;
const Button = styled.div<ButtonProps>`
  margin: 35px 135px 20px;
  height: 50px;
  padding: 25px 35px;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  ${mediaQueries("mobileL")} {
    margin: 35px 22px 20px;
  }
`;
const IcRevision = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 14px;
  //margin-top: 14px;
`;
const Text = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  margin: 3px 0 1px 14px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: -0.36px;
  text-align: center;
  color: #ffffff;
`;
const BackIcon = styled.img`
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  left: -60px;
  cursor: pointer;
`;
const CommentAgain = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  text-align: center;
  margin-top: 10px;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    margin-top: 40px;
  }
`;

interface MbtiResultProps {
  result: GetCardResultResModel;
  result_id: string;
  scoop_button_displayed?: boolean;
  type: string;
  thumbnail_url: string;
}

const MbtiResult = ({
  result,
  result_id,
  scoop_button_displayed,
  type,
  thumbnail_url,
}: MbtiResultProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <Wrapper>
        <BackIcon src={imgArrowLeft} onClick={() => history.goBack()} />
        <Title>
          <pre>{result.title && result.title[0]}</pre>
        </Title>
        <div style={{ marginTop: "-79px" }}>
          {result.spoons && result.spoons[2] && (
            <SpoonImage
              src={result.spoons[2].image}
              style={{
                position: "relative",
                bottom: "-70px",
                width: "90px",
                height: "90px",
                zIndex: 3,
                margin: 0,
              }}
            />
          )}
          <br />
          {result.spoons && result.spoons[1] && (
            <SpoonImage
              src={result.spoons[1].image}
              style={{
                position: "relative",
                bottom: "-30px",
                width: "110px",
                height: "110px",
                zIndex: 2,
                margin: 0,
                transform: `scaleX(-1)`,
              }}
            />
          )}
          <br />
          {result.spoons && result.spoons[0] && (
            <SpoonImage
              src={result.spoons[0].image}
              style={{
                position: "relative",
                width: "130px",
                height: "130px",
                zIndex: 1,
                margin: 0,
              }}
            />
          )}
          <br />
          <SpoonImage
            src={
              "https://whoami-whoareyou-image.s3.ap-northeast-2.amazonaws.com/ig__corn.png"
            }
            style={{
              position: "relative",
              bottom: "40px",
              width: "90px",
              height: "130px",
              margin: 0,
            }}
          />
          <br />
        </div>
        <SubFlavor>
          {result.spoons.map(({ title, percentage, image }, idx) => (
            <IcecreamWrapper key={"res-spoons-" + idx}>
              <IcecreamImage src={image} />
              <IcecreamPercentageTitle>
                <Percentage>
                  {percentage}
                  <PercentageIcon>%</PercentageIcon>
                </Percentage>
                <SubFlavorTitle>{title.toUpperCase()}</SubFlavorTitle>
              </IcecreamPercentageTitle>
            </IcecreamWrapper>
          ))}
        </SubFlavor>
        <FlavorDescWrapper>
          <FlavorDescTitle>
            {result.comment && result.comment[0]}
          </FlavorDescTitle>
          <FlavorDescSubTitle>
            {result.comment && result.comment[1]}
          </FlavorDescSubTitle>
        </FlavorDescWrapper>
        {type === "mbti-character-merit" ? (
          <>
            <ProsCons>{result.title[1].toUpperCase()}</ProsCons>
            {result.pros.map(({ title, desc, image }, idx) => (
              <Strengths key={"res-pros-" + idx}>
                <SpoonImage1 src={image} />
                <div>
                  <SpoonTitle>{title.toUpperCase()}</SpoonTitle>
                  <SpoonDesc>{desc}</SpoonDesc>
                </div>
              </Strengths>
            ))}
          </>
        ) : (
          <>
            <ProsCons inputColor="#2d2d2d">
              {result.title[1].toUpperCase()}
            </ProsCons>
            {result.cons.map(({ title, desc, image }, idx) => (
              <Strengths key={"res-cons-" + idx}>
                <SpoonImage1 src={image} />
                <div>
                  <SpoonTitle inputColor="#2d2d2d">
                    {title.toUpperCase()}
                  </SpoonTitle>
                  <SpoonDesc>{desc}</SpoonDesc>
                </div>
              </Strengths>
            ))}
          </>
        )}
        <br />
        <br />
        <FlavorDescWrapper style={{ marginTop: "80px", marginBottom: "50px" }}>
          <FlavorDescTitle>
            <QuotationWrapper>"</QuotationWrapper>
            {result.comment && result.comment[2]}
            <QuotationWrapper style={{ marginTop: "20px" }}>"</QuotationWrapper>
          </FlavorDescTitle>
          {/*<br />*/}
          <FlavorDescTitle>
            {result.comment && result.comment[3]}
          </FlavorDescTitle>
        </FlavorDescWrapper>
        <ResultShare
          link={result.link}
          thumbnail_url={thumbnail_url}
          description={result.description}
        />
      </Wrapper>
      <Wrapper style={{ marginTop: "30px" }}>
        <CommentAgain>{result.comment[4]}</CommentAgain>
        <Button onClick={() => history.push(`/cards/${result_id}`)}>
          <IcRevision src={icRevision} />
          <pre>
            <Text>{t("card_result.button")}</Text>
          </pre>
        </Button>
      </Wrapper>
    </>
  );
};

export default MbtiResult;
