import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import imgArrowLeft from "@assets/imgs/chevron-down-solid-3@2x.png";
import starcoin from "@assets/imgs/image_starcoin_s.png";
import { BirthModel } from "@api/births.api";
import { formatDateTime, getAge } from "@utils/datetime.util";
import { useTranslation } from "react-i18next";
import { genderTranslation } from "@utils/gender.util";
import { calendarTranslation } from "@utils/calendar.util";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;
const Dimmed = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(45, 45, 45, 0.5);
`;
const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 100px;
  border-radius: 40px;
  max-width: 582px;
  min-width: 582px;
  max-height: 700px;
  background-color: white;
  height: 100vh;
  overflow-y: scroll;

  ${mediaQueries("mobileL")} {
    border-radius: 0px;
    max-width: 100%;
    min-width: 100%;
    padding: 40px;
    max-height: 100vh;
    overflow: scroll;
    flex-direction: column;
  }
`;
const TitleWrapper = styled.div`
  margin-bottom: 20px;
  ${mediaQueries("tablet")} {
    margin-top: 50px;
  }
`;
const Title = styled.div`
  font-size: 28px;
  font-weight: 900;
  color: #2d2d2d;
  text-align: center;
  margin-top: 25px;
`;
const HelpText = styled.pre`
  font-size: 15px;
  color: #b8b8b8;
  margin-top: 5px;
`;
const DescWrapper = styled.div`
  width: 100%;
`;
const SubTitle = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 22px;
  font-weight: 900;
  color: #2d2d2d;
  margin-bottom: 40px;
`;
const InfoWrapper = styled.div``;
const InfoRow = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #2d2d2d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  margin: 0 auto;
`;
const EachText = styled.div``;
const ButtonWrapper = styled.div`
  width: 100%;
  /* max-width: 300px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto 0;
`;
const Button = styled.div`
  padding: 12px 41px;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  color: white;
  border: none;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 700;
  flex: 1;
  height: 50px;
`;

const CancelButton = styled(Button)`
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #2d2d2d;
  background-image: none;
  margin-right: 10px;
`;

const StyledImg = styled.img`
  width: 44px;
  height: 44px;
`;
const Separator = styled.div`
  width: 100%;
  height: 1.5px;
  margin: 30px 0;
  background-color: #eaeaea;
`;

const BackIconWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: -60px;
  cursor: pointer;
  text-align: left;
  ${mediaQueries("desktopSmall")} {
    position: fixed;
    top: 15px;
    left: 0;
    border-radius: 40px;
  }
  ${mediaQueries("mobileL")} {
    position: fixed;
    top: 15px;
    left: 11px;
    border-radius: 40px;
  }
`;
const BackIcon = styled.img`
  width: 44px;
  height: 44px;
`;

const WarningWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 30px;
`;
const Warning = styled.p`
  color: #713bdb;
  font-size: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
`;
interface UseCoinConfirmModalProps {
  show?: boolean;
  onPrevClick: () => void;
  onConfirmClick: () => void;
  coin: number;
  personalInfo?: BirthModel | null;
  secondPersonInfo?: BirthModel | null;
  disclaimer?: string;
  disclaimer_1?: string;
}

const UseCoinConfirmModal = ({
  show,
  onPrevClick,
  onConfirmClick,
  coin,
  personalInfo,
  secondPersonInfo,
  disclaimer,
  disclaimer_1,
}: UseCoinConfirmModalProps) => {
  const { t } = useTranslation();

  if (!show) return null;

  return (
    <Wrapper>
      <Dimmed onClick={onPrevClick} />
      <ModalWrapper>
        <BackIconWrapper>
          <BackIcon src={imgArrowLeft} onClick={onPrevClick} />
        </BackIconWrapper>
        <TitleWrapper>
          <StyledImg src={starcoin} />
          <Title>
            {t("coin_modal.title_1")} {coin} {t("coin_modal.title_2")}
          </Title>
        </TitleWrapper>
        <HelpText>{coin ? disclaimer_1 : disclaimer}</HelpText>
        <Separator />
        {personalInfo && (
          <DescWrapper>
            <SubTitle>{personalInfo.name}</SubTitle>
            <InfoWrapper>
              <InfoRow>
                <EachText>{genderTranslation(personalInfo.gender)}</EachText>
                <EachText>
                  {getAge(personalInfo.birth_datetime)} {t("coin_modal.years")}
                </EachText>
              </InfoRow>
              <InfoRow style={{ marginTop: 12 }}>
                <EachText>
                  {formatDateTime(personalInfo.birth_datetime)}
                </EachText>
                <EachText>{calendarTranslation(personalInfo.lunar)}</EachText>
              </InfoRow>
            </InfoWrapper>
          </DescWrapper>
        )}
        {secondPersonInfo && (
          <>
            <Separator />
            <DescWrapper>
              <SubTitle>{secondPersonInfo.name}</SubTitle>
              <InfoWrapper>
                <InfoRow>
                  <EachText>
                    {genderTranslation(secondPersonInfo.gender)}
                  </EachText>
                  <EachText>
                    {getAge(secondPersonInfo.birth_datetime)}{" "}
                    {t("coin_modal.years")}
                  </EachText>
                </InfoRow>
                <InfoRow style={{ marginTop: 12 }}>
                  <EachText>
                    {formatDateTime(secondPersonInfo.birth_datetime)}
                  </EachText>
                  <EachText>
                    {calendarTranslation(secondPersonInfo.lunar)}
                  </EachText>
                </InfoRow>
              </InfoWrapper>
            </DescWrapper>
          </>
        )}

        <Separator />
        <WarningWrapper>
          <Warning>{t("coin_modal.warning1")}</Warning>
          <Warning>{t("coin_modal.warning2")}</Warning>
        </WarningWrapper>
        <ButtonWrapper>
          <CancelButton onClick={onPrevClick}>
            {t("coin_modal.cancel")}
          </CancelButton>
          <Button onClick={onConfirmClick}>{t("coin_modal.ok")}</Button>
        </ButtonWrapper>
      </ModalWrapper>
    </Wrapper>
  );
};

export default UseCoinConfirmModal;
