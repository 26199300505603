import { LOGIN_FREE } from "@containers/CardDetail";
import styled from "@emotion/styled";
import useIsLoggedIn from "@hooks/useIsLoggedIn";
import { mediaQueries } from "@styles/common";
import LoginButtons from "../LoginButtons";

const LoginFormWrapper = styled.div`
  padding: 50px 100px;
  align-items: center;
  display: flex;
  flex-direction: column;
  ${mediaQueries("mobileL")} {
    padding: 15px;
  }
`;

const Title = styled.p`
  font-weight: 900;
  font-size: 20px;
  color: #713bdb;
`;
const CardResultLoginForm = ({ type }: { type: string }) => {
  const isLoggedIn = useIsLoggedIn();

  if (!isLoggedIn && LOGIN_FREE.includes(type)) {
    return (
      <LoginFormWrapper>
        <Title>지금 가입하고 무료코인 10개 받기!</Title>
        <LoginButtons />
      </LoginFormWrapper>
    );
  }
  return <></>;
};

export default CardResultLoginForm;
