import React, { useReducer, useContext, createContext, Dispatch } from "react";

type State = {
  cardId?: string;
};

type Action = { type: "SET_CARD_ID"; cardId: string };

type CardIdDispatch = Dispatch<Action>;

const CardIdStateContext = createContext<State | null>(null);
const CardIdDispatchContext = createContext<CardIdDispatch | null>(null);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_CARD_ID":
      return {
        ...state,
        ...action,
      };
    default:
      throw new Error("Unhandled action");
  }
}

function CardIdProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, {
    cardId: undefined,
  });

  return (
    <CardIdStateContext.Provider value={state}>
      <CardIdDispatchContext.Provider value={dispatch}>
        {children}
      </CardIdDispatchContext.Provider>
    </CardIdStateContext.Provider>
  );
}

function useCardIdState() {
  const state = useContext(CardIdStateContext);
  if (!state) throw new Error("Cannot find UsersProvider"); // 유효하지 않을땐 에러를 발생
  return state;
}

function useCardIdDispatch() {
  const dispatch = useContext(CardIdDispatchContext);
  if (!dispatch) throw new Error("Cannot find UsersProvider"); // 유효하지 않을땐 에러를 발생
  return dispatch;
}

export { CardIdProvider, useCardIdState, useCardIdDispatch };
