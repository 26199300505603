import i18next from "i18next";

export const calendarTranslation = (calendar: string) => {
  const lang = i18next.language.slice(0, 2);
  if (lang === "ko" && calendar === "양력") {
    return "양력";
  } else if (lang === "ko" && calendar === "음력(평달)") {
    return "음력(평달)";
  } else if (lang === "ko" && calendar === "음력(윤달)") {
    return "음력(윤달)";
  } else if (lang === "en" && calendar === "양력") {
    return "Solar Calendar";
  } else if (lang === "en" && calendar === "음력(평달)") {
    return "Lunar Calendar";
  } else if (lang === "en" && calendar === "음력(윤달)") {
    return "Lunar(Leap) Calendar";
  } else {
    return;
  }
};
