import moment from "moment";

export const getAge = (birth_datetime: string | Date) => {
  return moment().year() - moment(birth_datetime).year();
};

export const formatDateTime = (datetime: string | Date) => {
  return (
    datetime?.toString().slice(0, 10) + " " + datetime?.toString().slice(11, 16)
  );
};
