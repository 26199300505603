import { BirthModel } from "@api/births.api";
import { getCardResult, GetCardResultResModel } from "@api/card.result.api";
import NotEnoughCoinModal from "@components/molecules/NotEnoughCoinModal";
import StarcoinModal from "@components/molecules/StarcoinModal";
import UseCoinConfirmModal from "@components/molecules/UseCoinConfirmModal";
import { useCommonDispatch } from "@contexts/common.context";
import { useUsersDispatch, useUsersState } from "@contexts/users.context";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface UsePayForCardParams {
  otherBirth?: BirthModel;
  secondOtherBirth?: BirthModel;
  cardId: string;
  result: GetCardResultResModel;
  price: number;
  showConfirmModal: boolean;
  toggleShowMore: () => void;
  endpoint: string;
  isRequiredPerson: boolean;
  is임시로직?: boolean;
}
const usePayForCard = ({
  otherBirth,
  secondOtherBirth,
  cardId,
  result,
  price,
  showConfirmModal,
  endpoint,
  toggleShowMore,
  isRequiredPerson,
  is임시로직 = false,
}: UsePayForCardParams) => {
  //starcoin modal
  const { userInfo } = useUsersState();
  const commonDispatch = useCommonDispatch();
  const usersDispatch = useUsersDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [showStarcoinModal, setStarcoinModal] = useState(false);
  const [showNotEnoughCoinModal, setShowNotEnoughCoinModal] = useState(false);
  const calStarcoinBalance = useCallback(() => {
    const user_normal_starcoin_balance = userInfo?.normal_token_balance || 0;
    if (!userInfo) {
      return;
    }
    userInfo.normal_token_balance = user_normal_starcoin_balance - price;
    usersDispatch({ type: "SET_USER_INFO", userInfo: userInfo });
  }, [price, userInfo, usersDispatch]);

  const checkNeedStarcoin = useCallback((): boolean => {
    const user_normal_starcoin_balance = userInfo?.normal_token_balance || 0;
    const user_bonus_starcoin_balance = userInfo?.bonus_token_balance || 0;
    if (user_bonus_starcoin_balance + user_normal_starcoin_balance < price) {
      return true;
    }
    return false;
  }, [price, userInfo?.bonus_token_balance, userInfo?.normal_token_balance]);

  const _getCardResult = useCallback(
    async (payload: any) => {
      try {
        const res = await getCardResult(payload);
        return res;
      } catch (e) {
        commonDispatch({ type: "SET_IS_LOADING", isLoading: false });
        console.log("main list error : ", e);
        throw e;
      }
    },
    [commonDispatch]
  );
  const handleStartClick = useCallback(
    async (cardId: string) => {
      if (!userInfo) return;
      try {
        if (checkNeedStarcoin()) {
          setStarcoinModal(true);
          return;
        }

        let payload = null;

        if (isRequiredPerson) {
          if (!otherBirth || !secondOtherBirth) return;
          commonDispatch({ type: "SET_IS_LOADING", isLoading: true });
          payload = {
            personal_info: otherBirth,
            personal_info_2: is임시로직
              ? {
                  ...secondOtherBirth,
                  gender:
                    otherBirth.gender === secondOtherBirth.gender
                      ? otherBirth.gender === "MAN"
                        ? "WOMAN"
                        : "MAN"
                      : secondOtherBirth.gender,
                }
              : secondOtherBirth,
            endpoint,
            required_starcoin: price,
            card_id: cardId,
          };
        } else {
          if (!otherBirth) return;

          commonDispatch({ type: "SET_IS_LOADING", isLoading: true });
          payload = {
            personal_info: otherBirth,
            endpoint,
            required_starcoin: price,
            card_id: cardId,
          };
        }

        const res = await _getCardResult(payload);

        if (res) {
          calStarcoinBalance();
          commonDispatch({ type: "SET_IS_LOADING", isLoading: false });
          toggleShowMore();
          history.push("/cards/" + cardId + "/result", {
            res: res,
            required_starcoin: price,
          });
        }
      } catch (e) {
        alert(t("error.exception_error"));
        commonDispatch({ type: "SET_IS_LOADING", isLoading: false });
      }
    },
    [
      _getCardResult,
      calStarcoinBalance,
      checkNeedStarcoin,
      commonDispatch,
      endpoint,
      history,
      isRequiredPerson,
      otherBirth,
      price,
      secondOtherBirth,
      t,
      toggleShowMore,
      userInfo,
    ]
  );

  const getModals = () => {
    return (
      <>
        <UseCoinConfirmModal
          show={showConfirmModal}
          onConfirmClick={() => handleStartClick(cardId)}
          onPrevClick={toggleShowMore}
          coin={price}
          personalInfo={otherBirth}
          secondPersonInfo={secondOtherBirth}
          disclaimer={t("starcoin.disclaimer")}
          disclaimer_1={t("starcoin.disclaimer_1")}
        />
        <NotEnoughCoinModal
          show={showNotEnoughCoinModal}
          onConfirmClick={() =>
            history.push({
              pathname: "/shop",
              state: { cardId: result?.card_id },
            })
          }
          onPrevClick={() => setShowNotEnoughCoinModal(false)}
        />
        <StarcoinModal
          show={showStarcoinModal}
          title={t("starcoin.title")}
          desc={t("starcoin.desc")}
          buttonDesc={t("starcoin.button")}
          onPrevClick={() => setStarcoinModal(false)}
        />
      </>
    );
  };
  return { getModals };
};

export default usePayForCard;
