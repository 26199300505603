import { GetCardResultResModel, Graph } from "@api/card.result.api";
import imgArrowLeft from "@assets/imgs/chevron-down-solid-3@2x.png";
import icRevision from "@assets/imgs/revision-regular@2x.png";
import ResultShare from "@components/molecules/ResultShare";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

interface ButtonProps {
  displayed?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-width: 768px;
  margin: 0 auto;
  border-radius: 36px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  padding: 50px 100px;

  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("tablet")} {
    max-width: calc(100%);
    min-width: calc(100%);
    padding: 25px;
  }
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    margin: 0;
    padding-top: 0;
    padding-bottom: 40px;
  }
`;
const Title = styled.div`
  font-family: Montserrat, sans-serif;
  margin-top: 30px;
  font-size: 64px;
  font-weight: 900;
  ${mediaQueries("mobileL")} {
    font-size: 50px;
    margin-top: 50px;
  }
`;

const SubTitle = styled.div`
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: -0.4px;
  text-align: center;
  color: #2d2d2d;
  margin-bottom: 40px;
  ${mediaQueries("mobileL")} {
    margin-bottom: 50px;
  }
`;
const Comment = styled.div`
  margin-top: 60px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.16px;
  text-align: left;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    padding: 0 10px;
    margin-top: 40px;
  }
`;

const CommentAgain = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  text-align: center;
  margin-top: 10px;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    margin-top: 40px;
  }
`;
const Button = styled.div<ButtonProps>`
  margin: 35px 135px 20px;
  height: 50px;
  padding: 25px 35px;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  ${mediaQueries("mobileL")} {
    margin: 35px 22px 20px;
  }
`;
const IcRevision = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 14px;
  //margin-top: 14px;
`;
const Text = styled.div`
  margin: 3px 0 1px 14px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: -0.36px;
  text-align: center;
  color: #ffffff;
`;
const BackIcon = styled.img`
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  left: -60px;
  cursor: pointer;
`;

const GraphRow = styled.div`
  display: flex;
  width: 100%;
  border-radius: 35px;
  overflow: hidden;
  background-color: #eaeaea;
  //margin-bottom: 30px;
  margin-top: 30px;
`;
const GraphTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: white;
  margin: 0 15px;
  ${mediaQueries("mobileL")} {
    font-size: 13px;
    margin: 0 8px;
  }
`;

const GraphCircleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: white;
  ${mediaQueries("mobileL")} {
    height: 40px;
    width: 40px;
    min-width: 40px;
    min-width: 40px;
  }
`;
interface GraphBarProps {
  percentage: number;
  backgroundColor: string;
  active?: boolean;
}
const GraphBar = styled.div<GraphBarProps>`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 70px;
  width: ${(props) => `${(props.percentage / 100) * 100}%`};
  background-color: ${(props) => props.backgroundColor || "red"};
  border-radius: ${(props) => (props.active ? "35px" : "0px")};
  ${mediaQueries("mobileL")} {
    height: 50px;
    padding: 0 5px;
  }
`;
const GraphDesc = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  line-height: 1.5;
  margin-top: 10px;
  ${mediaQueries("mobileL")} {
    padding: 0 10px;
  }
`;
interface ObesessionResultProps {
  result: GetCardResultResModel;
  result_id: string;
  scoop_button_displayed?: boolean;
  thumbnail_url: string;
}

const GrpahComp = ({
  idx,
  graphs,
  graphThemeColor,
}: {
  idx: number;
  graphs: Graph[];
  graphThemeColor: string;
}) => {
  if (!graphs) return null;
  const firstGraph = graphs[idx];
  const secondGraph = graphs[idx + 1];
  if (!firstGraph || !secondGraph) return null;

  const firstActive = firstGraph.percentage > secondGraph.percentage;
  const firstColor = firstActive ? graphThemeColor : "#eaeaea";
  const secondColor = !firstActive ? graphThemeColor : "#eaeaea";
  const secondFontColor = !firstActive ? graphThemeColor : "#d8d8d8";
  return (
    <>
      <GraphRow>
        <GraphBar
          active={firstActive}
          percentage={firstGraph.percentage}
          backgroundColor={firstColor}
        >
          <GraphCircleWrapper style={{ color: firstColor }}>
            {firstGraph.percentage}%
          </GraphCircleWrapper>
          <GraphTitle>{firstGraph.title}</GraphTitle>
        </GraphBar>
        <GraphBar
          active={!firstActive}
          percentage={secondGraph.percentage}
          backgroundColor={secondColor}
          style={{ justifyContent: "flex-end" }}
        >
          <GraphTitle>{secondGraph.title}</GraphTitle>
          <GraphCircleWrapper style={{ color: secondFontColor }}>
            {secondGraph.percentage}%
          </GraphCircleWrapper>
        </GraphBar>
      </GraphRow>
      <GraphDesc>
        {firstGraph.desc}
        {secondGraph.desc}
      </GraphDesc>
    </>
  );
};

const MakeMbtiCurrent = ({
  result,
  result_id,
  scoop_button_displayed,
  thumbnail_url,
}: ObesessionResultProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <Wrapper>
        <BackIcon src={imgArrowLeft} onClick={() => history.goBack()} />
        <Title style={{ color: "#09c0d7" }}>{result.mbti.withluck.title}</Title>
        <SubTitle>{result.title[2].toUpperCase()}</SubTitle>
        {[0, 2, 4, 6].map((idx) => (
          <GrpahComp
            idx={idx}
            graphs={result.graph[1].withluck}
            graphThemeColor={"#09c0d7"}
          />
        ))}
        <Comment style={{ marginBottom: "50px" }}>
          {result.mbti.withluck.desc}
        </Comment>
        <Comment style={{ marginTop: "0px", marginBottom: "80px" }}>
          {result.comment[1]}
        </Comment>
        <ResultShare
          link={result.link}
          thumbnail_url={thumbnail_url}
          description={result.description}
        />
      </Wrapper>
      <Wrapper style={{ marginTop: "30px" }}>
        <CommentAgain>{result.comment[3]}</CommentAgain>
        <Button onClick={() => history.push(`/cards/${result_id}`)}>
          <IcRevision src={icRevision} />
          <pre>
            <Text>{t("card_result.button")}</Text>
          </pre>
        </Button>
      </Wrapper>
    </>
  );
};

export default MakeMbtiCurrent;
