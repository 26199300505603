import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { mediaQueries } from "@styles/common";

interface ITabItem {
  isActive: boolean;
}
interface ITabHighLighter {
  left: string;
}

const Wrapper = styled.div``;
export const TabsWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
`;

export const TabItem = styled.div<ITabItem>`
  font-family: "Noto Sans KR", sans-serif;
  flex: 1;
  padding: 0px 0 10px;
  text-align: center;
  color: ${(props) => (props.isActive ? "#2d2d2d" : "#b8b8b8")};
  cursor: pointer;
  font-size: 18px;
  font-weight: 900;
  ${mediaQueries("mobileL")} {
    line-height: 1;
  }
`;

export const TabHighLighterWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  height: 5px;
  background-color: #eaeaea;
  position: relative;
  margin-bottom: 40px;
  ${mediaQueries("mobileL")} {
    margin-bottom: 25px;
  }
`;
export const TabHighLighter = styled.div<ITabHighLighter>`
  width: 50%;
  position: absolute;
  top: 0;
  height: 5px;
  left: ${(props) => props.left};
  transition: left 0.2s linear;
  background-color: #2d2d2d;
`;

interface MyPageSelectorProps {
  tab: number;
  setTab: (n: number) => void;
}

const MyPageSelector = ({ tab, setTab }: MyPageSelectorProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <TabsWrapper>
        <TabItem isActive={tab === 0} onClick={() => setTab(0)}>
          {t("profile.my_info").toUpperCase()}
        </TabItem>
        <TabItem isActive={tab === 1} onClick={() => setTab(1)}>
          {t("profile.payment_history").toUpperCase()}
        </TabItem>
      </TabsWrapper>
      <TabHighLighterWrapper>
        <TabHighLighter left={`${50 * tab}%`} />
      </TabHighLighterWrapper>
    </Wrapper>
  );
};

export default MyPageSelector;
