import config from "@api/config";
import Button from "@components/atoms/Button";
import Input from "@components/atoms/Input";
import Radio from "@components/atoms/Radio";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import { getLatLngFromAddr } from "@utils/location.util";
import { FC, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  text-align: right;
  ${mediaQueries("desktop")} {
    text-align: center;
  }
`;

const Title = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 0.7;
  letter-spacing: -0.4px;
  color: #2d2d2d;
  margin-bottom: 14px;
  text-align: left;
  ${mediaQueries("mobileL")} {
    margin-bottom: 20px;
  }
`;
const Label = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #d8d8dc;
  margin-bottom: 14px;
  ${mediaQueries("mobileL")} {
    margin-bottom: 20px;
    width: 250px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
  ${mediaQueries("mobileL")} {
  }
`;
interface AgreementProps {
  agreement_displayed?: boolean;
}
const AgreementWrapper = styled.div<AgreementProps>`
  //display: flex;
  display: ${(props) => (props.agreement_displayed ? "flex" : "none")};
  margin-bottom: 30px;
  flex-wrap: wrap;
  ${mediaQueries("mobileL")} {
  }
`;
export interface SelectedProps {
  selected: boolean;
}
const AgreementText = styled.text<SelectedProps>`
  font-family: "Noto Sans KR", sans-serif;
  color: ${({ selected }) => (selected ? "#642ad6" : "#d8d8d8")};
  font-size: 16px;
  font-weight: 500;
  line-height: 0.88;
  letter-spacing: -0.32px;
  text-align: left;
  margin-left: 12px;
  cursor: pointer;
  ${mediaQueries("desktop")} {
    margin-left: 7px;
  }
`;

type TGender = "MAN" | "WOMAN";

export interface BasicFormData {
  gender: string;
  lunar: string;
  isTimeKnown: boolean;
  year: string;
  month: string;
  day: string;
  hour: string;
  minute: string;
  latitude: number;
  longitude: number;
}

export interface FormProps {
  onSumbit: (data: BasicFormData) => void;
  txtAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
  agreement_displayed: boolean;
}
// const GOOGLE_PLACE_API_KEY = 'AIzaSyCty2BQ0IHvotKcg7rIut4WJ1AQiLtpw9A';

interface Location {
  latitude: number;
  longitude: number;
}

const Form: FC<FormProps> = ({
  onSumbit,
  txtAlign,
  agreement_displayed,
}: FormProps) => {
  const [location, setLocation] = useState<Location>({
    latitude: 0,
    longitude: 0,
  });
  const [gender, setGender] = useState<TGender>("MAN");
  const [lunar, setLunar] = useState<string>("양력");
  const [isTimeKnown, setIsTimeKnown] = useState<boolean>(true);
  const [isAmPm, setIsAmPm] = useState<boolean>(true); // true: AM, false: PM
  const [termsOfService, setTermsOfService] = useState<boolean>(
    !agreement_displayed
  );
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(
    !agreement_displayed
  );
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const { t } = useTranslation();

  const checkDisabled = () => {
    if (location.latitude === 0 && location.longitude === 0) return true;
    if (
      isTimeKnown &&
      (year === "" ||
        month === "" ||
        day === "" ||
        hour === "" ||
        minute === "")
    )
      return true;
    if (agreement_displayed && !(privacyPolicy && termsOfService)) return true;
    return false;
  };

  const handleSubmit = () => {
    let _hour = "";
    if (hour == "12") {
      _hour = "12";
    } else if (!isAmPm) {
      _hour = (parseInt(hour) + 12).toString();
    } else {
      _hour = hour;
    }
    const data = {
      gender,
      lunar,
      isTimeKnown,
      year,
      month,
      day,
      hour: _hour,
      am_pm: isAmPm,
      minute,
      termsOfService: termsOfService,
      privacyPolicy: privacyPolicy,
      ...location,
    };
    onSumbit(data);
  };
  const handlerAddress = async (e: any) => {
    const currentAddr: any = await getLatLngFromAddr(e.label);
    setLocation({ latitude: currentAddr.lat, longitude: currentAddr.lng });
  };

  return (
    <Wrapper style={txtAlign ? { textAlign: txtAlign } : {}}>
      <Title>{t("birth_modal.gender")}</Title>
      <InputWrapper>
        <Radio
          selected={gender === "MAN"}
          onClick={() => setGender("MAN")}
          label={t("birth_modal.male")}
        />
        <Radio
          selected={gender === "WOMAN"}
          onClick={() => setGender("WOMAN")}
          label={t("birth_modal.female")}
        />
      </InputWrapper>
      <Title>{t("birth_modal.location")}</Title>
      <InputWrapper>
        <div style={{ flex: 1 }}>
          <GooglePlacesAutocomplete
            apiKey={config.GOOGLE_GEOCODE_KEY}
            selectProps={{
              onChange: handlerAddress,
              placeholder: t("birth_modal.location_placeholder"),
            }}
          />
        </div>
      </InputWrapper>
      <Title>{t("birth_modal.solar_lunar")}</Title>
      <InputWrapper>
        <Radio
          selected={lunar === "양력"}
          onClick={() => setLunar("양력")}
          label={t("birth_modal.solar")}
        />
        <Radio
          selected={lunar === "음력(평달)"}
          onClick={() => setLunar("음력(평달)")}
          label={t("birth_modal.lunar")}
        />
        <Radio
          selected={lunar === "음력(윤달)"}
          onClick={() => setLunar("음력(윤달)")}
          label={t("birth_modal.lunar_leap")}
        />
      </InputWrapper>
      <Title>{t("birth_modal.know_time")}</Title>
      <Label>{t("birth_modal._datetime")}</Label>
      <InputWrapper>
        <Radio
          selected={isTimeKnown}
          onClick={() => setIsTimeKnown(true)}
          label={t("birth_modal.know")}
        />
        <Radio
          selected={!isTimeKnown}
          onClick={() => setIsTimeKnown(false)}
          label={t("birth_modal.dont_know")}
        />
      </InputWrapper>
      {isTimeKnown ? (
        <>
          <Title>{t("birth_modal.datetime")}</Title>
          <InputWrapper style={{ marginBottom: "14px" }}>
            <Input
              style={{ width: "80px" }}
              value={year}
              onValueChange={setYear}
              label={t("birth_modal.year")}
              placeholder={"2021"}
            />
            <Input
              style={{ width: "50px" }}
              value={month}
              onValueChange={setMonth}
              label={t("birth_modal.month")}
              placeholder={"7"}
            />
            <Input
              style={{ width: "50px" }}
              value={day}
              onValueChange={setDay}
              label={t("birth_modal.day")}
              placeholder={"12"}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              style={{ width: "50px" }}
              value={hour}
              onValueChange={setHour}
              label={t("birth_modal.hour")}
              placeholder={"4"}
            />
            <Input
              style={{ width: "50px" }}
              value={minute}
              onValueChange={setMinute}
              label={t("birth_modal.minute")}
              placeholder={"20"}
            />
            <Radio
              style={{ marginBottom: "10px", marginRight: "10px" }}
              selected={isAmPm}
              onClick={() => setIsAmPm(true)}
              label={t("birth_modal.am")}
            />
            <Radio
              style={{ marginBottom: "10px", marginRight: "10px" }}
              selected={!isAmPm}
              onClick={() => setIsAmPm(false)}
              label={t("birth_modal.pm")}
            />
          </InputWrapper>
        </>
      ) : (
        <>
          <Title>{t("birth_modal.datetime")}</Title>
          <InputWrapper>
            <Input
              style={{ width: "80px" }}
              value={year}
              onValueChange={setYear}
              label={t("birth_modal.year")}
              placeholder={"2021"}
            />
            <Input
              style={{ width: "50px" }}
              value={month}
              onValueChange={setMonth}
              label={t("birth_modal.month")}
              placeholder={"7"}
            />
            <Input
              style={{ width: "50px" }}
              value={day}
              onValueChange={setDay}
              label={t("birth_modal.day")}
              placeholder={"12"}
            />
          </InputWrapper>
        </>
      )}
      <AgreementWrapper agreement_displayed={agreement_displayed}>
        <Radio
          selected={termsOfService}
          onClick={() => setTermsOfService(!termsOfService)}
          label={t("extra_info.terms_of_service")}
          agreement={t("extra_info.go_terms_of_service")}
          url="https://www.termsfeed.com/live/d45ca735-59ec-4336-acc6-11a08e17ebe4"
        />
      </AgreementWrapper>
      <AgreementWrapper agreement_displayed={agreement_displayed}>
        <Radio
          selected={privacyPolicy}
          onClick={() => setPrivacyPolicy(!privacyPolicy)}
          label={t("extra_info.privacy_policy")}
          agreement={t("extra_info.go_privacy_policy")}
          url="https://www.termsfeed.com/live/d45ca735-59ec-4336-acc6-11a08e17ebe4"
        />
      </AgreementWrapper>
      <Button
        style={{ padding: "15px 80px" }}
        onClick={handleSubmit}
        disabled={checkDisabled()}
      >
        {t("birth_modal.submit")}
      </Button>
      <div style={{ marginBottom: "30px" }}></div>
    </Wrapper>
  );
};

export default Form;
