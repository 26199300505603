import { GetCardResultResModel } from "@api/card.result.api";
import icRevision from "@assets/imgs/revision-regular@2x.png";
import ResultShare from "@components/molecules/ResultShare";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import { MouseEvent, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

interface PartnersCharactersProps {
  result: GetCardResultResModel;
  result_id: string;
  thumbnail_url: string;
}

const PartnersCharacter = ({
  result,
  result_id,
  thumbnail_url,
}: PartnersCharactersProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [toggles, setToggles] = useState<Record<string, boolean>>({
    title1: false,
    title2: false,
    d1: false,
    d2: false,
    d3: false,
    d4: false,
    d5: false,
    d6: false,
    d7: false,
    d8: false,
    d9: false,
    d10: false,
    d11: false,
    d12: false,
    d13: false,
    t1: false,
    t2: false,
    t3: false,
    t4: false,
    t5: false,
    t6: false,
    t7: false,
    t8: false,
    t9: false,
    t10: false,
    t11: false,
    t12: false,
  });

  const handleToggles = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    const { name } = e.currentTarget;

    setToggles({ ...toggles, [name]: !toggles[name] });
  };
  return (
    <>
      <Wrapper>
        <Content>
          <Title>상대의 기본성격</Title>

          <ToggleTitleButton onClick={handleToggles} name="d1">
            {"<상대 기질>(클릭)"}
          </ToggleTitleButton>
          {toggles.d1 ? (
            <div>
              <div>
                <ToggleButton onClick={handleToggles} name="t1">
                  상대가 가진 기질의 세계 단점(클릭)
                </ToggleButton>
                {toggles.t1 ? (
                  result.total_characters.상대_일반_기본_성격.상대_기질.상대가_가진_기질의_세계_단점.map(
                    (item: any) => {
                      return (
                        <>
                          <h3>{item.title}</h3>
                          <p>{item.desc}</p>
                        </>
                      );
                    }
                  )
                ) : (
                  <></>
                )}
              </div>
              <div>
                <ToggleButton onClick={handleToggles} name="t2">
                  상대가 가진 기질의 세계 장점(클릭)
                </ToggleButton>
                {toggles.t2 ? (
                  result.total_characters.상대_일반_기본_성격.상대_기질.상대가_가진_기질의_세계_장점.map(
                    (item: any) => {
                      return (
                        <>
                          <h3>{item.title}</h3>
                          <p>{item.desc}</p>
                        </>
                      );
                    }
                  )
                ) : (
                  <></>
                )}
              </div>
              <div>
                <ToggleButton onClick={handleToggles} name="t3">
                  상대의 명 EI FT 정보(클릭)
                </ToggleButton>
                {/* <p>
              {
                result.total_characters.상대_일반_기본_성격.상대_기질
                  .상대의_명_EI_FT_정보.desc
              }
            </p> */}
                {toggles.t3 ? (
                  <>
                    <h3>E</h3>
                    <p>
                      제목:
                      {
                        result.total_characters.상대_일반_기본_성격.상대_기질
                          .상대의_명_EI_FT_정보.E.title
                      }
                    </p>
                    <p>
                      설명:
                      {
                        result.total_characters.상대_일반_기본_성격.상대_기질
                          .상대의_명_EI_FT_정보.E.desc
                      }
                    </p>
                    <p>
                      퍼센트:
                      {
                        result.total_characters.상대_일반_기본_성격.상대_기질
                          .상대의_명_EI_FT_정보.E.percentage
                      }
                    </p>

                    <h3>F</h3>
                    <p>
                      제목:
                      {
                        result.total_characters.상대_일반_기본_성격.상대_기질
                          .상대의_명_EI_FT_정보.F.title
                      }
                    </p>
                    <p>
                      설명:
                      {
                        result.total_characters.상대_일반_기본_성격.상대_기질
                          .상대의_명_EI_FT_정보.F.desc
                      }
                    </p>
                    <p>
                      퍼센트:
                      {
                        result.total_characters.상대_일반_기본_성격.상대_기질
                          .상대의_명_EI_FT_정보.F.percentage
                      }
                    </p>

                    <h3>I</h3>
                    <p>
                      제목:
                      {
                        result.total_characters.상대_일반_기본_성격.상대_기질
                          .상대의_명_EI_FT_정보.I.title
                      }
                    </p>
                    <p>
                      설명:
                      {
                        result.total_characters.상대_일반_기본_성격.상대_기질
                          .상대의_명_EI_FT_정보.I.desc
                      }
                    </p>
                    <p>
                      퍼센트:
                      {
                        result.total_characters.상대_일반_기본_성격.상대_기질
                          .상대의_명_EI_FT_정보.I.percentage
                      }
                    </p>

                    <h3>T</h3>
                    <p>
                      제목:
                      {
                        result.total_characters.상대_일반_기본_성격.상대_기질
                          .상대의_명_EI_FT_정보.T.title
                      }
                    </p>
                    <p>
                      설명:
                      {
                        result.total_characters.상대_일반_기본_성격.상대_기질
                          .상대의_명_EI_FT_정보.T.desc
                      }
                    </p>
                    <p>
                      퍼센트:
                      {
                        result.total_characters.상대_일반_기본_성격.상대_기질
                          .상대의_명_EI_FT_정보.T.percentage
                      }
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div>
            <ToggleTitleButton
              onClick={handleToggles}
              name="d2"
            >{`<상대 능동성과 피동성>(클릭)`}</ToggleTitleButton>
            {toggles.d2 ? (
              <div>
                {result.total_characters.상대_일반_기본_성격.상대_능동성과_피동성?.map(
                  (item: string) => (
                    <p>{item}</p>
                  )
                )}
              </div>
            ) : (
              <></>
            )}
          </div>

          <div>
            <ToggleTitleButton onClick={handleToggles} name="d3">
              {"<상대 삶의 방식과 가치관>(클릭)"}
            </ToggleTitleButton>
            {toggles.d3 ? (
              <>
                <div>
                  {result.total_characters.상대_일반_기본_성격
                    .상대_삶의방식과_가치관?.상대의_삶의방식과_가치_대표_대응
                    .length > 0 && (
                    <>
                      <ToggleButton onClick={handleToggles} name="t8">
                        상대의 삶의 방식과 가치 대표 대응(클릭)
                      </ToggleButton>
                      {toggles.t8 ? (
                        result.total_characters.상대_일반_기본_성격.상대_삶의방식과_가치관?.상대의_삶의방식과_가치_대표_대응?.map(
                          (item: string) => <p>{item}</p>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
                <div>
                  {result.total_characters.상대_일반_기본_성격
                    .상대_삶의방식과_가치관?.상대의_삶의방식과_가치_대표_이해
                    .length > 0 && (
                    <>
                      <ToggleButton onClick={handleToggles} name="t9">
                        상대의 삶의 방식과 가치 대표 이해(클릭)
                      </ToggleButton>
                      {toggles.t9 ? (
                        result.total_characters.상대_일반_기본_성격.상대_삶의방식과_가치관?.상대의_삶의방식과_가치_대표_이해?.map(
                          (item: string) => <p>{item}</p>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
                <div>
                  <ToggleButton onClick={handleToggles} name="t4">
                    상대의 삶의 방식과 가치 다중1 대응(클릭)
                  </ToggleButton>
                  {toggles.t4 ? (
                    result.total_characters.상대_일반_기본_성격.상대_삶의방식과_가치관?.상대의_삶의방식과_가치_다중1_대응?.map(
                      (item: string) => <p>{item}</p>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <ToggleButton onClick={handleToggles} name="t5">
                    상대의 삶의 방식과 가치 다중1 이해(클릭)
                  </ToggleButton>
                  {toggles.t5 ? (
                    result.total_characters.상대_일반_기본_성격.상대_삶의방식과_가치관?.상대의_삶의방식과_가치_다중1_이해?.map(
                      (item: string) => <p>{item}</p>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  {result.total_characters.상대_일반_기본_성격
                    .상대_삶의방식과_가치관?.상대의_삶의방식과_가치_다중2_대응
                    .length > 0 && (
                    <>
                      <ToggleButton onClick={handleToggles} name="t6">
                        상대의 삶의 방식과 가치 다중2 대응(클릭)
                      </ToggleButton>
                      {toggles.t6 ? (
                        result.total_characters.상대_일반_기본_성격.상대_삶의방식과_가치관?.상대의_삶의방식과_가치_다중2_대응?.map(
                          (item: string) => <p>{item}</p>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
                <div>
                  {result.total_characters.상대_일반_기본_성격
                    .상대_삶의방식과_가치관?.상대의_삶의방식과_가치_다중2_이해
                    .length > 0 && (
                    <>
                      <ToggleButton onClick={handleToggles} name="t7">
                        상대의 삶의 방식과 가치 다중2 이해(클릭)
                      </ToggleButton>
                      {toggles.t7 ? (
                        result.total_characters.상대_일반_기본_성격.상대_삶의방식과_가치관?.상대의_삶의방식과_가치_다중2_이해?.map(
                          (item: string) => <p>{item}</p>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </Content>
        <Content>
          <Title>상대의 가려진 개성</Title>

          <ToggleTitleButton onClick={handleToggles} name="d4">
            {"<상대 가려진 개성>(클릭)"}
          </ToggleTitleButton>
          <div>
            {toggles.d4 ? (
              result.total_characters.상대_가려진_개성.상대_가려진_개성?.map(
                (item: string) => <p>{item}</p>
              )
            ) : (
              <></>
            )}
          </div>

          {/* <h1>{"<상대 삶의방식과 가치관>"}</h1>
            <h2>상대 일반 기본 성격 상대 삶의방식과 가치관 과 중복됨</h2> */}
          <ToggleTitleButton onClick={handleToggles} name="d5">
            {"<상대 타고난 고유의 강한 기질>(클릭)"}
          </ToggleTitleButton>
          <div>
            {toggles.d5 ? (
              result.total_characters.상대_가려진_개성.상대_타고난_고유의_강한기질?.map(
                (item: string) => <p>{item}</p>
              )
            ) : (
              <></>
            )}
          </div>

          <ToggleTitleButton onClick={handleToggles} name="d6">
            {"<상대 행동양식 문제해결방식>(클릭)"}
          </ToggleTitleButton>
          <div>
            {toggles.d6 ? (
              result.total_characters.상대_가려진_개성.상대_행동양식_문제해결방식?.map(
                (item: string) => <p>{item}</p>
              )
            ) : (
              <></>
            )}
          </div>

          <ToggleTitleButton onClick={handleToggles} name="d7">
            {"<상대가 삶을 운용하는 방식>(클릭)"}
          </ToggleTitleButton>
          <div>
            {toggles.d7 ? (
              result.total_characters.상대_가려진_개성?.상대가_삶을_운용하는_방식?.map(
                (item: string) => <p>{item}</p>
              )
            ) : (
              <></>
            )}
          </div>
        </Content>
        <Content>
          <ToggleMainTitleButton onClick={handleToggles} name="title1">
            상대의 극복 어려운 성질(클릭)
          </ToggleMainTitleButton>
          <div>
            <ToggleTitleButton onClick={handleToggles} name="d10">
              {"<상대 오행많다>(클릭)"}
            </ToggleTitleButton>
            <div>
              {toggles.d10 ? (
                result.total_characters.상대_극복_어려운_성질.상대_오행많다?.map(
                  (item: string | string[]) => (
                    <p>
                      {typeof item === "object"
                        ? item.map((item) => <>{item}</>)
                        : item}
                    </p>
                  )
                )
              ) : (
                <></>
              )}
            </div>
          </div>
          <div>
            <ToggleTitleButton onClick={handleToggles} name="d11">
              {"<상대 오행없다>(클릭)"}
            </ToggleTitleButton>
            <div>
              {toggles.d11 ? (
                result.total_characters.상대_극복_어려운_성질.상대_오행없다?.map(
                  (item: string | string[]) => (
                    <p>
                      {typeof item === "object"
                        ? item.map((item) => <>{item}</>)
                        : item}
                    </p>
                  )
                )
              ) : (
                <></>
              )}
            </div>
          </div>
          <div>
            <ToggleTitleButton onClick={handleToggles} name="d12">
              {"<상대 육친많다>(클릭)"}
            </ToggleTitleButton>
            <div>
              {toggles.d12 ? (
                result.total_characters.상대_극복_어려운_성질.상대_육친많다?.map(
                  (item: string | string[]) => (
                    <p>
                      {typeof item === "object"
                        ? item.map((item) => <>{item}</>)
                        : item}
                    </p>
                  )
                )
              ) : (
                <></>
              )}
            </div>
          </div>
          <div>
            <ToggleTitleButton onClick={handleToggles} name="d13">
              {"<상대 육친없다>(클릭)"}
            </ToggleTitleButton>
            <div>
              {toggles.d13 ? (
                result.total_characters.상대_극복_어려운_성질.상대_육친없다?.map(
                  (item: string | string[]) => (
                    <p>
                      {typeof item === "object"
                        ? item.map((item) => <>{item}</>)
                        : item}
                    </p>
                  )
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        </Content>
        <Content>
          <Title>상대와의 관계조성 속성</Title>
          <ToggleTitleButton onClick={handleToggles} name="d8">
            {"<상대가 내게주는 운명적 베이스>(클릭)"}
          </ToggleTitleButton>
          <div>
            {" "}
            {toggles.d8 ? (
              <div>
                <p>
                  긍정:{" "}
                  {
                    result.total_characters.상대와_관계조성_속성
                      .상대가_내게주는_운명적베이스.긍정
                  }
                </p>
                <p>
                  부정:{" "}
                  {
                    result.total_characters.상대와_관계조성_속성
                      .상대가_내게주는_운명적베이스.부정
                  }
                </p>
                <p>
                  기타:{" "}
                  {
                    result.total_characters.상대와_관계조성_속성
                      .상대가_내게주는_운명적베이스.Extra
                  }
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>

          <ToggleTitleButton onClick={handleToggles} name="d9">
            {"<에너지 차이에 따른 관계성>(클릭)"}
          </ToggleTitleButton>
          <div>
            {toggles.d9 ? (
              result.total_characters.상대와_관계조성_속성.에너지_차이에_따른_관계성.map(
                (item: string) => item
              )
            ) : (
              <></>
            )}
          </div>
        </Content>
        <Content>
          <ToggleMainTitleButton onClick={handleToggles} name="title2">
            상대의 특이성질(클릭)
          </ToggleMainTitleButton>
          {toggles.title2 ? (
            <>
              <p>
                {result.total_characters.상대_특이성질?.상대_text1?.map(
                  (item: string) => item
                )}
              </p>
              <p>
                {result.total_characters.상대_특이성질?.상대_text2?.map(
                  (item: string) => item
                )}
              </p>
              <p>
                {result.total_characters.상대_특이성질?.상대_text3?.map(
                  (item: string) => item
                )}
              </p>
              <p>
                {result.total_characters.상대_특이성질?.상대_text4?.map(
                  (item: string) => item
                )}
              </p>
              <p>
                {result.total_characters.상대_특이성질?.상대_text5?.map(
                  (item: string) => item
                )}
              </p>
            </>
          ) : (
            <></>
          )}
        </Content>

        <ResultShare
          link={result.link}
          thumbnail_url={thumbnail_url}
          description={result.description}
        />
      </Wrapper>
      <BottomWrapper style={{ marginTop: "30px" }}>
        {/* <CommentAgain>{result?.comment[3]}</CommentAgain> */}
        <Button onClick={() => history.push(`/cards/${result_id}`)}>
          <IcRevision src={icRevision} />
          <pre>
            <Text>{t("card_result.button")}</Text>
          </pre>
        </Button>
      </BottomWrapper>
    </>
  );
};

interface ButtonProps {
  displayed?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-width: 768px;
  margin: 0 auto;
  border-radius: 36px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  overflow: hidden;

  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("tablet")} {
    max-width: calc(100%);
    min-width: calc(100%);
  }
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    margin: 0;
    padding-top: 0;
  }
`;

const Content = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
`;
const Title = styled.p`
  font-family: NotoSansCJKkr;
  font-size: 40px;
  color: blue;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 0px;
`;

const BottomWrapper = styled(Wrapper)`
  padding: 50px 100px;
  ${mediaQueries("mobileL")} {
    padding: 15px;
  }
`;

const Button = styled.div<ButtonProps>`
  margin: 35px 135px 20px;
  height: 50px;
  padding: 25px 35px;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  ${mediaQueries("mobileL")} {
    margin: 35px 22px 20px;
  }
`;
const IcRevision = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 14px;
`;
const Text = styled.div`
  margin: 3px 0 1px 14px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: -0.36px;
  /* text-align: center; */
  color: #ffffff;
`;
const ToggleMainTitleButton = styled.button`
  border: none;
  background: transparent;
  font-family: NotoSansCJKkr;
  font-size: 40px;
  color: blue;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 0px;
  cursor: pointer;
`;

const ToggleTitleButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 35px;
  font-weight: bold;
`;
const ToggleButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 28px;
`;
export default PartnersCharacter;
