import Footer from "@components/molecules/Footer";
import Header from "@components/molecules/Header";
import * as React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  padding-left: 10%;
  padding-top: 81px;
  padding-bottom: 50px;
  height: 100vh;
  @media (max-width: 1500px) {
    height: auto;
  }
`;
const TextArea = styled.textarea`
  display: block;
  padding: 20px;
  width: 50%;
  height: 150px;
  background: #fff;
  line-height: 1.6em;
  resize: none;
  @media (max-width: 960px) {
    width: 90%;
  }
`;

const TermsOfService: React.FC = () => {
  return (
    <>
      <Header />
      <Container>
        <h2>서비스 이용약관</h2>
        <TextArea readOnly>서비스 이용약관</TextArea>
      </Container>
      <Footer />
    </>
  );
};

export default TermsOfService;
