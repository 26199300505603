import imgAdd from "@assets/imgs/group_10200@2x.png";
import imgArrowLeft from "@assets/imgs/chevron-down-solid-3@2x.png";
import Input from "@components/atoms/Input";
import { useBirthsDispatch, useBirthsState } from "@contexts/births.context";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import { useEffect, useState } from "react";
import Form from "../Form";
import SearchBar from "../SearchBar";
import moment from "moment";
import { BirthModel, getBirthList, saveBirth } from "@api/births.api";
import { useTranslation } from "react-i18next";
import { genderTranslation } from "@utils/gender.util";
import { CardModel } from "@models/users/Card";

interface TableIdx {
  idx: number;
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;
const Dimmed = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(45, 45, 45, 0.7);
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 100px;
  border-radius: 40px;
  max-width: 768px;
  min-width: 768px;
  overflow: scroll;
  max-height: 700px;
  background-color: white;
  ${mediaQueries("desktopSmall")} {
    max-width: calc(100%);
    min-width: calc(100%);
    max-height: calc(100vh - 80px);
    padding: 38px;
    flex-direction: column;
  }
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    max-height: calc(100vh - 0px);
    padding: 20px;
    flex-direction: column;
    border-radius: 0px;
  }
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 900;
  color: #2d2d2d;
  text-align: center;
  ${mediaQueries("desktopSmall")} {
    margin-top: 60px;
    text-align: left;
  }
`;

const Spacing = styled.div`
  height: 50px;
`;

const TableWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  max-height: 330px;
  /* min-height: 330px; */
  overflow: scroll;
`;

const TableRow = styled.div<TableIdx>`
  width: 100%;
  max-height: 350px;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  border-radius: 14px;
  &:hover {
    cursor: pointer;
    background-color: #eaeaea;
  }
  ${mediaQueries("desktopSmall")} {
    padding: 20px 0px;
  }
  //border: ${(props) => (props.idx == 0 ? "1px solid #713bdb" : "")};
`;
const FirstCol = styled.div<TableIdx>`
  font-family: "Noto Sans KR", sans-serif;
  flex: 2;
  font-size: 18px;
  font-weight: 900;
  text-align: left;
  color: ${(props) => (props.idx == 0 ? "#8743FF" : "#2d2d2d")};
  ${mediaQueries("mobileL")} {
    font-size: 16px;
  }
`;
const SecondCol = styled.div<TableIdx>`
  font-family: "Noto Sans KR", sans-serif;
  flex: 1;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  color: ${(props) => (props.idx == 0 ? "#8743FF" : "#2d2d2d")};
`;
const LastCol = styled.div<TableIdx>`
  font-family: "Noto Sans KR", sans-serif;
  flex: 2;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  color: ${(props) => (props.idx == 0 ? "#8743FF" : "#2d2d2d")};
  ${mediaQueries("mobileL")} {
    text-align: left;
    //padding-left: 20px;
  }
`;
const AddWrapper = styled.div`
  margin-top: 20px;
  //width: 100%;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;
const AddButton = styled.img`
  width: 80px;
  height: 80px;
`;
const AddText = styled.div`
  //font-size: 20px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 900;
  color: #8743ff;
`;

const BackIconWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: -60px;
  cursor: pointer;
  text-align: left;
  ${mediaQueries("desktopSmall")} {
    background-color: white;
    position: fixed;
    top: 15px;
    left: 30px;
    border-radius: 40px;
  }
  ${mediaQueries("mobileL")} {
    left: 14px;
  }
`;
const BackIcon = styled.img`
  width: 44px;
  height: 44px;
`;

const FormWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
`;

const NickNameLabel = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 0.7;
  letter-spacing: -0.4px;
  color: #2d2d2d;
  margin-bottom: 20px;
  text-align: left;
  margin-top: 20px;
  ${mediaQueries("tablet")} {
    margin-top: 40px;
    font-size: 15px;
  }
  ${mediaQueries("mobileL")} {
    margin-top: 40px;
    font-size: 15px;
  }
`;

interface BirthModalProps {
  show: boolean;
  onPrevClick: () => void;
  current_card: CardModel | null;
}

const BirthModal = ({ show, onPrevClick, current_card }: BirthModalProps) => {
  const [showForm, setShowForm] = useState(false);
  const [nickname, setNickname] = useState("");
  const { list, otherBirth, secondOtherBirth } = useBirthsState();

  const [searched, setSearched] = useState<BirthModel[] | null>(null);
  const birthsDispatch = useBirthsDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    setNickname("");
  }, [showForm]);

  if (!show || !list) return null;

  const handleSearch = (keyword: string) => {
    setSearched(list.filter((d) => d.name.includes(keyword)));
  };
  const handleSubmit = async (data: any) => {
    if (2025 <= data.year || data.year < 1930) {
      alert(t("birth_modal.error_year"));
      return;
    }
    if (!(data.latitude >= 18.5 && data.latitude <= 66.5)) {
      alert(t("birth_modal.latitude_info"));
      return;
    }
    if (data.hour > 24) {
      alert(t("birth_modal.error_hour"));
      return;
    } else if (data.am_pm && data.hour >= 12) {
      alert(t("birth_modal.error_hour"));
      return;
    }
    data.month = data.month - 1;
    const _birthdate = moment({ ...data })
      .format("YYYY-MM-DD HH:mm")
      .toString();

    const payload = {
      ...data,
      is_time_known: data.isTimeKnown,
      name: nickname,
      birth_datetime: _birthdate,
    };
    const res = await saveBirth(payload);
    if (!res) return alert(t("error.save_birth_error"));

    const _l = await getBirthList({});
    if (!_l) return;
    birthsDispatch({ type: "SET_LIST", list: _l.births });
    setShowForm(false);
  };

  const handleClose = () => {
    if (showForm) setShowForm(false);
    else onPrevClick();
  };

  const handleBirthClick = (b: BirthModel) => {
    if (!current_card) return;
    if (current_card.is_required_person) {
      if (otherBirth) {
        birthsDispatch({ type: "SET_SECOND_OTHER_BIRTH", secondOtherBirth: b });
      } else {
        birthsDispatch({ type: "SET_OTHER_BIRTH", otherBirth: b });
      }
      onPrevClick();
    } else {
      birthsDispatch({ type: "SET_OTHER_BIRTH", otherBirth: b });
      onPrevClick();
    }
  };

  return (
    <Wrapper>
      <Dimmed onClick={handleClose} />
      <ModalWrapper>
        <BackIconWrapper>
          <BackIcon src={imgArrowLeft} onClick={handleClose} />
        </BackIconWrapper>
        {showForm ? (
          <FormWrapper>
            {/* <BackIcon src={imgArrowLeft} onClick={() => setShowForm(false)} /> */}
            <Title>{t("birth_modal.title")}</Title>
            <NickNameLabel>{t("birth_modal.nickname")}</NickNameLabel>
            <Input
              style={{
                width: "300px",
                textAlign: "left",
                paddingLeft: 20,
                marginBottom: "20px",
              }}
              value={nickname}
              onValueChange={setNickname}
              placeholder={t("birth_modal.nickname_placeholder")}
            />
            <Form
              onSumbit={handleSubmit}
              txtAlign={"center"}
              agreement_displayed={false}
            />
          </FormWrapper>
        ) : (
          <>
            {/* <BackIcon src={imgArrowLeft} onClick={onPrevClick} /> */}
            <Title>{t("birth_select.title")}</Title>
            <Spacing />
            <SearchBar onSearch={handleSearch} />
            <TableWrapper>
              {searched
                ? searched.map((d, idx) => (
                    <TableRow
                      key={d.name + "-" + d.birth_datetime}
                      onClick={() => handleBirthClick(d)}
                      idx={idx}
                    >
                      <FirstCol idx={idx}>{d.name}</FirstCol>
                      <SecondCol idx={idx}>
                        {genderTranslation(d.gender)}
                      </SecondCol>
                      <SecondCol idx={idx}>
                        {moment().year() - moment(d.birth_datetime).year()}
                      </SecondCol>
                      <LastCol idx={idx}>
                        {d.birth_datetime.toString().slice(0, 10)}{" "}
                        {d.birth_datetime.toString().slice(11, 16)}
                      </LastCol>
                    </TableRow>
                  ))
                : list.map((d, idx) => (
                    <TableRow
                      key={d.name + "-" + d.birth_datetime}
                      onClick={() => handleBirthClick(d)}
                      idx={idx}
                    >
                      <FirstCol idx={idx}>{d.name}</FirstCol>
                      <SecondCol idx={idx}>
                        {genderTranslation(d.gender)}
                      </SecondCol>
                      <SecondCol idx={idx}>
                        {moment().year() - moment(d.birth_datetime).year()}
                      </SecondCol>
                      <LastCol idx={idx}>
                        {d.birth_datetime.toString().slice(0, 10)}{" "}
                        {d.birth_datetime.toString().slice(11, 16)}
                      </LastCol>
                    </TableRow>
                  ))}
            </TableWrapper>
            <AddWrapper onClick={() => setShowForm(true)}>
              <AddButton src={imgAdd} />
              <AddText>{t("birth_select.enter_button")}</AddText>
            </AddWrapper>
          </>
        )}
      </ModalWrapper>
    </Wrapper>
  );
};

export default BirthModal;
