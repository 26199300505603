import React, { useReducer, useContext, createContext, Dispatch } from "react";
import { User } from "../models/users/User";

type State = {
  token: string;
  additionalInfoNeeded: boolean;
  userInfo: null | User;
};

type Action =
  | { type: "SET_TOKEN"; token: string }
  | { type: "SET_ADDITINAL_INFO_NEEDED"; needed: boolean }
  | { type: "SET_USER_INFO"; userInfo: User | null };

type UsersDispatch = Dispatch<Action>;

const UsersStateContext = createContext<State | null>(null);
const UsersDispatchContext = createContext<UsersDispatch | null>(null);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        token: action.token,
      };
    case "SET_ADDITINAL_INFO_NEEDED":
      return {
        ...state,
        additionalInfoNeeded: action.needed,
      };
    case "SET_USER_INFO":
      return {
        ...state,
        userInfo: action.userInfo,
      };
    default:
      throw new Error("Unhandled action");
  }
}

function UsersProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, {
    token: "",
    userInfo: null,
    additionalInfoNeeded: false,
  });

  return (
    <UsersStateContext.Provider value={state}>
      <UsersDispatchContext.Provider value={dispatch}>
        {children}
      </UsersDispatchContext.Provider>
    </UsersStateContext.Provider>
  );
}

// state 와 dispatch 를 쉽게 사용하기 위한 커스텀 Hooks
function useUsersState() {
  const state = useContext(UsersStateContext);
  if (!state) throw new Error("Cannot find UsersProvider"); // 유효하지 않을땐 에러를 발생
  return state;
}

function useUsersDispatch() {
  const dispatch = useContext(UsersDispatchContext);
  if (!dispatch) throw new Error("Cannot find UsersProvider"); // 유효하지 않을땐 에러를 발생
  return dispatch;
}

export { UsersProvider, useUsersState, useUsersDispatch };
