import KakaoLoginButton from "@components/atoms/KakaoLoginButton";
import NaverLoginButton from "@components/atoms/NaverLoginButton";
// import {useUsersDispatch} from '@contexts/users.context';
import styled from "@emotion/styled";
import GoogleLoginButton from "@components/atoms/GoogleLoginButton";
import FacebookLoginButton from "@components/atoms/FacebookLoginButton";

import i18next from "i18next";

const ButtonsWrapper = styled.div`
  margin-bottom: 25px;
`;
const Separator = styled.div`
  width: 240px;
  height: 1.5px;
  margin: 20px 0;
  background-color: #eaeaea;
`;
// const PasswordFind = styled.div`
//   font-family: Montserrat;
//   font-size: 16px;
//   font-weight: normal;
//   line-height: 1.63;
//   letter-spacing: -0.32px;
//   color: #b8b8b8;
//   text-decoration: underline;
//   cursor: pointer;
// `;

const LoginButtons = () => {
  // const usersDispatch = useUsersDispatch();
  // const navigateToSignup = () => {
  //   usersDispatch({type: 'SET_ADDITINAL_INFO_NEEDED', needed: true});
  // };

  const lang = i18next.language.slice(0, 2);

  return (
    <ButtonsWrapper>
      {lang === "ko" ? (
        <>
          <KakaoLoginButton style={{ marginBottom: 12 }} />
          <NaverLoginButton style={{ width: "240px", height: "50px" }} />
        </>
      ) : (
        <></>
      )}
      {/* <FacebookLoginButton style={{ width: "240px", marginBottom: "12px" }} /> */}
      <Separator />
      <GoogleLoginButton style={{ width: "240px" }} />
    </ButtonsWrapper>
  );
};

export default LoginButtons;
