import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import GoogleLogin from "react-google-login";
import GoogleButton from "react-google-button";
import * as React from "react";
import { getUserInfo, login, signup } from "@api/user.api";
import apiAgent from "@api/api.agent";
import { useCommonDispatch } from "@contexts/common.context";
import { useUsersDispatch } from "@contexts/users.context";
import config from "@api/config";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useCardIdDispatch, useCardIdState } from "@contexts/cardId.context";
import useRedirectCardDetail from "@hooks/useRedirectCardDetail";

const StyledButton = styled.button`
  width: 360px;
  padding: 0px;
  ${mediaQueries("desktop")} {
    max-width: 100%;
    //min-width: 100%;
  }
  //margin-top: 20px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  //box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  line-height: 0.88;
  letter-spacing: -0.32px;
  color: #ffffff;
  cursor: pointer;
  background-color: #ffffff;
`;

export interface NaverButtonProps {
  style?: React.CSSProperties;
}

export default function GoogleLoginButton({ style }: NaverButtonProps) {
  const commonDispatch = useCommonDispatch();
  const usersDispatch = useUsersDispatch();
  const { redirectCardDetail } = useRedirectCardDetail();
  const history = useHistory();
  const { t } = useTranslation();

  const onLoginGoogle = async (result: any) => {
    const token: string = result.tokenId;
    if (!token) return;

    try {
      await handleGoogleToken(token);
    } catch (e) {
      console.log("googl : ", e);
    }
  };

  const handleGoogleToken = async (token: string) => {
    commonDispatch({ type: "SET_IS_LOADING", isLoading: true });
    const res = await login({ sns_token: token, sns_type: "google" });

    if (!res) {
      commonDispatch({ type: "SET_IS_LOADING", isLoading: false });
      return alert("로그인에 실패하였습니다.");
    }

    if (res === "NOT_REGISTERED") {
      const signupRes = await signup({ sns_token: token, sns_type: "google" });
      if (!signupRes) return alert("회원가입에 실패하였습니다.");

      const { access_token } = signupRes;
      await handleTokenUpdated(access_token);
      await getUserAndUpdate();

      history.push("/extraInfo", { agreement_displayed: true });
    } else {
      const { access_token, is_once_modified } = res;

      await handleTokenUpdated(access_token);

      if (!is_once_modified) {
        await handleNotModified();
      } else {
        await handleModified();
      }
    }
  };

  const handleTokenUpdated = async (access_token: string) => {
    commonDispatch({ type: "SET_IS_LOADING", isLoading: false });
    await apiAgent.updateToken(access_token);
    localStorage.setItem("token", access_token);
  };

  const getUserAndUpdate = async () => {
    const user = await getUserInfo({});
    if (user) usersDispatch({ type: "SET_USER_INFO", userInfo: user });
  };

  const handleNotModified = async () => {
    usersDispatch({ type: "SET_ADDITINAL_INFO_NEEDED", needed: true });
    history.push("/extraInfo", { agreement_displayed: true });
  };

  const handleModified = async () => {
    await getUserAndUpdate();
    redirectCardDetail();
  };

  return (
    <>
      <StyledButton onClick={() => {}} style={style}>
        <GoogleLogin
          clientId="565595769398-io5t9n20dj8jah5fa4ushts7opg2f6ss.apps.googleusercontent.com"
          onSuccess={onLoginGoogle}
          onFailure={(result) => console.log(result)}
          cookiePolicy={"single_host_origin"}
          redirectUri={config.NAVER_LOGIN.CALLBACK_URL}
          render={(renderProps) => (
            <GoogleButton
              onClick={renderProps.onClick}
              style={{ width: "360px" }}
              label={t("login.google")}
            />
          )}
        />
      </StyledButton>
    </>
  );
}
