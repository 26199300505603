import icArrowDown from '@assets/imgs/ic_arrow_down.png';
import styled from '@emotion/styled';
import { mediaQueries } from '@styles/common';
import { useState } from 'react';

const Wrapper = styled.div`
  display: none;
  ${mediaQueries('tablet')} {
    display: flex;
    position: absolute;
    right: 20px;
    top: 0px;
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  padding: 5px 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
`;

const ButtonText = styled.div`
  margin-left: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #8d8d8d;
  margin-right: 10px;
`;
const IcArrowDown = styled.img`
  width: 30px;
  height: 30px;
`;

const PopupWrapper = styled.div`
  position: absolute;
  z-index: 50;
  top: 60px;
  right: 0px;
  padding: 30px 45px;
  border-radius: 24px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  cursor: text;
`;


const PopupText = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  color: #8d8d8d;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0px;
  }
`;

interface PaymentMobileSorterProps {
  sortType: string;
  setSortType: (st: string) => void;
}

const PaymentMobileSorter = ({ sortType, setSortType }: PaymentMobileSorterProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const handleSorTypeChange = (st: string) => {
    setSortType(st);
    setTimeout(() => {
      setShowPopup(false);
    }, 0);
  };
  return (
    <Wrapper>
      <ButtonWrapper onClick={() => setShowPopup((v) => !v)}>
        <ButtonText>정렬방식</ButtonText>
        <IcArrowDown src={icArrowDown} />
      </ButtonWrapper>
      {showPopup && (
        <PopupWrapper>
          <PopupText onClick={() => handleSorTypeChange('orderNumber')}>주문번호순</PopupText>
          <PopupText onClick={() => handleSorTypeChange('orderDate')}>주문일자순</PopupText>
          <PopupText onClick={() => handleSorTypeChange('status')}>상태순</PopupText>
          <PopupText onClick={() => handleSorTypeChange('paymentMethod')}>결제방식순</PopupText>
          <PopupText onClick={() => handleSorTypeChange('amount')}>금액순</PopupText>
        </PopupWrapper>
      )}
    </Wrapper >
  );
};

export default PaymentMobileSorter;