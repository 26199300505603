import { OrderModel } from '@api/orders.api';
import NewPaymentTableRow from '@components/molecules/NewPaymentTableRow';
import PaymentTableRow from '@components/molecules/PaymentTableRow';
import styled from '@emotion/styled';
import { mediaQueries } from '@styles/common';

const Wrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 60px;
  max-width: 1200px;
  min-width: 1200px;
  ${mediaQueries('desktop')} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
`;

interface PaymentTableProps {
  list: OrderModel[];
  refresh: () => Promise<void>;
}

const PaymentTable = ({ list, refresh }: PaymentTableProps) => {
  return (
    <Wrapper>
      <NewPaymentTableRow />
      {list.map((orderItem, idx) => (
        <PaymentTableRow key={'payment-history-' + orderItem.id} orderItem={orderItem} refundSuccessCB={refresh} />
      ))}
    </Wrapper>
  );
};

export default PaymentTable;