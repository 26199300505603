import InfoText from "@components/atoms/InfoText";
import PaymentMobileSorter from "@components/molecules/PaymentMobileSorter";
import TablePagination from "@components/molecules/TablePaginaition";
import styled from "@emotion/styled";
import useOrderHistories from "@hooks/useOrderHistories";
import { mediaQueries } from "@styles/common";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PaymentTable from "../PaymentTable";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  min-height: calc(100vh - 700px);
  ${mediaQueries("tablet")} {
    padding-top: 80px;
  }
`;
const InfoTextWrapper = styled.div`
  margin-bottom: 50px;
  ${mediaQueries("mobileL")} {
    margin-bottom: 30px;
  }
`;
interface PaymentHistoryProps {}

const PaymentHistory = ({}: PaymentHistoryProps) => {
  const [sortType, setSortType] = useState("recent");
  const {
    list,
    pageIndex,
    setPageIndex,
    totalCount,
    refreshList,
  } = useOrderHistories();

  const { t } = useTranslation();
  return (
    <Wrapper>
      <InfoTextWrapper>
        <InfoText text={t("profile.refund_info")} />
      </InfoTextWrapper>
      <PaymentMobileSorter sortType={sortType} setSortType={setSortType} />
      <PaymentTable list={list} refresh={refreshList} />
      <TablePagination
        currentPage={pageIndex}
        totalCount={totalCount}
        setCurrentPage={setPageIndex}
      />
    </Wrapper>
  );
};

export default PaymentHistory;
