import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";

import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import SearchIconImg from "@assets/imgs/search_purple.png";
import { useState } from "react";
import useHashQuery from "@query/useHashQuery";
const Wrapper = styled.div`
  display: none;
  ${mediaQueries("desktopSmall")} {
    display: block;
    right: 100px;
    position: absolute;
    top: 57px;
    background-color: white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
    padding: 20px;
    min-height: 200px;
    border-radius: 36px;
    z-index: 100;
  }
`;
const SearchBarBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 250px;
  border: solid 2px #d8d8d8;
  background-color: #ffffff;
  border-radius: 17px;
  padding-left: 16px;
  position: relative;
  align-items: center;
`;

const SearchInput = styled.input`
  font-family: "Noto Sans KR", sans-serif;
  outline: none;
  width: 200px;
  font-size: 12px;
  font-weight: bold;
  border: none;
  height: 30px;
  &::placeholder {
    color: #d8d8d8;
  }
`;

const SearchBtn = styled.button`
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
`;
const SearchImg = styled.img`
  height: 18px;
  width: 18px;
`;

const SampleKeywordWapper = styled.div``;
const SmableKeywordBtn = styled.button`
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
const SampleKeyword = styled.p`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  margin-bottom: 7px;
  margin-top: 7px;
  font-weight: bold;
  color: #d8d8d8;
  text-align: left;
`;

const SmallDesktopSearch = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState<string>("");
  const search = (sampleKeyword?: string) => {
    history.push("/search", { keyword: sampleKeyword || keyword });
  };

  const {
    data: hashTags,
    isLoading: isHashTagsLoading,
    isError: isHashTagsError,
  } = useHashQuery();

  return (
    <Wrapper>
      <SearchBarBox>
        <SearchInput
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder={t("search.word")}
        />

        <SearchBtn onClick={() => search()}>
          <SearchImg src={SearchIconImg} />
        </SearchBtn>
      </SearchBarBox>
      <SampleKeywordWapper>
        {!isHashTagsLoading || !isHashTagsError ? (
          hashTags?.map((item: any) => {
            return (
              <SmableKeywordBtn
                onClick={() => {
                  search(item.name);
                }}
              >
                <SampleKeyword key={item.hashtag_id}>{item.name}</SampleKeyword>
              </SmableKeywordBtn>
            );
          })
        ) : (
          <></>
        )}
      </SampleKeywordWapper>
    </Wrapper>
  );
};

export default SmallDesktopSearch;
