import MainCard from "@components/atoms/MainCard";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import icArrowDown from "../../../assets/imgs/arrow_big_down@2x.png";
import Landing_KOR_01 from "@assets/imgs/landing_KOR_01.png";
import Landing_KOR_02 from "@assets/imgs/landing_KOR_02.png";
import Landing_KOR_03 from "@assets/imgs/landing_KOR_03.png";
import Landing_KOR_04 from "@assets/imgs/landing_KOR_04.png";
import Landing_ENG_01 from "@assets/imgs/landing_ENG_01.png";
import Landing_ENG_02 from "@assets/imgs/landing_ENG_02.png";
import Landing_ENG_03 from "@assets/imgs/landing_ENG_03.png";
import Landing_ENG_04 from "@assets/imgs/landing_ENG_04.png";

const Wrapper = styled.div`
  position: relative;
  max-width: 1200px;
  min-width: 1200px;
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  /* height: auto; */
  margin: 0 auto;
  padding-bottom: 20px;

  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("tablet")} {
    justify-content: center;
    height: calc(69vh - 70px);
    margin-bottom: 30px;
  }
  ${mediaQueries("mobileL")} {
    height: calc(100vh - 70px);
    padding-bottom: 70px;
  }
  ${mediaQueries("mobileSE")} {
    height: calc(100vh - 24px);
    padding-bottom: 70px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${mediaQueries("tablet")} {
    display: none;
  }
`;

const IcArrowDown = styled.img`
  cursor: pointer;
  //height: 22px;
  width: 58px;
`;

const VirtualDiv = styled.div`
  display: block;
  ${mediaQueries("tablet")} {
    display: none;
  }
`;

const imgArr = [
  "https://spoon5-image.s3.ap-northeast-2.amazonaws.com/landing+and+logo/landing_04.png",
  "https://spoon5-image.s3.ap-northeast-2.amazonaws.com/landing+and+logo/landing_01.png",
  "https://spoon5-image.s3.ap-northeast-2.amazonaws.com/landing+and+logo/landing_02.png",
  "https://spoon5-image.s3.ap-northeast-2.amazonaws.com/landing+and+logo/landing_03.png",
];
const imgArr_KOR_mobile = [
  Landing_KOR_04,
  Landing_KOR_01,
  Landing_KOR_02,
  Landing_KOR_03,
];
const imgArr_ENG_mobile = [
  Landing_ENG_04,
  Landing_ENG_01,
  Landing_ENG_02,
  Landing_ENG_03,
];

interface MainSectionProps {
  handleScroll: () => void;
}

const MainSection = ({ handleScroll }: MainSectionProps) => {
  const [currentImgIdx, setCurrentImgIdx] = useState(0);
  const history = useHistory();
  const { t } = useTranslation();

  const sliderTitleArr = [
    t("home.slider_title_3"),
    t("home.slider_title_1"),
    t("home.slider_title_2"),
  ];
  const sliderSubtitleArr = [
    t("home.slider_subtitle_3"),
    t("home.slider_subtitle_1"),
    t("home.slider_subtitle_2"),
  ];
  const sliderTitleArrMobile = [""];
  const sliderSubtitleArrMobile = [""];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImgIdx((v) => (v + 1) % 4);
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const onStartClick = () => {
    const lang = i18next.language.slice(0, 2);
    if (lang == "ko") {
      history.push("/cards/1");
    } else {
      history.push("/cards/2");
    }
  };

  const chooseImg = () => {
    const lang = i18next.language.slice(0, 2);
    if (window.screen.width >= 768) {
      return imgArr[currentImgIdx];
    } else if (lang == "en") {
      return imgArr_ENG_mobile[currentImgIdx];
    } else if (lang == "ko") {
      return imgArr_KOR_mobile[currentImgIdx];
    } else {
      return imgArr[currentImgIdx];
    }
  };

  const chooseTitle = () => {
    if (window.screen.width >= 768) {
      return sliderTitleArr[currentImgIdx];
    } else {
      return sliderTitleArrMobile[0];
    }
  };

  const chooseSubtitle = () => {
    if (window.screen.width >= 768) {
      return sliderSubtitleArr[currentImgIdx];
    } else {
      return sliderSubtitleArrMobile[0];
    }
  };
  return (
    <Wrapper>
      <VirtualDiv />
      <Container>
        <MainCard
          imgUrl={chooseImg()}
          title={chooseTitle()}
          subtitle={chooseSubtitle()}
          onStartClick={onStartClick}
        />
      </Container>
      <ArrowWrapper onClick={handleScroll}>
        <IcArrowDown src={icArrowDown} />
      </ArrowWrapper>
    </Wrapper>
  );
};

export default MainSection;
