import styled from "@emotion/styled";
import { ReactNode } from "react";
import { mediaQueries } from "@styles/common";

const StyledButton = styled.button<Partial<ButtonProps>>`
  position: relative;
  padding: 16px 41px;
  border-radius: 25px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  border: none;
  outline: none;
  white-space: nowrap;
  cursor: pointer;

  transition: background-color 0.1s ease-in-out;
  font-size: 16px;
  font-weight: bold;
  color: white;
  height: 55px;
  &:disabled {
    background-image: none;
    background-color: #eaeaea;
    cursor: not-allowed;
  }
  ${mediaQueries("mobileL")} {
    //margin-bottom: 60px;
  }
`;

export interface ButtonProps {
  type?: "button" | "submit";
  primary?: boolean;
  active?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  children?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  id?: string;
}

export default function Button({
  type,
  onClick,
  primary,
  active,
  style,
  disabled,
  className,
  children,
  id,
}: ButtonProps) {
  return (
    <StyledButton
      id={id}
      type={type || "button"}
      onClick={onClick}
      primary={primary}
      active={active}
      disabled={disabled}
      style={style}
      className={className}
    >
      {children}
    </StyledButton>
  );
}
