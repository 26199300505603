import { OrderModel, refundOrder } from "@api/orders.api";
import icCoin from "@assets/imgs/ic_starcoin.png";
import Button from "@components/atoms/Button";
import { useCommonDispatch } from "@contexts/common.context";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  margin-bottom: 20px;
  padding: 25px 40px;
  border-radius: 24px;
  display: flex;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  align-items: center;
  background-color: #ffffff;
  color: #2d2d2d;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  text-align: center;
  ${mediaQueries("tablet")} {
    padding: 15px 20px;
    padding-bottom: 40px;
  }
`;

const CoinWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const IcCoin = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 8px;
`;
const CoinText = styled.div`
  font-size: 20px;
  font-weight: 900;
`;
const OrderNumber = styled.div`
  flex: 2;
  text-align: right;
`;
const OrderDate = styled.div`
  flex: 2;
  ${mediaQueries("tablet")} {
    position: absolute;
    bottom: 15px;
    right: 25px;
    font-size: 12px;
    font-weight: normal;
    color: #b8b8b8;
  }
`;
const Status = styled.div`
  flex: 1;
  ${mediaQueries("tablet")} {
    display: none;
  }
`;
const PaymentMethod = styled.div`
  flex: 1;
  ${mediaQueries("tablet")} {
    display: none;
  }
`;
const Amount = styled.div`
  flex: 1;
  font-size: 20px;
  font-weight: 900;
  ${mediaQueries("tablet")} {
    display: none;
  }
`;
const BtnWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${mediaQueries("tablet")} {
    display: none;
    /* position: absolute;
    bottom: 0;
    right: 0; */
  }
`;

interface PaymentTableRowProps {
  orderItem: OrderModel;
  refundSuccessCB: () => Promise<void>;
}

const PaymentTableRow = ({
  orderItem,
  refundSuccessCB,
}: PaymentTableRowProps) => {
  const commonDispatch = useCommonDispatch();
  const handleRefund = async () => {
    commonDispatch({ type: "SET_IS_LOADING", isLoading: true });
    const res = await refundOrder({ capture_id: orderItem.capture_id });
    if (res === true) await refundSuccessCB();
    if (res === false) alert("already refunded or you already used starcoin.");
    commonDispatch({ type: "SET_IS_LOADING", isLoading: false });
    window.location.reload();
  };
  const { t } = useTranslation();
  return (
    <Wrapper>
      <CoinWrapper>
        <IcCoin src={icCoin} />
        <CoinText>x {orderItem.coin}</CoinText>
      </CoinWrapper>
      <OrderNumber>{orderItem.order_id}</OrderNumber>
      <OrderDate>
        {moment(orderItem.created_at).format("YYYY-MM-DD HH:mm")}
      </OrderDate>
      <Status>{orderItem.status}</Status>
      <PaymentMethod>{orderItem.type}</PaymentMethod>
      <Amount>{orderItem.payment} $</Amount>
      <BtnWrapper onClick={handleRefund}>
        <Button style={{ padding: "8px 20px", borderRadius: "14px" }}>
          {t("profile.refund")}
        </Button>
      </BtnWrapper>
    </Wrapper>
  );
};

export default PaymentTableRow;
