import Loading from "@components/atoms/Loading";
import styled from "@emotion/styled";

import Spacing from "@components/atoms/Spacing";
import Footer from "@components/molecules/Footer";
import Header from "@components/molecules/Header";

import * as React from "react";

import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import MainSection from "@components/organisms/MainSection";

import { mediaQueries } from "@styles/common";
import Card from "@components/atoms/Card";
import { useCardIdDispatch } from "@contexts/cardId.context";
import useCardsByCategoryQuery from "@query/useCardsByCategory";
import useGetLatestCardsQuery from "@query/useLatestCardsQuery";
import CustomCarousel from "@components/atoms/CustomCarousel";

const Home: React.FC = () => {
  const cardIdDispatch = useCardIdDispatch();

  const history = useHistory();
  const myRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  // const {
  //   data,
  //   fetchNextPage,
  //   hasNextPage,
  //   isLoading,
  //   isFetchingNextPage,
  // } = useAllCardsQuery({
  //   pageSize: 6,
  // });

  const {
    data: latestCards,
    isLoading: isLatestCardsLoading,
  } = useGetLatestCardsQuery();

  const {
    data: lucksCards,
    isLoading: isLucksLoading,
  } = useCardsByCategoryQuery({ category: "lucks" });

  const {
    data: themaLoveCards,
    isLoading: isThemaLoveLoading,
  } = useCardsByCategoryQuery({ category: "thema_love" });

  const {
    data: compatibilityCards,
    isLoading: isCompatibilityCardsLoading,
  } = useCardsByCategoryQuery({ category: "compatibility" });

  const {
    data: mbtiLoveCards,
    isLoading: isMBTILoveCardsLoading,
  } = useCardsByCategoryQuery({ category: "mbti_love" });

  const {
    data: mbtiCharacterCards,
    isLoading: isMbtiCharacterCardsLoading,
  } = useCardsByCategoryQuery({ category: "mbti_character" });

  const {
    data: mbtiCards,
    isLoading: isMBTICardsLoading,
  } = useCardsByCategoryQuery({ category: "mbti" });

  const {
    data: twoMakeMBTICards,
    isLoading: isTwoMakeMBTILoading,
  } = useCardsByCategoryQuery({ category: "two_make_mbti" });

  const {
    data: ignorantAboutMeCards,
    isLoading: isIgnorantAboutMeCardsLoading,
  } = useCardsByCategoryQuery({ category: "ignorant_about_me" });

  const {
    data: fittedWithMeCards,
    isLoading: isFittedWithMeCardsLoading,
  } = useCardsByCategoryQuery({ category: "fitted_with_me" });

  const resetCardId = React.useCallback(() => {
    cardIdDispatch({ type: "SET_CARD_ID", cardId: "" });
  }, [cardIdDispatch]);

  React.useEffect(() => {
    resetCardId();
  }, [resetCardId]);

  const handleCardClick = (curId: number) => {
    history.push("/cards/" + curId);
  };

  const executeScroll = () => {
    myRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {/* {(isLoading || isFetchingNextPage) && <Loading />} */}

      <Container>
        {isLatestCardsLoading && <Loading />}
        <Header />
        <Spacing height={"56px"} />
        <MainSection handleScroll={executeScroll} />
        <div ref={myRef} />
        <Spacing height={"80px"} />
        <pre>
          <Title>{t("home.title")}</Title>
        </pre>
        {/* {data?.pages.map((item) => (
          <>
            {item?.total_cards ? (
              item.total_cards.map((info) => (
                <Grid>
                  <Card
                    key={"idx-" + info.title + "-" + info.card_id}
                    imgUrl={info.thumbnail_url}
                    description={info.title}
                    onClick={() => handleCardClick(info.card_id!)}
                  />
                </Grid>
              ))
            ) : (
              <></>
            )}
          </>
        ))} */}
        <DesktopView>
          {!isIgnorantAboutMeCardsLoading &&
            ignorantAboutMeCards &&
            ignorantAboutMeCards.cards.length > 0 && (
              <CategoryWrapper>
                <Category>{t("home.ignorant_about_me")}</Category>
                <CustomCarousel>
                  {ignorantAboutMeCards?.cards.map((info: any) => (
                    <Grid>
                      <Card
                        key={"idx-" + info.title + "-" + info.card_id}
                        imgUrl={info.thumbnail_url}
                        description={info.title}
                        onClick={() => handleCardClick(info.card_id!)}
                      />
                    </Grid>
                  ))}
                </CustomCarousel>
              </CategoryWrapper>
            )}
          {!isFittedWithMeCardsLoading &&
            fittedWithMeCards &&
            fittedWithMeCards.cards.length > 0 && (
              <CategoryWrapper>
                <Category>{t("home.fitted_with_me")}</Category>
                <CustomCarousel>
                  {fittedWithMeCards?.cards.map((info: any) => (
                    <Grid>
                      <Card
                        key={"idx-" + info.title + "-" + info.card_id}
                        imgUrl={info.thumbnail_url}
                        description={info.title}
                        onClick={() => handleCardClick(info.card_id!)}
                      />
                    </Grid>
                  ))}
                </CustomCarousel>
              </CategoryWrapper>
            )}
          {!isLucksLoading && lucksCards && lucksCards.cards.length > 0 && (
            <CategoryWrapper>
              <Category>{t("home.lucks")}</Category>
              <CustomCarousel>
                {lucksCards?.cards.map((info: any) => (
                  <Grid>
                    <Card
                      key={"idx-" + info.title + "-" + info.card_id}
                      imgUrl={info.thumbnail_url}
                      description={info.title}
                      onClick={() => handleCardClick(info.card_id!)}
                    />
                  </Grid>
                ))}
              </CustomCarousel>
            </CategoryWrapper>
          )}
          {!isLatestCardsLoading &&
            latestCards &&
            latestCards.cards.length > 0 && (
              <CategoryWrapper>
                <Category>{t("home.latest")}</Category>
                <CustomCarousel>
                  {latestCards?.cards.map((info: any) => (
                    <Grid>
                      <Card
                        key={"idx-" + info.title + "-" + info.card_id}
                        imgUrl={info.thumbnail_url}
                        description={info.title}
                        onClick={() => handleCardClick(info.card_id!)}
                      />
                    </Grid>
                  ))}
                </CustomCarousel>
              </CategoryWrapper>
            )}
          {!isThemaLoveLoading &&
            themaLoveCards &&
            themaLoveCards.cards.length > 0 && (
              <CategoryWrapper>
                <Category>{t("home.thema_love")}</Category>
                <CustomCarousel>
                  {themaLoveCards?.cards.map((info) => (
                    <Grid>
                      <Card
                        key={"idx-" + info.title + "-" + info.card_id}
                        imgUrl={info.thumbnail_url}
                        description={info.title}
                        onClick={() => handleCardClick(info.card_id!)}
                      />
                    </Grid>
                  ))}
                </CustomCarousel>
              </CategoryWrapper>
            )}
          {!isCompatibilityCardsLoading &&
            compatibilityCards &&
            compatibilityCards.cards.length > 0 && (
              <CategoryWrapper>
                <Category>{t("home.compatibility")}</Category>
                <CustomCarousel>
                  {compatibilityCards?.cards.map((info) => (
                    <Grid>
                      <Card
                        key={"idx-" + info.title + "-" + info.card_id}
                        imgUrl={info.thumbnail_url}
                        description={info.title}
                        onClick={() => handleCardClick(info.card_id!)}
                      />
                    </Grid>
                  ))}
                </CustomCarousel>
              </CategoryWrapper>
            )}
          {/* {!isMbtiCharacterCardsLoading &&
          mbtiCharacterCards &&
          mbtiCharacterCards.cards.length > 0 && (
            <CategoryWrapper>
              <Category>{t("home.mbti_character")}</Category>
              <CustomCarousel>
                {mbtiCharacterCards?.cards.map((info) => (
                  <Grid>
                    <Card
                      key={"idx-" + info.title + "-" + info.card_id}
                      imgUrl={info.thumbnail_url}
                      description={info.title}
                      onClick={() => handleCardClick(info.card_id!)}
                    />
                  </Grid>
                ))}
              </CustomCarousel>
            </CategoryWrapper>
          )} */}
          {!isMBTICardsLoading && mbtiCards && mbtiCards.cards.length > 0 && (
            <CategoryWrapper>
              <Category>{t("home.mbti_character")}</Category>
              <CustomCarousel>
                {mbtiCards?.cards.map((info) => (
                  <Grid>
                    <Card
                      key={"idx-" + info.title + "-" + info.card_id}
                      imgUrl={info.thumbnail_url}
                      description={info.title}
                      onClick={() => handleCardClick(info.card_id!)}
                    />
                  </Grid>
                ))}
              </CustomCarousel>
            </CategoryWrapper>
          )}
          {!isMBTILoveCardsLoading &&
            mbtiLoveCards &&
            mbtiLoveCards.cards.length > 0 && (
              <CategoryWrapper>
                <Category>{t("home.mbti_love")}</Category>
                <CustomCarousel>
                  {mbtiLoveCards?.cards.map((info) => (
                    <Grid>
                      <Card
                        key={"idx-" + info.title + "-" + info.card_id}
                        imgUrl={info.thumbnail_url}
                        description={info.title}
                        onClick={() => handleCardClick(info.card_id!)}
                      />
                    </Grid>
                  ))}
                </CustomCarousel>
              </CategoryWrapper>
            )}
          {!isTwoMakeMBTILoading &&
            twoMakeMBTICards &&
            twoMakeMBTICards.cards.length > 0 && (
              <CategoryWrapper>
                <Category>{t("home.two_make_mbti")}</Category>
                <CustomCarousel>
                  {twoMakeMBTICards?.cards.map((info) => (
                    <Grid>
                      <Card
                        key={"idx-" + info.title + "-" + info.card_id}
                        imgUrl={info.thumbnail_url}
                        description={info.title}
                        onClick={() => handleCardClick(info.card_id!)}
                      />
                    </Grid>
                  ))}
                </CustomCarousel>
              </CategoryWrapper>
            )}
        </DesktopView>

        <MobileView>
          {!isIgnorantAboutMeCardsLoading &&
            ignorantAboutMeCards &&
            ignorantAboutMeCards.cards.length > 0 && (
              <CategoryWrapper>
                <Category>{t("home.ignorant_about_me")}</Category>
                <Wrapper>
                  {ignorantAboutMeCards?.cards.map((info: any) => (
                    <MobileGrid>
                      <Card
                        key={"idx-" + info.title + "-" + info.card_id}
                        imgUrl={info.thumbnail_url}
                        description={info.title}
                        onClick={() => handleCardClick(info.card_id!)}
                      />
                    </MobileGrid>
                  ))}
                </Wrapper>
              </CategoryWrapper>
            )}
          {!isFittedWithMeCardsLoading &&
            fittedWithMeCards &&
            fittedWithMeCards.cards.length > 0 && (
              <CategoryWrapper>
                <Category>{t("home.fitted_with_me")}</Category>
                <Wrapper>
                  {fittedWithMeCards?.cards.map((info: any) => (
                    <MobileGrid>
                      <Card
                        key={"idx-" + info.title + "-" + info.card_id}
                        imgUrl={info.thumbnail_url}
                        description={info.title}
                        onClick={() => handleCardClick(info.card_id!)}
                      />
                    </MobileGrid>
                  ))}
                </Wrapper>
              </CategoryWrapper>
            )}
          {!isLucksLoading && lucksCards && lucksCards.cards.length > 0 && (
            <CategoryWrapper>
              <Category>{t("home.lucks")}</Category>
              <Wrapper>
                {lucksCards?.cards.map((info: any) => (
                  <MobileGrid>
                    <Card
                      key={"idx-" + info.title + "-" + info.card_id}
                      imgUrl={info.thumbnail_url}
                      description={info.title}
                      onClick={() => handleCardClick(info.card_id!)}
                    />
                  </MobileGrid>
                ))}
              </Wrapper>
            </CategoryWrapper>
          )}

          {!isLatestCardsLoading &&
            latestCards &&
            latestCards.cards.length > 0 && (
              <CategoryWrapper>
                <Category>{t("home.latest")}</Category>
                <Wrapper>
                  {latestCards?.cards.map((info: any) => (
                    <MobileGrid>
                      <Card
                        key={"idx-" + info.title + "-" + info.card_id}
                        imgUrl={info.thumbnail_url}
                        description={info.title}
                        onClick={() => handleCardClick(info.card_id!)}
                      />
                    </MobileGrid>
                  ))}
                </Wrapper>
              </CategoryWrapper>
            )}
          {!isThemaLoveLoading &&
            themaLoveCards &&
            themaLoveCards.cards.length > 0 && (
              <CategoryWrapper>
                <Category>{t("home.thema_love")}</Category>
                <Wrapper>
                  {themaLoveCards?.cards.map((info) => (
                    <MobileGrid>
                      <Card
                        key={"idx-" + info.title + "-" + info.card_id}
                        imgUrl={info.thumbnail_url}
                        description={info.title}
                        onClick={() => handleCardClick(info.card_id!)}
                      />
                    </MobileGrid>
                  ))}
                </Wrapper>
              </CategoryWrapper>
            )}
          {!isCompatibilityCardsLoading &&
            compatibilityCards &&
            compatibilityCards.cards.length > 0 && (
              <CategoryWrapper>
                <Category>{t("home.compatibility")}</Category>
                <Wrapper>
                  {compatibilityCards?.cards.map((info) => (
                    <MobileGrid>
                      <Card
                        key={"idx-" + info.title + "-" + info.card_id}
                        imgUrl={info.thumbnail_url}
                        description={info.title}
                        onClick={() => handleCardClick(info.card_id!)}
                      />
                    </MobileGrid>
                  ))}
                </Wrapper>
              </CategoryWrapper>
            )}
          {!isMBTICardsLoading && mbtiCards && mbtiCards.cards.length > 0 && (
            <CategoryWrapper>
              <Category>{t("home.mbti_character")}</Category>
              <Wrapper>
                {mbtiCards?.cards.map((info) => (
                  <MobileGrid>
                    <Card
                      key={"idx-" + info.title + "-" + info.card_id}
                      imgUrl={info.thumbnail_url}
                      description={info.title}
                      onClick={() => handleCardClick(info.card_id!)}
                    />
                  </MobileGrid>
                ))}
              </Wrapper>
            </CategoryWrapper>
          )}
          {!isMBTILoveCardsLoading &&
            mbtiLoveCards &&
            mbtiLoveCards.cards.length > 0 && (
              <CategoryWrapper>
                <Category>{t("home.mbti_love")}</Category>
                <Wrapper>
                  {mbtiLoveCards?.cards.map((info) => (
                    <MobileGrid>
                      <Card
                        key={"idx-" + info.title + "-" + info.card_id}
                        imgUrl={info.thumbnail_url}
                        description={info.title}
                        onClick={() => handleCardClick(info.card_id!)}
                      />
                    </MobileGrid>
                  ))}
                </Wrapper>
              </CategoryWrapper>
            )}
          {!isTwoMakeMBTILoading &&
            twoMakeMBTICards &&
            twoMakeMBTICards.cards.length > 0 && (
              <CategoryWrapper>
                <Category>{t("home.two_make_mbti")}</Category>
                <CustomCarousel>
                  {twoMakeMBTICards?.cards.map((info) => (
                    <Grid>
                      <Card
                        key={"idx-" + info.title + "-" + info.card_id}
                        imgUrl={info.thumbnail_url}
                        description={info.title}
                        onClick={() => handleCardClick(info.card_id!)}
                      />
                    </Grid>
                  ))}
                </CustomCarousel>
              </CategoryWrapper>
            )}
        </MobileView>
        {/* {hasNextPage && (
            <MoreButton
              onClick={() => {
                fetchNextPage();
              }}
            />
          )} */}
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  padding-bottom: 150px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 33px;
  text-align: center;
  padding: 0px 0 30px 0;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    font-size: 22px;
  }
`;
const Wrapper = styled.div`
  flex-direction: row;
  overflow-x: scroll;
  width: 100%;
  display: flex;
`;
const Grid = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
`;

const MobileGrid = styled.div`
  display: grid;
  min-width: 378px;
  max-width: 378px;
  width: 378px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: content-box;
  &:first-child {
    padding-left: 60px;
  }
  &:last-child {
    padding-right: 60px;
  }
  ${mediaQueries("tablet")} {
    min-width: 300px;
    max-width: 300px;
    width: 300px;
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
`;

const CategoryWrapper = styled.div``;

const Category = styled.p`
  text-align: left;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 26px;
  font-weight: 900;
  text-align: left;
  margin-left: 60px;
  color: #2d2d2d;
  ${mediaQueries("tablet")} {
    margin-left: 20px;
    font-size: 20px;
  }
`;

const DesktopView = styled.div`
  ${mediaQueries("tablet")} {
    display: none;
  }
`;

const MobileView = styled.div`
  display: none;
  ${mediaQueries("tablet")} {
    display: block;
  }
`;

export default Home;
