import i18next from "i18next";
import Axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

class ApiAgent {
  public axios: AxiosInstance;
  constructor() {
    this.axios = Axios.create();
    this.boot();
  }
  private async boot() {
    const token = localStorage.getItem("token");
    if (token) this.axios.defaults.headers.common["access-token"] = token;
    this.registerInterceptor();
  }
  public updateToken = async (token: string) => {
    if (token) {
      this.axios.defaults.headers.common["access-token"] = token;
    }
  };
  private registerInterceptor = () => {
    this.axios.interceptors.response.use(
      function (response: AxiosResponse) {
        return response;
      },
      async function (error: AxiosError) {
        if (error.response) {
          const { status } = error.response;
          const lang = i18next.language.slice(0, 2);
          const errorMsg =
            lang === "ko"
              ? "인증이 만료 되었습니다. 다시 로그인 해주세요"
              : "your authorization has expired and you need to log in again.";
          if (status === 401 || status === 403) {
            alert(errorMsg);
            localStorage.removeItem("token");
            window.location.reload();
            window.location.replace("/login");
          }
        }
        return Promise.reject(error);
      }
    );
  };
}

export default new ApiAgent();
