import React from "react";

interface SpacingProps {
  height: string;
}

const Spacing = ({ height }: SpacingProps) => {
  return <div style={{ height }}></div>;
};

export default Spacing;
