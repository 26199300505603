import icPlus from '@assets/imgs/ic_plus.png';
import styled from '@emotion/styled';
import {useTranslation} from "react-i18next";
import {mediaQueries} from "@styles/common";

const Wrapper = styled.div`
  margin-bottom: 20px;
  padding: 25px 35px;
  border-radius: 24px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(94deg, #4938f3 1%, #7f41fd);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  ${mediaQueries('mobileL')} {
    padding: 17px 35px;
  }
`;

const Text = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.4px;
  text-align: left;
  color: #ffffff;
`;
const IcPlus = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 30px;
`;

interface NewBirthTableRowProps {
  onClick: () => void;
}

const NewBirthTableRow = ({ onClick }: NewBirthTableRowProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper onClick={onClick}>
      <IcPlus src={icPlus} />
      <Text>{t('births.table_title')}</Text>
    </Wrapper>
  );
};

export default NewBirthTableRow;
