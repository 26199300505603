/* eslint-disable react-hooks/exhaustive-deps */
import { getBirthList } from "@api/births.api";
import { getCardDetail } from "@api/cards.api";
import DetailCard from "@components/atoms/DetailCard";
import Spacing from "@components/atoms/Spacing";
import BirthModal from "@components/molecules/BirthModal";
import Footer from "@components/molecules/Footer";
import Header from "@components/molecules/Header";
import CardRow from "@components/organisms/CardRow";
import { useBirthsDispatch, useBirthsState } from "@contexts/births.context";
import { useCardsDispatch, useCardsState } from "@contexts/cards.context";
import React, { useEffect, useCallback, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { getCardResult } from "@api/card.result.api";
import { useUsersDispatch, useUsersState } from "@contexts/users.context";
import StarcoinModal from "@components/molecules/StarcoinModal";
import UseCoinConfirmModal from "@components/molecules/UseCoinConfirmModal";
import DoubleBirthDetailCard from "@components/atoms/DoubleBirthDetailCard";
import NotEnoughCoinModal from "@components/molecules/NotEnoughCoinModal";
import { useCommonDispatch } from "@contexts/common.context";
import { useCardIdDispatch } from "@contexts/cardId.context";
import moment from "moment";
import BirthModalWithoutLogin from "@components/molecules/BirthModalWithoutLogin";
import useIsLoggedIn from "@hooks/useIsLoggedIn";

export const LUCKS_TABS = ["TODAY", "SOMEDAY"];
export const HAS_TABS = [
  "today_love_trouble_lovers",
  "today_general_lucks",
  "today_love_trouble_couple",
  "today_love_harmony",
];

export const LOGIN_FREE = [
  "today_general_lucks",
  "today_love_trouble_lovers",
  "today_love_trouble_couple",
  "compatibility_samgi",
  "compatibility_ddi",
  "compatibility_ohang",
  "compatibility_ilgi",
  "basic_characters_each",
  "hidden_characters_4",
];

export const ONLY_MAN = ["violence"];
export const HAS_SPECIFIC_DAY = [
  ...HAS_TABS,
  "specific_love_trouble_lovers_month",
  "specific_love_trouble_couples_month",
];
const 임시로직 = ["dangerous_when_thisyear", "dangerous_when_8years"];

const HAS_SHOW_MORE = [
  "dangerous_when_thisyear",
  "marriage_timing_this_year",
  "hidden_characters_6_each",
  "hidden_characters_6",
];

interface ParamTypes {
  id: string;
}

export enum BirthType {
  OTHER_BIRTH = "OTHER_BIRTH",
  SECOND_OTHER_BIRTH = "SECOND_OTHER_BIRTH",
}

declare global {
  interface Window {
    Kakao: any;
  }
}

const CardDetail: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const commonDispatch = useCommonDispatch();
  const cardsDispatch = useCardsDispatch();
  const birthsDispatch = useBirthsDispatch();
  const cardIdDispatch = useCardIdDispatch();
  const { userInfo } = useUsersState();
  const isLoggedIn = useIsLoggedIn();
  const { list, otherBirth, secondOtherBirth } = useBirthsState();

  const usersDispatch = useUsersDispatch();
  const [showBirthModal, setShowBirthModal] = React.useState(false);
  const [
    showBirthModalWithoutLogin,
    setShowBirthModalWithoutLogin,
  ] = React.useState(false);

  const [showStarcoinModal, setStarcoinModal] = React.useState(false);
  const [showNotEnoughCoinModal, setShowNotEnoughCoinModal] = React.useState(
    false
  );
  const [requiredStarcoin, setRequiredStarcoin] = React.useState<number>(0);
  const [type, setType] = React.useState<string | undefined>("");

  const { current_card, current_popular_cards } = useCardsState();

  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { t } = useTranslation();

  const [showConfirmModal, setShowConfirmModal] = React.useState<boolean>(
    false
  );

  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [selectedTab, setSelectedTab] = useState<string>(LUCKS_TABS[0]);
  const [isLoginFree, setIsLoginFree] = useState(false);

  const [birthType, setBirthType] = useState<BirthType>(BirthType.OTHER_BIRTH);
  const isSomeday =
    (current_card?.type &&
      HAS_SPECIFIC_DAY.includes(current_card.type) &&
      selectedTab === LUCKS_TABS[1]) ||
    current_card?.type === "specific_love_trouble_lovers_month" ||
    current_card?.type === "specific_love_trouble_couples_month";

  const isPossibleOnlyMan =
    current_card?.type && ONLY_MAN.includes(current_card.type);

  const is임시로직 = current_card?.type && 임시로직.includes(current_card.type);
  const checkValidDate = useCallback(() => {
    if (!year || !month || !day) {
      return false;
    }
    if (!moment(`${year}-${month}-${day}`, "YYYY-MM-DD").isValid()) {
      alert("유효한 날짜가 아닙니다.");
      return false;
    }
    return true;
  }, [day, month, year]);

  const checkNeedStarcoin = useCallback((): boolean => {
    const user_normal_starcoin_balance = userInfo?.normal_token_balance || 0;
    const user_bonus_starcoin_balance = userInfo?.bonus_token_balance || 0;
    if (
      user_bonus_starcoin_balance + user_normal_starcoin_balance <
        requiredStarcoin &&
      requiredStarcoin !== 0
    ) {
      return true;
    }
    return false;
  }, [
    requiredStarcoin,
    userInfo?.bonus_token_balance,
    userInfo?.normal_token_balance,
  ]);

  const calStarcoinBalance = useCallback(() => {
    const user_normal_starcoin_balance = userInfo?.normal_token_balance || 0;
    if (!userInfo) {
      return;
    }
    userInfo.normal_token_balance =
      user_normal_starcoin_balance - (requiredStarcoin || 0);
    usersDispatch({ type: "SET_USER_INFO", userInfo: userInfo });
  }, [requiredStarcoin, userInfo, usersDispatch]);

  const _getCardResult = useCallback(
    async (payload: any) => {
      try {
        const res = await getCardResult(payload);
        return res;
      } catch (e) {
        commonDispatch({ type: "SET_IS_LOADING", isLoading: false });
        console.log("main list error : ", e);
        throw e;
      } finally {
        setIsLoading(false);
      }
    },
    [commonDispatch]
  );

  const handleStartClick = useCallback(
    async (curId: string) => {
      if (!current_card) return;
      if (!isLoginFree && !userInfo) return;

      try {
        if (checkNeedStarcoin()) {
          setStarcoinModal(true);
          return;
        }

        let payload = null;

        if (current_card.is_required_person) {
          if (!otherBirth || !secondOtherBirth) return;
          commonDispatch({ type: "SET_IS_LOADING", isLoading: true });
          payload = {
            personal_info: otherBirth,
            personal_info_2: is임시로직
              ? {
                  ...secondOtherBirth,
                  gender:
                    otherBirth.gender === secondOtherBirth.gender
                      ? otherBirth.gender === "MAN"
                        ? "WOMAN"
                        : "MAN"
                      : secondOtherBirth.gender,
                }
              : secondOtherBirth,
            endpoint: type,
            required_starcoin: requiredStarcoin,
            card_id: curId,
          };
        } else {
          if (!otherBirth) return;
          if (isPossibleOnlyMan && otherBirth.gender === "WOMAN") {
            alert("남성만 입력이 가능합니다.");
            return;
          }
          commonDispatch({ type: "SET_IS_LOADING", isLoading: true });
          payload = {
            personal_info: otherBirth,
            endpoint: type,
            required_starcoin: requiredStarcoin,
            card_id: curId,
          };
        }

        if (isSomeday) {
          payload = {
            personal_info: otherBirth,
            personal_info_2: secondOtherBirth,
            endpoint: type,
            required_starcoin: requiredStarcoin,
            card_id: curId,
            day_info: `${year}-${month}-${day}`,
          };
        }

        const res = await _getCardResult(payload);

        if (res) {
          calStarcoinBalance();
          commonDispatch({ type: "SET_IS_LOADING", isLoading: false });

          if (current_card.type && HAS_SHOW_MORE.includes(current_card.type)) {
            history.push("/cards/" + curId + "/result", {
              res: res,
              required_starcoin: requiredStarcoin,
              otherBirth,
              secondOtherBirth,
            });
            return;
          }

          if (
            current_card.type &&
            current_card.type === "hidden_characters_4_each"
          ) {
            history.push("/cards/" + curId + "/result", {
              res: res,
              required_starcoin: requiredStarcoin,
              otherBirth,
            });
            return;
          }
          history.push("/cards/" + curId + "/result", {
            res: res,
            required_starcoin: requiredStarcoin,
          });
        }
      } catch (e) {
        alert(t("error.exception_error"));
        commonDispatch({ type: "SET_IS_LOADING", isLoading: false });
      }
    },
    [
      _getCardResult,
      calStarcoinBalance,
      checkNeedStarcoin,
      commonDispatch,
      current_card,
      day,
      history,
      isLoginFree,
      isSomeday,
      month,
      otherBirth,
      requiredStarcoin,
      secondOtherBirth,
      type,
      userInfo,
      year,
    ]
  );

  const resetBirthsDispatch = useCallback(() => {
    birthsDispatch({ type: "SET_OTHER_BIRTH", otherBirth: null });
    birthsDispatch({
      type: "SET_SECOND_OTHER_BIRTH",
      secondOtherBirth: null,
    });
  }, [birthsDispatch]);

  useEffect(() => {
    if (!current_card?.type) {
      return;
    }
    setIsLoginFree(LOGIN_FREE.includes(current_card.type));
  }, [current_card?.type]);

  useEffect(() => {
    if (
      isLoggedIn &&
      current_card?.type &&
      HAS_SPECIFIC_DAY.includes(current_card?.type)
    ) {
      birthsDispatch({ type: "SET_OTHER_BIRTH", otherBirth: list[0] });
    }

    return resetBirthsDispatch;
  }, [
    birthsDispatch,
    current_card?.type,
    list,
    resetBirthsDispatch,
    isLoggedIn,
  ]);

  useEffect(() => {
    if (!id) return;

    const _fetchCurrentCard = async () => {
      try {
        const res = await getCardDetail({ id });
        if (res.status === 400) {
          // CardDetail에 들어가서 한/영 변환을 누르는 경우 card_id 와 lang_type 매칭이 안되는 경우 400 발생 가능
          // 그래서 언어 변환이 가능하도록 Home 으로 redirect 시킴
          history.push("");
          return;
        }

        setType(res.card.type);
        setRequiredStarcoin(res.card.required_starcoin);
        cardsDispatch({
          type: "SET_CURRENT_CARD",
          current_card: res.card,
          current_popular_cards: res.popular_cards,
        });
        setIsLoading(false);
      } catch (e) {
        console.log("main list error : ", e);
        setIsLoading(false);
      }
    };

    const _fetchBirthList = async () => {
      try {
        const res = await getBirthList({});

        if (!res) return;
        birthsDispatch({ type: "SET_LIST", list: res.births });
        setIsLoading(false);
      } catch (e) {
        console.log("main list error : ", e);
        setIsLoading(false);
      }
    };
    _fetchBirthList();
    _fetchCurrentCard();
  }, [id]);

  const resetCardsDispatch = () => {
    cardsDispatch({
      type: "SET_CURRENT_CARD",
      current_card: null,
      current_popular_cards: [],
    });
  };
  const handlePrev = () => {
    resetCardsDispatch();
    history.replace("/");
  };

  const handleCardClick = (curId: number) => {
    history.replace("/cards/" + curId);
  };
  const balanace = React.useMemo(() => {
    if (!userInfo) return 0;
    else {
      const _normal = userInfo.normal_token_balance || 0;
      const _bonus = userInfo.bonus_token_balance || 0;
      return _normal + _bonus;
    }
  }, [userInfo]);

  const haveEnoughCoin = React.useMemo(() => {
    if (!current_card) return false;

    return balanace >= current_card.required_starcoin;
  }, [balanace, current_card]);

  const _handleStartClick = () => {
    if (!current_card || current_card.required_starcoin === undefined) return;

    if (!haveEnoughCoin) return setShowNotEnoughCoinModal(true);

    if (isSomeday) {
      if (!checkValidDate()) {
        return;
      }
    }

    if (current_card.required_starcoin > 0) {
      setShowConfirmModal(true);
    } else {
      handleStartClick(id);
    }
  };

  const onBirthClick = (birthType: BirthType) => {
    setBirthType(birthType);
    if (isLoginFree && !isLoggedIn) {
      setShowBirthModalWithoutLogin(true);
      return;
    }
    setShowBirthModal(true);
  };

  useEffect(() => {
    if (!isLoggedIn && !isLoginFree && !isLoading) {
      cardIdDispatch({ type: "SET_CARD_ID", cardId: id });
      history.replace("/login");
    }
  }, [isLoggedIn, isLoginFree, isLoading]);

  if (isLoading || !current_card)
    return (
      <>
        <Header />
        <Spacing height={"90vh"} />
        <Footer />
      </>
    );

  return (
    <>
      <Header />
      <UseCoinConfirmModal
        show={showConfirmModal}
        onConfirmClick={() => handleStartClick(id)}
        onPrevClick={() => setShowConfirmModal(false)}
        coin={current_card.required_starcoin || 0}
        personalInfo={otherBirth}
        secondPersonInfo={
          current_card?.is_required_person ? secondOtherBirth : null
        }
        disclaimer={t("starcoin.disclaimer")}
        disclaimer_1={t("starcoin.disclaimer_1")}
      />
      <NotEnoughCoinModal
        show={showNotEnoughCoinModal}
        onConfirmClick={() =>
          history.push({ pathname: "/shop", state: { cardId: id } })
        }
        onPrevClick={() => setShowNotEnoughCoinModal(false)}
      />
      <BirthModal
        show={showBirthModal}
        onPrevClick={() => setShowBirthModal(false)}
        current_card={current_card}
      />
      <BirthModalWithoutLogin
        birthType={birthType}
        show={showBirthModalWithoutLogin}
        onPrevClick={() => setShowBirthModalWithoutLogin(false)}
        current_card={current_card}
      />
      <StarcoinModal
        show={showStarcoinModal}
        title={t("starcoin.title")}
        desc={t("starcoin.desc")}
        buttonDesc={t("starcoin.button")}
        onPrevClick={() => setStarcoinModal(false)}
      />
      <Spacing height={"116px"} />
      {current_card.is_required_person ? (
        <DoubleBirthDetailCard
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          onStartClick={_handleStartClick}
          onPrevClick={handlePrev}
          title={current_card.title}
          imgUrl={current_card.thumbnail_url}
          description={current_card.description}
          requiredStarCoin={current_card.required_starcoin}
          type={current_card.type}
          onBirthClick={onBirthClick}
          birthType={birthType}
          setBirthType={setBirthType}
          id={id}
          year={year}
          month={month}
          day={day}
          setDay={setDay}
          setMonth={setMonth}
          setYear={setYear}
        />
      ) : (
        <DetailCard
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          onStartClick={_handleStartClick}
          onPrevClick={handlePrev}
          title={current_card.title}
          imgUrl={current_card.thumbnail_url}
          description={current_card.description}
          requiredStarCoin={current_card.required_starcoin}
          onBirthClick={onBirthClick}
          id={id}
          year={year}
          month={month}
          day={day}
          setDay={setDay}
          setMonth={setMonth}
          setYear={setYear}
          type={current_card.type}
        />
      )}
      <Spacing height={"60px"} />
      <CardRow
        centerTitle={true}
        title={t("card_detail.recommended")}
        list={current_popular_cards}
        onCardClick={handleCardClick}
      />
      <Spacing height={"60px"} />
      <Footer />
    </>
  );
};

export default CardDetail;
