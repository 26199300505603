import { BirthModel } from "@api/births.api";
import MoreButton from "@components/atoms/MoreButton";
import BirthFormModal from "@components/molecules/BirthFormModal";
import BirthTableHeader from "@components/molecules/BirthTableHeader";
import BirthTableRow from "@components/molecules/BirthTableRow";
import NewBirthTableRow from "@components/molecules/NewBirthTableRow";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import { useState } from "react";

const Wrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 60px;
  max-width: 1200px;
  min-width: 1200px;
  min-height: calc(100vh - 700px);
  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
`;

interface BirthTableProps {
  list: BirthModel[];
  // totalCount: number;
}

const BirthTable = ({ list }: BirthTableProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <Wrapper>
      <BirthFormModal
        show={showModal}
        onPrevClick={() => setShowModal(false)}
      />
      <BirthTableHeader />
      <NewBirthTableRow onClick={handleOpenModal} />
      {list.map((birth, idx) => (
        <BirthTableRow
          birth={birth}
          key={"birth-" + birth.birth_id}
          idx={idx}
        />
      ))}
      {/* {<MoreButton />} */}
    </Wrapper>
  );
};

export default BirthTable;
