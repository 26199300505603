import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import React from "react";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 20px;
  ${mediaQueries("desktopSmall")} {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  ${mediaQueries("mobileL")} {
    margin-right: 10px;
    margin-bottom: 20px;
  }
`;
const StyledInput = styled.input<Partial<InputProps>>`
  padding: 10px;
  border-radius: 14px;
  outline: none;
  width: 100%;
  height: 44px;

  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #642ad6;
  border: solid 2px #d8d8d8;
  background-color: #ffffff;
  text-align: center;
  &::placeholder {
    color: #d8d8d8;
    font-weight: bold;
  }
`;

const Label = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  margin-left: 8px;
  color: #d8d8d8;
  font-weight: 500;
`;

export interface InputProps {
  style?: React.CSSProperties;
  className?: string;
  id?: string;
  label?: string;
  value: string;
  onValueChange: (v: string) => void;
  placeholder: string;
  fill?: boolean;
  name?: string;
  maxLength?: number;
  type?: string;
}
export default function Input({
  style,
  className,
  id,
  label,
  value,
  onValueChange,
  placeholder,
  fill,
  name,
  maxLength,
  type = "text",
}: InputProps) {
  return (
    <Wrapper style={fill ? { flex: 1 } : {}}>
      <StyledInput
        name={name}
        type={type}
        id={id}
        style={style}
        className={className}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onValueChange(e.target.value)
        }
      />
      {!!label && <Label>{label}</Label>}
    </Wrapper>
  );
}
