import * as React from "react";
import Header from "@components/molecules/Header";
import Footer from "@components/molecules/Footer";
import Spacing from "@components/atoms/Spacing";
import { getProductList } from "@api/products.api";
import { useLocation } from "react-router";

import ProductRow from "@components/organisms/ProductRow";
import { ProductsModel } from "@models/products/Product";
import { useTranslation } from "react-i18next";
import MobilePageHeader from "@components/molecules/MobilePageHeader";
import CheckoutModal from "@components/molecules/CheckoutModal";

interface ILocation {
  cardId?: string;
}

const Shop: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [products, setProducts] = React.useState<ProductsModel[] | undefined>(
    undefined
  );
  const [
    selectedProduct,
    setSelectedProduct,
  ] = React.useState<ProductsModel | null>(null);
  const location = useLocation<ILocation>();

  const { t } = useTranslation();

  React.useEffect(() => {
    const _fetchProductList = async () => {
      try {
        const res = await getProductList({});
        if (!res) return;
        const { products } = res;
        setProducts(products);
      } catch (e) {
        console.log("main list error : ", e);
      } finally {
        setIsLoading(false);
      }
    };

    _fetchProductList();
  }, []);

  const handleProductClick = (curId: string) => {
    if (!products) return;
    const filtered = products.filter((p) => p.id === curId);
    if (filtered.length < 1) return;
    setSelectedProduct(filtered[0]);
  };

  return (
    <>
      <Header />
      <Spacing height={"116px"} />
      <MobilePageHeader title={t("shop.title")} desc={t("shop.desc")} />
      <Spacing height={"10px"} />
      {selectedProduct && (
        <CheckoutModal
          cardId={location?.state?.cardId}
          show={!!selectedProduct}
          onPrevClick={() => setSelectedProduct(null)}
          product={selectedProduct}
        />
      )}
      <ProductRow list={products} onProductClick={handleProductClick} />
      <Footer />
    </>
  );
};

export default Shop;
