import config from "@api/config";
import Geocode from "react-geocode";

Geocode.setApiKey(config.GOOGLE_GEOCODE_KEY);
Geocode.setLanguage("kr");

export const getLatLngFromAddr = async (
  currentAddr: NodeListOf<HTMLElement>
) => {
  // @ts-ignore
  return Geocode.fromAddress(currentAddr)
    .then((response) => {
      const { lat, lng } = response.results[0].geometry.location;
      return { lat, lng };
    })
    .catch((err) => console.log(err));
};
