import Stormy from "@assets/imgs/min_stormy.png";
import Rainy from "@assets/imgs/min_rainy.png";
import Cloudy from "@assets/imgs/min_cloudy.png";
import Bright from "@assets/imgs/min_bright.png";
import VeryBright from "@assets/imgs/min_verybright.png";

const getWeatherIcon = (score: number) => {
  if (score <= 35) {
    return VeryBright;
  }
  if (score > 35 && score <= 60) {
    return Bright;
  }
  if (score > 60 && score <= 80) {
    return Cloudy;
  }
  if (score > 80 && score <= 95) {
    return Rainy;
  }
  return Stormy;
};

export default getWeatherIcon;
