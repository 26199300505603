import styled from '@emotion/styled';
import { mediaQueries } from '@styles/common';
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  margin-bottom: 0px;
  padding: 25px 35px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: #2d2d2d;
  font-size: 16px;
  font-weight: bold;
  ${mediaQueries('desktop')} {
    display: none;
  }
`;

const NickName = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  text-align: left;
  flex: 3;
`;
const Gender = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  flex: 1;
`;
const Age = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  flex: 1;
`;
const BirthDate = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  flex: 2;
`;
const ModifiedAt = styled.div`
  flex: 2;
`;
const BtnWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IcTrashcan = styled.img`
  width: 60px;
  height: 60px;
`;

interface BirthTableHeaderProps {
}

const BirthTableHeader = ({ }: BirthTableHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <NickName>{t('births.table_nickname')}</NickName>
      <Gender>{t('births.table_gender')}</Gender>
      <Age>{t('births.table_age')}</Age>
      <BirthDate>{t('births.table_birth')}</BirthDate>
      {/*<ModifiedAt>{t('births.table_datetime')}</ModifiedAt>*/}
      <BtnWrapper />
    </Wrapper>
  );
};

export default BirthTableHeader;
