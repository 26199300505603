import icPageRight from "@assets/imgs/arrow_right.png";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${mediaQueries("mobileL")} {
    flex: 1;
    flex-direction: column;
  }
`;

const ImgWrapper = styled.div`
  width: 378px;
  text-align: center;
  padding-left: 56px;
  ${mediaQueries("tablet")} {
    width: 100%;
    flex-direction: row;
    margin-top: 10px;
    z-index: -1;
  }
  ${mediaQueries("mobileL")} {
    width: 339px;
    /* position: absolute; */
    /* margin-bottom: 400px; */
    position: relative;
    padding-left: 0px;
  }
`;

const StyledImg = styled.img`
  width: 300px;
  //height: 550px;
  margin-right: 30px;
  margin-left: 36px;
  /* max-width: 240px; */
  object-fit: contain;
  ${mediaQueries("mobileSE")} {
    width: 300px;
    height: 400px;
    left: 8px;
    margin-bottom: 0px;
    //max-height: 300px;
  }
  ${mediaQueries("mobileL")} {
    height: 400px;
    margin: 0px 0px 58px;
    //max-height: 300px;
    margin-bottom: 0px;
  }
  ${mediaQueries("tablet")} {
    width: 290px;
    margin-left: 0px;
    margin-right: 10px;
    /* margin-bottom: 30px; */
    position: relative;
    left: 11px;
    //max-height: 300px;
  }
`;

const ContentWrapper = styled.div`
  width: 600px;
  padding: 50px 50px 50px 118px;
  ${mediaQueries("tablet")} {
    width: 100%;
    padding: 50px 100px 50px 40px;
  }
  ${mediaQueries("mobileL")} {
    padding: 20px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ContentTitle = styled.div`
  font-size: 50px;
  font-weight: 900;
  line-height: 1.29;
  color: #2d2d2d;
  margin-bottom: 18px;
  text-align: left;
  ${mediaQueries("mobileL")} {
    display: none;
  }
`;

const ContentDesc = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 2;
  text-align: left;
  color: #2d2d2d;
  white-space: pre-wrap;
  ${mediaQueries("mobileL")} {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 43px;
  text-align: left;
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  border-radius: 16px;
  padding: 10px 18px;
  cursor: pointer;
  width: 300px;
  height: 50px;
  ${mediaQueries("tablet")} {
    margin-top: 0px;
  }
`;

const InsideButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonText = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: white;
  margin-bottom: 6px;
`;

const IconRight = styled.img`
  width: 36px;
  height: 36px;
  padding-bottom: 6px;
`;

const LeftBlank = styled.div`
  width: 36px;
  height: 36px;
`;

export interface MainCardProps {
  style?: React.CSSProperties;
  imgUrl?: string;
  className?: string;
  onStartClick?: () => void;
  id?: string;
  title?: string;
  subtitle?: string;
}

export default function MainCard({
  onStartClick,
  style,
  imgUrl,
  title,
  subtitle,
  className,
  id,
}: MainCardProps) {
  const { t } = useTranslation();

  return (
    <Wrapper id={id} style={style} className={className}>
      <ImgWrapper>
        <StyledImg key={imgUrl} src={imgUrl} />
      </ImgWrapper>
      <ContentWrapper>
        <ContentTitle>
          <pre style={{ marginBottom: "20px" }}>{title}</pre>
        </ContentTitle>
        <ContentDesc>
          <pre style={{ fontFamily: "Noto Sans KR" }}>{subtitle}</pre>
          {/*Knowing yourself, others and the future<br/>*/}
          {/*is the best life strategy.<br/>*/}
          {/*What is your personality flavor?*/}
        </ContentDesc>
        <ButtonWrapper onClick={onStartClick}>
          <InsideButtonWrapper>
            <LeftBlank />
            <ButtonText>{t("home.scoop_button")}</ButtonText>
            <IconRight src={icPageRight} />
          </InsideButtonWrapper>
        </ButtonWrapper>
      </ContentWrapper>
    </Wrapper>
  );
}
