import "./App.css";
import Root from "./routes";
import { AppProvider, combinedContexts } from "./contexts";
import i18next from "i18next";
import ReactGA from "react-ga";
import { ComponentType, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import { ErrorBoundary } from "react-error-boundary";
import Exception from "@containers/Exception";

function App() {
  useEffect(() => {
    getGA();
  }, []);

  const getGA = () => {
    const pathName = window.location.pathname;
    ReactGA.initialize("UA-207435473-1");
    ReactGA.set({ page: pathName });
    ReactGA.pageview(pathName);
  };
  const queryClient = new QueryClient();

  return (
    <div className="App" lang={i18next.language.slice(0, 2)}>
      <ErrorBoundary FallbackComponent={() => <Exception />}>
        <QueryClientProvider client={queryClient}>
          <RecoilRoot>
            <AppProvider contexts={combinedContexts}>
              <Root />
            </AppProvider>
          </RecoilRoot>
        </QueryClientProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
