import * as React from "react";
import { useEffect, useRef } from "react";
import Header from "@components/molecules/Header";
import Footer from "@components/molecules/Footer";
import Spacing from "@components/atoms/Spacing";
import { postPaypalCapture, postPaypalOrder } from "@api/paypal.api";
import { useHistory } from "react-router";
import config from "@api/config";
import { useScript } from "@hooks/useScript";
import { useUsersDispatch, useUsersState } from "@contexts/users.context";

declare global {
  interface Window {
    paypal: any;
  }
}

const Checkout: React.FC = () => {
  const [paypalLoaded, paypalError] = useScript(
    `https://www.paypal.com/sdk/js?client-id=${config.PAYPAL_CLIENT_ID}&currency=USD`
  );
  const history = useHistory();
  const paypalRef = useRef(null);
  const { userInfo } = useUsersState();
  const usersDispatch = useUsersDispatch();

  const { location } = history;
  const { product }: any = location.state;

  useEffect(() => {
    if (paypalLoaded) _initPaypal(window.paypal);
  }, [paypalLoaded]);

  const _initPaypal = (windowPaypal: any) => {
    if (!windowPaypal) return;

    windowPaypal
      .Buttons({
        createOrder: async () => {
          const orderReq = { id: product.id };
          const resp = await postPaypalOrder(orderReq);
          if (!resp) {
            alert("paypal에 문제가 생겼습니다.");
            return;
          }
          return resp.order_id;
        },
        onApprove: async (data: any) => {
          const captureReq = { id: product.id, order_id: data.orderID };

          const resp = await postPaypalCapture(captureReq);

          if (!userInfo) return;
          const user_normal_starcoin_balance =
            userInfo.normal_token_balance || 0;
          userInfo.normal_token_balance =
            user_normal_starcoin_balance +
            (product.normal_starcoin_increment || 0);
          usersDispatch({ type: "SET_USER_INFO", userInfo: userInfo });

          history.push("/home");
        },
        onErr: (err: any) => {
          alert("paypal에 문제가 생겼습니다.");
          console.log(err);
        },
      })
      .render(paypalRef.current);
  };

  return (
    <>
      <Header />
      <Spacing height={"116px"} />
      {paypalLoaded && !paypalError && (
        <div style={{ textAlign: "center" }} ref={paypalRef} />
      )}
      <Spacing height={"10px"} />
      <Footer />
    </>
  );
};

export default Checkout;
