import apiAgent from "@api/api.agent";
import config from "@api/config";
import { getUserInfo, login, signup } from "@api/user.api";
import { useCardIdDispatch, useCardIdState } from "@contexts/cardId.context";
import { useCommonDispatch } from "@contexts/common.context";
import { useUsersDispatch } from "@contexts/users.context";
import styled from "@emotion/styled";
import useRedirectCardDetail from "@hooks/useRedirectCardDetail";
import { mediaQueries } from "@styles/common";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";

declare global {
  interface Window {
    naver: any;
  }
}

const { naver } = window;

const StyledButton = styled.button`
  width: 320px;
  padding: 0px;
  ${mediaQueries("desktop")} {
    max-width: 100%;
    //min-width: 100%;
  }
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(278deg, #00bf18 100%, #00dd1c 0%);
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  line-height: 0.88;
  letter-spacing: -0.32px;
  color: #ffffff;
  cursor: pointer;
`;

export interface NaverButtonProps {
  style?: React.CSSProperties;
}

export default function NaverLoginButton({ style }: NaverButtonProps) {
  const location = useLocation();
  const commonDispatch = useCommonDispatch();
  const usersDispatch = useUsersDispatch();
  const { redirectCardDetail } = useRedirectCardDetail();

  const history = useHistory();

  useEffect(() => {
    initNaverLogin();
    getNaverToken();
  }, []);

  const initNaverLogin = () => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: config.NAVER_LOGIN.CLIENT_ID,
      callbackUrl: config.NAVER_LOGIN.CALLBACK_URL,
      isPopup: false,
      loginButton: { color: "green", type: 3, height: 56, width: 360 },
      callbackHandle: true,
    });
    naverLogin.init();
  };

  const getNaverToken = async () => {
    if (!location.hash) return;
    commonDispatch({ type: "SET_IS_LOADING", isLoading: true });
    const token = location.hash.split("=")[1].split("&")[0];
    const res = await login({ sns_token: token, sns_type: "naver" });

    if (!res) {
      commonDispatch({ type: "SET_IS_LOADING", isLoading: false });
      return alert("로그인에 실패하였습니다.");
    }

    if (res === "NOT_REGISTERED") {
      const signupRes = await signup({ sns_token: token, sns_type: "naver" });
      if (!signupRes) return alert("회원가입에 실패하였습니다.");

      const { access_token } = signupRes;
      await handleTokenUpdated(access_token);
      await getUserAndUpdate();

      return history.push("/extraInfo", { agreement_displayed: true });
    }

    const { access_token, is_once_modified } = res;

    await handleTokenUpdated(access_token);

    if (!is_once_modified) {
      await handleNotModified();
    } else {
      await handleModified();
    }
  };

  const handleTokenUpdated = async (access_token: string) => {
    commonDispatch({ type: "SET_IS_LOADING", isLoading: false });
    await apiAgent.updateToken(access_token);
    localStorage.setItem("token", access_token);
  };

  const getUserAndUpdate = async () => {
    const user = await getUserInfo({});
    if (user) usersDispatch({ type: "SET_USER_INFO", userInfo: user });
  };

  const handleNotModified = async () => {
    usersDispatch({ type: "SET_ADDITINAL_INFO_NEEDED", needed: true });
    history.push("/extraInfo", { agreement_displayed: true });
  };

  const handleModified = async () => {
    await getUserAndUpdate();
    redirectCardDetail();
  };

  const _handleNaverLogin = () => {
    if (
      document &&
      document?.querySelector("#naverIdLogin")?.firstChild &&
      window !== undefined
    ) {
      const loginBtn: any = document.getElementById("naverIdLogin")?.firstChild;
      loginBtn?.click();
    }
  };

  return (
    <>
      <div
        id={"naverIdLogin"}
        style={{ position: "absolute", top: "-10000px" }}
      />
      <StyledButton onClick={_handleNaverLogin} style={style}>
        네이버 아이디로 로그인
      </StyledButton>
    </>
  );
}
