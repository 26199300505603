import { useTranslation } from "react-i18next";

const useCopyClipBoard = () => {
  const { t } = useTranslation();
  const url = window.location.href;
  const onClickCopyClipBoard = async () => {
    await navigator.clipboard.writeText(url);
    alert(t("share.temp"));
  };
  return { onClickCopyClipBoard };
};

export default useCopyClipBoard;
