import { GetCardResultResModel } from "@api/card.result.api";
import imgArrowLeft from "@assets/imgs/chevron-down-solid-3@2x.png";
import icRevision from "@assets/imgs/revision-regular@2x.png";
import ResultShare from "@components/molecules/ResultShare";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

interface ButtonProps {
  displayed?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-width: 768px;
  margin: 0 auto;
  padding: 50px 100px;
  border-radius: 36px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;

  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("tablet")} {
    padding: 15px;
    max-width: 100%;
    min-width: 100%;
  }
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    margin: 0;
    padding-top: 0;
    padding-bottom: 40px;
  }
`;
const Title = styled.div`
  font-size: 36px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: -0.4px;
  margin: 30px 10px 60px;
  text-align: center;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    margin: 50px 10px 40px 10px;
  }
`;
const SubFlavor = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 0 100px 0;
  ${mediaQueries("mobileL")} {
    flex-direction: column;
    margin: 0 0 75px 0;
  }
`;
const Button = styled.div<ButtonProps>`
  margin: 35px 135px 20px;
  height: 50px;
  padding: 25px 35px;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  ${mediaQueries("mobileL")} {
    margin: 35px 22px 20px;
  }
`;
const IcRevision = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 14px;
  //margin-top: 14px;
`;
const Text = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  margin: 3px 0 1px 14px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: -0.36px;
  text-align: center;
  color: #ffffff;
`;
const BackIcon = styled.img`
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  left: -60px;
  cursor: pointer;
`;
const CommentAgain = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  text-align: center;
  margin-top: 10px;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    margin-top: 40px;
  }
`;

interface StockerProps {
  result: GetCardResultResModel;
  result_id: string;
  thumbnail_url: string;
}

const Stocker = ({ result, result_id, thumbnail_url }: StockerProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <Wrapper>
        <BackIcon src={imgArrowLeft} onClick={() => history.goBack()} />
        <Title>
          <pre>{result.title && result.title[0]}</pre>
        </Title>
        <div>
          {result.stocker.map((item) => (
            <p>{item}</p>
          ))}
        </div>
        <ResultShare
          link={result.link}
          thumbnail_url={thumbnail_url}
          description={result.description}
        />
      </Wrapper>
      <Wrapper style={{ marginTop: "30px" }}>
        <CommentAgain>{result.comment[4]}</CommentAgain>
        <Button onClick={() => history.push(`/cards/${result_id}`)}>
          <IcRevision src={icRevision} />
          <pre>
            <Text>{t("card_result.button")}</Text>
          </pre>
        </Button>
      </Wrapper>
    </>
  );
};

export default Stocker;
