import styled from "@emotion/styled";
import moment from "moment";
// import icTrashCanLine from '@assets/imgs/ic_trashcan_line.png';
import icTrashCan from "@assets/imgs/ic_trashcan.png";
// import { Event } from '@storybook/api/dist/ts3.9/modules/shortcuts';
import React from "react";
import { genderTranslation } from "@utils/gender.util";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  border-radius: 24px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 30px;
  position: relative;
`;

const StyledImg = styled.img`
  flex: 1;
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  flex: 1;
  padding: 25px;
  position: relative;
  text-align: left;
  min-height: 134px;
`;

const Name = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  color: #2d2d2d;
`;

const InfoWrapper = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #2c2738;
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  align-items: center;
`;

const SubInfoWrapper = styled.div``;
const Gender = styled.span`
  font-family: "Noto Sans KR", sans-serif;
  margin-right: 30px;
  font-weight: 700;
`;

const Age = styled.span`
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 700;
`;

const Description = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #2c2738;
  margin-bottom: 35px;
`;

const CreatedMyBirth = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #b6b6b6;
  position: absolute;
  bottom: 12px;
`;

const CreatedOtherBirth = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  color: #b6b6b6;
  position: absolute;
  bottom: 12px;
  right: 25px;
`;
// const DeleteWrapper = styled.div`
//   position: absolute;
//   top: 20px;
//   right: 20px;
//   padding: 7px;
//   background-color: #ffffff;
// `;
//
// const IcTrashCan = styled.img`
//   width: 26px;
//   height: 26px;
// `;

const TrashCanButton = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 65px;
`;

export interface ResultCardProps {
  style?: React.CSSProperties;
  imgUrl?: string;
  name?: string;
  card_description?: string;
  gender: string;
  birthdate: string;
  name2?: string;
  gender2?: string;
  birthdate2?: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onDelete: (resultId: string) => Promise<void>;
  id: string;
}

export default function ResultCard2({
  onClick,
  onDelete,
  style,
  imgUrl,
  name,
  card_description,
  gender,
  birthdate,
  name2,
  gender2,
  birthdate2,
  className,
  id,
}: ResultCardProps) {
  const handleDelete = async (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // TODO: update
    onDelete && onDelete(id);
  };
  return (
    <Wrapper id={id} style={style} className={className} onClick={onClick}>
      <TrashCanButton src={icTrashCan} onClick={handleDelete} />
      {/* <DeleteWrapper>
        <IcTrashCan src={icTrashCanLine} />
      </DeleteWrapper> */}
      <StyledImg src={imgUrl} />
      <ContentWrapper>
        <InfoWrapper>
          <Name>{name}</Name>
          <SubInfoWrapper>
            <Gender>{genderTranslation(gender)}</Gender>
            <Age>{moment().get("year") - moment(birthdate).get("year")}</Age>
          </SubInfoWrapper>
        </InfoWrapper>
        {gender2 && name2 && birthdate2 && (
          <InfoWrapper>
            <Name>{name2}</Name>
            <SubInfoWrapper>
              <Gender>{genderTranslation(gender2)}</Gender>
              <Age>{moment().get("year") - moment(birthdate2).get("year")}</Age>
            </SubInfoWrapper>
          </InfoWrapper>
        )}{" "}
        {/*<Description>*/}
        {/*  {card_description || 'No description'}*/}
        {/*</Description>*/}
        <CreatedMyBirth>
          {birthdate.toString().slice(0, 10)}{" "}
          {birthdate.toString().slice(11, 16)}
        </CreatedMyBirth>
        {birthdate2 && (
          <CreatedOtherBirth>
            {birthdate2.toString().slice(0, 10)}{" "}
            {birthdate2.toString().slice(11, 16)}
          </CreatedOtherBirth>
        )}
      </ContentWrapper>
    </Wrapper>
  );
}
