import axios from "axios";
import config from "@api/config";
import apiAgent from "./api.agent";

interface GetCurrentPaypalOrderReqModel {
  id: string;
}

interface GetCurrentPaypalOrderResModel {
  order_id: string;
}

export const postPaypalOrder = async (req: GetCurrentPaypalOrderReqModel) => {
  try {
    const { data } = await apiAgent.axios.post<GetCurrentPaypalOrderResModel>(
      config.API_URL + "/v1/paypal/products/" + req.id + "/order",
      {}
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};

interface GetCurrentPaypalCaptureReqModel {
  id: string;
  order_id: string;
}

interface GetCurrentPaypalCaptureResModel {
  is_completed: boolean;
}

export const postPaypalCapture = async (
  req: GetCurrentPaypalCaptureReqModel
) => {
  try {
    const { data } = await apiAgent.axios.post<GetCurrentPaypalCaptureResModel>(
      config.API_URL +
        "/v1/paypal/products/" +
        req.id +
        "/orders/" +
        req.order_id +
        "/capture",
      {}
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};

interface GetCurrentPaypalRefundReqModel {
  capture_id: string;
}

interface GetCurrentPaypalRefundResModel {}

export const postPaypalRefund = async (req: GetCurrentPaypalRefundReqModel) => {
  try {
    const { data } = await apiAgent.axios.post<GetCurrentPaypalRefundResModel>(
      config.API_URL + "/v1/paypal/" + req.capture_id + "/refund",
      {}
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};
