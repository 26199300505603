import Input from "@components/atoms/Input";

import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import moment from "moment";

import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface FormProps {
  year: string;
  month: string;
  day: string;
  setYear: React.Dispatch<React.SetStateAction<string>>;
  setMonth: React.Dispatch<React.SetStateAction<string>>;
  setDay: React.Dispatch<React.SetStateAction<string>>;
  type?: string;
}

const SomedayForm: FC<FormProps> = ({
  year,
  month,
  day,
  setYear,
  setMonth,
  setDay,
  type,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>{t("card_detail.datetime")}</Title>
      <InputWrapper>
        <Input
          style={{ width: "80px" }}
          name="year"
          value={year}
          onValueChange={setYear}
          label={t("birth_modal.year")}
          placeholder={moment().format("YYYY")}
          maxLength={4}
        />
        <Input
          name="month"
          style={{ width: "50px" }}
          value={month}
          onValueChange={setMonth}
          label={t("birth_modal.month")}
          placeholder={moment().format("MM")}
          maxLength={2}
        />
        <Input
          name={day}
          style={{ width: "50px" }}
          value={day}
          onValueChange={setDay}
          label={t("birth_modal.day")}
          placeholder={moment().format("DD")}
          maxLength={2}
        />
        {(type === "specific_love_trouble_lovers_month" ||
          type === "specific_love_trouble_lovers_month") && (
          <Description>부터 30일</Description>
        )}
      </InputWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: left;
  margin-top: 20px;
`;

const Title = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 0.7;
  letter-spacing: -0.4px;
  color: #2d2d2d;
  margin-bottom: 14px;
  text-align: left;
  ${mediaQueries("mobileL")} {
    margin-bottom: 20px;
  }
`;
const Description = styled.span`
  margin-top: 11px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  color: #b8b8b8;
`;

const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;

  ${mediaQueries("mobileL")} {
  }
`;

export default SomedayForm;
