import { CardModel } from "@models/users/Card";
import axios from "axios";
import config from "./config";
import i18next from "i18next";
import apiAgent from "./api.agent";
import { UseInfiniteQueryResult } from "react-query";

interface GetMainCardsReqModel {
  page: number;
  results_per_page: number;
}

export interface GetMainCardsResModel {
  popular_cards: CardModel[];
  recent_cards: CardModel[];
  total_cards: CardModel[];
  page: number;
  total_count_cards: number;
}

interface IGetMainCardsParams {
  pageSize: number;
  pageParam: number;
}
export const getMainCards = async ({
  pageParam,
  pageSize,
}: IGetMainCardsParams) => {
  const lang = i18next.language.slice(0, 2);
  try {
    const { data } = await apiAgent.axios.get<any>(
      config.API_URL +
        `/v1/cards?page=${pageParam}&results_per_page=${pageSize}&lang=${lang}`
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};

export const getLatestCards = async () => {
  const lang = i18next.language.slice(0, 2);
  try {
    const { data } = await apiAgent.axios.get<GetMainCardsResModel>(
      config.API_URL + `/v1/cards/latest?lang=${lang}`
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};

interface GetCurrentCardReqModel {
  id: string;
}

interface GetCurrentCardResModel {
  card: CardModel;
  popular_cards: CardModel[];
}

export const getCardDetail = async (req: GetCurrentCardReqModel) => {
  const lang = i18next.language.slice(0, 2);
  try {
    const { data } = await apiAgent.axios.get<GetCurrentCardResModel>(
      config.API_URL + "/v1/cards/" + req.id + "?lang=" + lang
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return e.response;
  }
};

interface SearchCardsReqModel {
  hashtag: string;
}
export interface SearchCardsResModel {
  cards: CardModel[];
}

export const searchCards = async (req: SearchCardsReqModel) => {
  try {
    const lang = i18next.language.slice(0, 2);
    const { data } = await apiAgent.axios.get(
      config.API_URL + `/v1/cards/hashtag?lang=${lang}&hashtag=${req.hashtag}`
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};

interface GetRecommendCardsReqModel {}

export interface GetRecommendCardsResModel {
  cards: CardModel[];
}

export const getRecommendCards = async () => {
  const lang = i18next.language.slice(0, 2);
  try {
    const { data } = await apiAgent.axios.get(
      config.API_URL + "/v1/cards/recommendation?lang=" + lang
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};

interface GetCardsByCategoryReqModel {
  category: string;
}

export interface GetCardsByCategoryResModel {
  cards: CardModel[];
}

export const getCardsByCategory = async ({
  category,
}: GetCardsByCategoryReqModel) => {
  const lang = i18next.language.slice(0, 2);
  try {
    const { data } = await apiAgent.axios.get(
      config.API_URL + `/v1/cards/category?lang=${lang}&category=${category}`
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};

interface DeleteCardReqModel {
  result_id: string;
}

interface DeleteCardResModel {}

export const deleteCard = async (req: DeleteCardReqModel) => {
  try {
    const { data } = await apiAgent.axios.delete<DeleteCardResModel>(
      config.API_URL + "/v1/cards/result/" + req.result_id
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};
