import imgRevision from "@assets/imgs/ic_revision.png";
import imgArrowLeft from "@assets/imgs/ic_arrow_left.png";
import imgFacebookShare from "@assets/imgs/ic_facebook_share.png";
import imgKakaoShare from "@assets/imgs/ic_kakao_share.png";
import imgShare from "@assets/imgs/ic_share.png";
import imgStarCoin from "@assets/imgs/ic_starcoin.png";
import { useBirthsDispatch, useBirthsState } from "@contexts/births.context";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import imgPocket from "@assets/imgs/pocket.png";

import config from "@api/config";
import DateTab from "@components/molecules/DateTab";
import SomedayForm from "@components/molecules/SomedayForm";
import {
  BirthType,
  HAS_SPECIFIC_DAY,
  HAS_TABS,
  LOGIN_FREE,
  LUCKS_TABS,
} from "@containers/CardDetail";
import useIsLoggedIn from "@hooks/useIsLoggedIn";
import useCopyClipBoard from "@utils/clipboard.util";

export interface DoubleBirthDetailCardProps {
  style?: React.CSSProperties;
  imgUrl?: string;
  title?: string;
  description?: string;
  requiredStarCoin?: number;
  className?: string;
  onStartClick?: () => void;
  onPrevClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
  onBirthClick?: (birthType: BirthType) => void;
  id?: string;
  type?: string;
  year: string;
  month: string;
  day: string;
  setYear: React.Dispatch<React.SetStateAction<string>>;
  setMonth: React.Dispatch<React.SetStateAction<string>>;
  setDay: React.Dispatch<React.SetStateAction<string>>;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
  selectedTab: string;
  birthType: BirthType;
  setBirthType: React.Dispatch<React.SetStateAction<BirthType>>;
}

export default function DoubleBirthDetailCard({
  onStartClick,
  onPrevClick,
  onBirthClick,
  style,
  imgUrl,
  title,
  description,
  requiredStarCoin = 0,
  className,
  id,
  type,
  year,
  month,
  day,
  setYear,
  setMonth,
  setDay,
  setSelectedTab,
  selectedTab,
  setBirthType,
  birthType,
}: DoubleBirthDetailCardProps) {
  const { otherBirth, secondOtherBirth } = useBirthsState();

  const birthsDispatch = useBirthsDispatch();
  const isLoggedIn = useIsLoggedIn();
  const { t } = useTranslation();
  const { onClickCopyClipBoard } = useCopyClipBoard();
  const createKakaoButton = useCallback(() => {
    if (window.Kakao) {
      const kakao = window.Kakao;
      if (!kakao.isInitialized()) {
        kakao.init(config.KAKAO_KEY);
      }
      kakao.Link.createDefaultButton({
        container: "#kakao-link-btn",
        objectType: "feed",
        content: {
          title: "Spoon5",
          description: description,
          imageUrl: imgUrl, // i.e. process.env.FETCH_URL + '/logo.png'
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          },
        },
        // social: {
        //   likeCount: 77,
        //   commentCount: 55,
        //   sharedCount: 333,
        // },
        buttons: [
          {
            title: "웹으로 보기",
            link: {
              mobileWebUrl: window.location.href,
              webUrl: window.location.href,
            },
          },
          {
            title: "앱으로 보기",
            link: {
              mobileWebUrl: window.location.href,
              webUrl: window.location.href,
            },
          },
        ],
      });
    }
  }, [description, imgUrl]);

  React.useEffect(() => {
    createKakaoButton();
    // birthsDispatch({ type: "SET_OTHER_BIRTH", otherBirth: null });
    // birthsDispatch({ type: "SET_SECOND_OTHER_BIRTH", secondOtherBirth: null });
  }, [createKakaoButton]);

  const onClickFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`
    );
  };

  const isSomeday =
    (HAS_SPECIFIC_DAY.includes(type ?? "") && selectedTab === LUCKS_TABS[1]) ||
    type === "specific_love_trouble_lovers_month" ||
    type === "specific_love_trouble_couples_month";

  const buttonDisabled = () => {
    if (isSomeday) {
      return !!(year && month && day);
    }
    return true;
  };

  const getContent = () => {
    if (
      type &&
      LOGIN_FREE.includes(type) &&
      !isLoggedIn &&
      selectedTab === LUCKS_TABS[1]
    ) {
      return <>{t("card_detail.need_login")}</>;
    }
    return (
      <>
        {" "}
        {otherBirth ? (
          <OtherNameWrapper>
            <OtherNameText>{otherBirth.name}</OtherNameText>
            <Button
              onClick={() =>
                birthsDispatch({ type: "SET_OTHER_BIRTH", otherBirth: null })
              }
              style={{ width: "56px", height: "56px", padding: 0 }}
            >
              <img src={imgRevision} style={{ width: 40, height: 40 }} />
            </Button>
          </OtherNameWrapper>
        ) : (
          <ButtonWrapper>
            <Button
              onClick={() => onBirthClick?.(BirthType.OTHER_BIRTH)}
              style={{ width: "100%" }}
            >
              {t("card_detail.input_birthday_button")}
            </Button>
          </ButtonWrapper>
        )}
        {secondOtherBirth ? (
          <OtherNameWrapper>
            <OtherNameText>{secondOtherBirth.name}</OtherNameText>
            <Button
              onClick={() =>
                birthsDispatch({
                  type: "SET_SECOND_OTHER_BIRTH",
                  secondOtherBirth: null,
                })
              }
              style={{ width: "56px", height: "56px", padding: 0 }}
            >
              <img src={imgRevision} style={{ width: 40, height: 40 }} />
            </Button>
          </OtherNameWrapper>
        ) : (
          <ButtonWrapper>
            <Button
              onClick={() => onBirthClick?.(BirthType.SECOND_OTHER_BIRTH)}
              style={{ width: "100%" }}
            >
              {t("card_detail.input_partner's_birthday_button")}
            </Button>
          </ButtonWrapper>
        )}
        {isSomeday && (
          <SomeDayWrapper>
            <SomedayForm
              type={type}
              year={year}
              month={month}
              day={day}
              setYear={setYear}
              setMonth={setMonth}
              setDay={setDay}
            />
          </SomeDayWrapper>
        )}
        <ButtonWrapper>
          <Button
            onClick={onStartClick}
            disabled={!!!otherBirth || !!!secondOtherBirth || !buttonDisabled()}
            style={
              otherBirth &&
              secondOtherBirth &&
              requiredStarCoin > 0 &&
              buttonDisabled()
                ? { width: "100%", color: "#ffe328" }
                : { width: "100%" }
            }
          >
            {t("card_detail.start_button")}
            {requiredStarCoin > 0 && (
              <RequiredStarCoinWrapper>
                <IcStarCoin src={imgStarCoin} />
                <TextStarCoin>{requiredStarCoin}</TextStarCoin>
              </RequiredStarCoinWrapper>
            )}
          </Button>
        </ButtonWrapper>
      </>
    );
  };
  return (
    <Wrapper id={id} style={style} className={className}>
      {onPrevClick && <BackIcon src={imgArrowLeft} onClick={onPrevClick} />}

      <StyledImg src={imgUrl} />

      <ContentWrapper>
        {HAS_TABS.includes(type ?? "") && (
          <TabWrapper>
            <DateTab
              tabs={LUCKS_TABS}
              selectedTab={selectedTab}
              onTabClick={(selectedTab: string) => setSelectedTab(selectedTab)}
            />
          </TabWrapper>
        )}
        <ContentTitle>{title || "No title"}</ContentTitle>
        <ContentDescription>
          {description || "No description"}
        </ContentDescription>
        {getContent()}
        <HelpText>{t("card_detail.private_info")}</HelpText>
      </ContentWrapper>
      <ShareWrapper>
        {/*<img src={imgKakaoShare} alt="kakao-share-icon" />*/}
        <ShareButtonWrapper id="kakao-link-btn">
          <ShareImg src={imgKakaoShare} />
          {/* <ShareText>{t("card_detail.kakao_sharing")}</ShareText> */}
        </ShareButtonWrapper>
        <Splitter />
        <ShareButtonWrapper onClick={onClickFacebook}>
          <ShareImg src={imgFacebookShare} />
          {/* <ShareText>{t("card_detail.facebook_sharing")}</ShareText> */}
        </ShareButtonWrapper>
        <Splitter />
        <ShareButtonOthersWrapper onClick={onClickCopyClipBoard}>
          <ShareImg src={imgShare} />
        </ShareButtonOthersWrapper>
      </ShareWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-width: 768px;
  margin: 0 auto;

  border-radius: 36px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;

  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("mobileL")} {
    margin: 0px;
    min-width: 100%;
  }
`;

const StyledImg = styled.img`
  border-top-right-radius: 36px;
  border-top-left-radius: 36px;
  border-bottom-right-radius: 36px;
  width: 100%;
  height: 100%;
  ${mediaQueries("mobileL")} {
    border-bottom-right-radius: 0px;
  }
`;

const BackIcon = styled.img`
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  left: -60px;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  flex: 1;
  padding: 60px 100px;
  text-align: center;
  ${mediaQueries("mobileL")} {
    padding: 40px 15px 0px;
  }
`;

const DayTitle = styled.p`
  font-size: 34px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #713bdb;
  margin-bottom: 0px;
  ${mediaQueries("mobileL")} {
    font-size: 32px;
    padding: 0px 10px 0px;
  }
`;

const ContentTitle = styled.p`
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #2c2738;
  margin-top: 0px;
  margin-bottom: 20px;
  ${mediaQueries("mobileL")} {
    padding: 0px 10px 0px;
    font-size: 28px;
    margin-bottom: 30px;
  }
`;

const ContentDescription = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  line-height: 2;
  color: #2d2d2d;
  margin-bottom: 60px;
  ${mediaQueries("mobileL")} {
    padding: 0px 10px;
    font-size: 15px;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto 15px;
`;

const OtherNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const SomeDayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const OtherNameText = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #eaeaea;
  //padding: 12px 73px;
  margin-right: 12px;
  font-size: 18px;
  font-weight: 900;
  color: #2d2d2d;
  height: 50px;
  width: 234px;
  padding-top: 11px;
`;

const HelpText = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  color: #8d8d8d;
  margin-top: 60px;
  ${mediaQueries("mobileL")} {
    margin-bottom: 40px;
    margin-top: 40px;
  }
`;

const ShareWrapper = styled.div`
  cursor: pointer;
  border-top: solid 1.5px #eaeaea;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  // ${mediaQueries("desktopSmall")} {
  //   display: none;
  // }
`;

const ShareImg = styled.img`
  width: 40px;
  height: 40px;
  ${mediaQueries("mobileL")} {
    width: 35px;
    height: 35px;
  }
`;
const ShareText = styled.div`
  ${mediaQueries("mobileL")} {
    display: none;
  }
`;

const ShareButtonWrapper = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  padding: 40px;
  font-size: 15px;
  font-weight: 700;
  color: #713bdb;
  display: flex;
  align-items: center;
  ${mediaQueries("tablet")} {
    padding: 0 35px;
  }
  ${mediaQueries("mobileL")} {
    padding: 0 30px;
  }
`;
const ShareButtonOthersWrapper = styled.div`
  padding: 0 40px;
  font-size: 16px;
  font-weight: bold;
  color: #713bdb;
  display: flex;
  align-items: center;
  ${mediaQueries("mobileL")} {
    padding: 0 30px;
  }
  //${mediaQueries("tablet")} {
  //   display: none;
  // }
`;

const Splitter = styled.div`
  width: 1px;
  height: 48px;
  background-color: #eaeaea;
`;

const RequiredStarCoinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 30px;
`;
const IcStarCoin = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;
const TextStarCoin = styled.div``;

const TabWrapper = styled.div`
  margin-bottom: 50px;
`;
const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const PocketImg = styled.img`
  width: 100%;
  left: 0px;
  right: 0px;
  position: absolute;
  bottom: 0px;
`;
