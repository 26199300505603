import React, { Dispatch, SetStateAction } from "react";
import icArrowLeft from "@assets/imgs/chevron-down-solid-3@2x.png";
import styled from "@emotion/styled";

import { User } from "@models/users/User";
import Balance from "@components/atoms/Balance";
import { useHistory } from "react-router-dom";
import { mediaQueries } from "@styles/common";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useUsersDispatch } from "@contexts/users.context";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Box } from "@mui/material";

const MobileMoreWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: calc(100vw * 0.8);
  padding: 15px 20px;
  z-index: 8001;
  background-color: white;
`;

const IcArrowLeftWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
`;
const IcArrowLeft = styled.img`
  width: 42px;
  height: 42px;
`;

const ProfileWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const ProfileImg = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 30px;
  margin-right: 15px;
`;

const ProfileInfo = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  ${mediaQueries("mobileL")} {
    margin-left: 20px;
  }
  ${mediaQueries("mobileSE")} {
    margin-left: 10px;
  }
`;

const ProfileName = styled.div`
  font-size: 24px;
  font-weight: 900;
  text-align: left;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    font-size: 22px;
    font-weight: 900;
  }
`;

const NavWrapper = styled.nav`
  text-align: left;
`;

const TextLink = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 900;
  text-align: left;
  color: #2d2d2d;
  margin-bottom: 30px;
  ${mediaQueries("mobileL")} {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  ${mediaQueries("mobileSE")} {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 18px;
  }
`;

const TermsLinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TermsLink = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  font-weight: 900;
  text-align: left;
  color: #2d2d2d;
  opacity: 0.5;

  ${mediaQueries("mobileL")} {
    font-size: 12px;
    font-weight: 700;
  }
  ${mediaQueries("mobileSE")} {
    font-size: 16px;
    font-weight: 700;
  }
`;

const Splitter = styled.div`
  width: 1.5px;
  height: 12px;
  background-color: #2d2d2d;
  opacity: 0.5;
  margin-right: 10px;
  margin-left: 10px;
`;

interface MobileHeaderMoreProps {
  show: boolean;
  userInfo: User | null;
  setShowMobileMore: Dispatch<SetStateAction<boolean>>;
}

const iOS =
  typeof navigator !== "undefined" &&
  /iPad|iPhone|iPod/.test(navigator.userAgent);

const MobileHeaderMore: React.FC<MobileHeaderMoreProps> = ({
  show,
  setShowMobileMore,
  userInfo,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const usersDispatch = useUsersDispatch();
  const logout = () => {
    localStorage.removeItem("token");
    window.location.reload();
    usersDispatch({ type: "SET_USER_INFO", userInfo: null });
  };

  const login = () => {
    setShowMobileMore(false);
    history.push("/login");
  };

  const changeLanguage = () => {
    if (i18next.language === "en") {
      history.push("/?lng=ko");
    } else if (i18next.language === "ko") {
      history.push("/?lng=en");
    } else {
      history.push("/?lng=en");
    }
    setShowMobileMore(false);
    window.location.reload();
    // window.location.reload();
    // if (i18next.language === "en") {
    //   i18next.changeLanguage("ko");
    // } else {
    //   i18next.changeLanguage("en");
    // }
    //   window.location.reload();
    // onDismiss();
  };

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setShowMobileMore(open);
  };

  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      anchor="left"
      open={show}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Box
        sx={{ width: `calc(100vw * 0.8)` }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <MobileMoreWrapper>
          <IcArrowLeftWrapper onClick={() => setShowMobileMore(false)}>
            <IcArrowLeft src={icArrowLeft} />
          </IcArrowLeftWrapper>
          {userInfo && userInfo.is_once_modified ? (
            <ProfileWrapper>
              <ProfileImg src={"https://picsum.photos/200"} />
              <ProfileInfo>
                <ProfileName>{userInfo.nickname}</ProfileName>
                <Balance
                  balance={
                    (userInfo.bonus_token_balance || 0) +
                    (userInfo.normal_token_balance || 0)
                  }
                  onClick={() => history.push("/shop")}
                />
              </ProfileInfo>
            </ProfileWrapper>
          ) : null}
          <NavWrapper>
            <TextLink
              onClick={() => history.push("/births")}
              style={{ color: "#8743FF" }}
            >
              {t("header.birth")}
            </TextLink>
            <TextLink
              onClick={() => history.push("/results")}
              style={{ color: "#8743FF" }}
            >
              {t("header.result")}
            </TextLink>
            <TextLink
              onClick={() => history.push("/shop")}
              style={{ color: "#8743FF" }}
            >
              {t("header.shop")}
            </TextLink>
            {/*<Splitter />*/}
            <TextLink onClick={() => history.push("/mypage")}>
              {t("header.mypage")}
            </TextLink>

            <TextLink onClick={changeLanguage} style={{ marginBottom: "50px" }}>
              한국어 / English
            </TextLink>

            {userInfo ? (
              <TextLink onClick={logout}>{t("header.logout")}</TextLink>
            ) : (
              <TextLink onClick={login}>{t("header.login")}</TextLink>
            )}
            <TermsLinkWrapper>
              <TermsLink
                onClick={() =>
                  window.open(
                    "https://www.termsfeed.com/live/d45ca735-59ec-4336-acc6-11a08e17ebe4"
                  )
                }
              >
                {t("header.service")}
              </TermsLink>
              <Splitter />
              <TermsLink
                onClick={() =>
                  window.open(
                    "https://www.termsfeed.com/live/d316303d-4b6a-4ac7-ad66-1de702d86e7c"
                  )
                }
              >
                {t("header.personal")}
              </TermsLink>
            </TermsLinkWrapper>
          </NavWrapper>
        </MobileMoreWrapper>
      </Box>
    </SwipeableDrawer>
  );
};

export default MobileHeaderMore;
