import React, { useEffect, useState } from "react";
import icArrowLeft from "@assets/imgs/chevron-down-solid-3@2x.png";
import SearchPng from "@assets/imgs/search_white.png";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { mediaQueries, MobileView } from "@styles/common";
import { useTranslation } from "react-i18next";
import DeletePng from "@assets/imgs/delete.png";

import useRecommendCardsQuery from "@query/useRecommendCardsQuery";
import { CardModel } from "@models/users/Card";
import { searchCards } from "@api/cards.api";
import Loading from "@components/atoms/Loading";
import useHashQuery from "@query/useHashQuery";
import Card from "@components/atoms/Card";

interface MobileSearchProps {
  show: boolean;
  onDismiss: () => void;
}

const MobileSearch: React.FC<MobileSearchProps> = ({ show, onDismiss }) => {
  const [keyword, setKeyword] = useState<string>("");
  const [searchedKeyword, setSearchedKeyword] = useState<string>("");
  const [resultCards, setResultCards] = useState<CardModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    data: hashTags,
    isLoading: isHashTagsLoading,
    isError: isHashTagsError,
  } = useHashQuery();

  const history = useHistory();
  const { t } = useTranslation();

  const {
    data: recommendCards,
    isLoading: isRecommendCardsLoadings,
  } = useRecommendCardsQuery({});

  const search = async (sampleKeyword?: string) => {
    setIsLoading(true);
    const result = await searchCards({ hashtag: sampleKeyword ?? keyword });
    setResultCards(result.cards);
    setIsLoading(false);
    setSearchedKeyword(sampleKeyword ?? keyword);
  };

  useEffect(() => {
    setKeyword("");
  }, [show]);
  if (!show) return null;

  const handleCardClick = (curId?: number) => {
    if (curId) {
      history.push("/cards/" + curId);
    }
  };

  const onDelete = () => {
    setKeyword("");
  };

  return (
    <>
      {(isLoading || isRecommendCardsLoadings) && <Loading />}
      <MobileView>
        <MobileSearchWrapper>
          <IcArrowLeftWrapper>
            <Button onClick={onDismiss}>
              <IcArrowLeft src={icArrowLeft} />
            </Button>
          </IcArrowLeftWrapper>
          <SearchBarWrapper>
            <SearchInputWrapper>
              <Button onClick={() => search()}>
                <SearchIcon src={SearchPng} />
              </Button>

              <SearchInput
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder={t("search.word")}
              />
            </SearchInputWrapper>
            <Button onClick={onDelete}>
              <DeleteIcon src={DeletePng} />
            </Button>
          </SearchBarWrapper>
          <Content>
            <SampleKeywordWapper>
              {!isHashTagsLoading || !isHashTagsError ? (
                hashTags?.map((item: any) => {
                  return (
                    <SmableKeywordBtn
                      onClick={() => {
                        setKeyword(item.name);
                        search(item.name);
                      }}
                    >
                      <SampleKeyword key={item.hashtag_id}>
                        {item.name}
                      </SampleKeyword>
                    </SmableKeywordBtn>
                  );
                })
              ) : (
                <></>
              )}
            </SampleKeywordWapper>

            <TitleSection>
              <Title>{t("search.related_to")}</Title>
              <Keyword>{searchedKeyword}</Keyword>
            </TitleSection>

            {resultCards.length > 0 ? (
              <CardSectinon>
                {resultCards.map((info) => (
                  <Grid>
                    <Card
                      key={"idx-" + info.title + "-" + info.card_id}
                      imgUrl={info.thumbnail_url}
                      description={info.title}
                      onClick={() => handleCardClick(info.card_id!)}
                    />
                  </Grid>
                ))}
              </CardSectinon>
            ) : (
              <NoResultWrapper>
                <NoResult>{t("search.no_result")}</NoResult>
                <Description>{t("search.description")}</Description>
              </NoResultWrapper>
            )}

            <TitleSection>
              <Title>{t("search.recommendation")}</Title>
            </TitleSection>
            <CardSectinon>
              {recommendCards?.cards ? (
                recommendCards.cards.map((info) => (
                  <Grid>
                    <Card
                      key={"idx-" + info.title + "-" + info.card_id}
                      imgUrl={info.thumbnail_url}
                      description={info.title}
                      onClick={() => handleCardClick(info.card_id!)}
                    />
                  </Grid>
                ))
              ) : (
                <></>
              )}
            </CardSectinon>
          </Content>
        </MobileSearchWrapper>
      </MobileView>
    </>
  );
};
const Grid = styled.div`
  display: grid;
  min-width: 378px;
  max-width: 378px;
  width: 378px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: content-box;
  &:first-child {
    padding-left: 60px;
  }
  &:last-child {
    padding-right: 60px;
  }
  ${mediaQueries("tablet")} {
    min-width: 245px;
    max-width: 245px;
    width: 245px;
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
`;

const NoResult = styled.p`
  font-family: Montserrat;
  text-align: left;
  font-size: 24px;
  font-weight: 900;
  color: #2d2d2d;
  margin-top: 35px;
  margin-bottom: 0px;
`;
const TitleSection = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
`;
const Title = styled.span`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 900;
  color: #2d2d2d;
`;
const Keyword = styled.span`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 900;
  color: #713bdb;
  margin-left: 5px;
`;
const Description = styled.p`
  font-family: NotoSansCJKkr;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #2d2d2d;
  margin-top: 5px;
  margin-bottom: 50px;
`;
const MobileSearchWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  background-color: white;
  overflow-y: scroll;
`;
const Content = styled.div`
  display: flex;
  justify-content: flex-start;

  padding-top: 40px;
  flex-direction: column;
`;

const SampleKeyword = styled.p`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #d8d8d8;
  margin-top: 0px;
`;

const SearchInput = styled.input`
  margin-left: 21px;
  width: 80%;
  border: none;
  background-color: #713bdb;
  font-weight: bold;
  font-family: -Bold, sans-serif;
  font-size: 20px;
  color: white;
  &::placeholder {
    color: white;
  }
`;
const IcArrowLeftWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 17px 8px;
`;
const IcArrowLeft = styled.img`
  width: 42px;
  height: 42px;
`;

const SearchBarWrapper = styled.div`
  width: 100vw;
  height: 48px;
  background-color: #713bdb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
`;

const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SearchIcon = styled.img``;
const Button = styled.button`
  border: none;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  cursor: pointer;
`;
const DeleteIcon = styled.img``;

const CardSectinon = styled.div`
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  margin-top: 20px;
  margin-bottom: 60px;
`;
const NoResultWrapper = styled.div`
  padding-left: 20px;
`;

const SampleKeywordWapper = styled.div`
  flex-direction: row;
  overflow-x: scroll;
  white-space: nowrap;
  justify-content: flex-start;
  width: 100%;
  display: flex;
`;

const SmableKeywordBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:first-child {
    padding-left: 20px;
  }
  &:last-child {
    padding-right: 20px;
  }
`;

export default MobileSearch;
