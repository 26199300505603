import { getLatestCards } from "./../api/cards.api";
import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";

const QUERY_KEY = "LATEST_CARDS";

const useGetLatestCardsQuery = (
  queryOptions?: UseQueryOptions<any, AxiosError>
) => {
  return useQuery<any, AxiosError>(
    QUERY_KEY,
    () => getLatestCards(),
    queryOptions
  );
};
export default useGetLatestCardsQuery;
