import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";

const Wrapper = styled.div`
  display: flex;
  /* height: 100%; */
  flex-direction: column;
  border-radius: 24px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  overflow: hidden;
  cursor: pointer;
  ${mediaQueries("desktop")} {
    margin-bottom: 20px;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  width: 100%;
`;
const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  flex: 1;
  width: 100%;
  padding: 23px 40px 50px 40px;
`;

const ContentTitle = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  line-height: 1.29;
  color: #2c2738;
  ${mediaQueries("mobileL")} {
    font-size: 18px;
  }
`;

export interface CardProps {
  style?: React.CSSProperties;
  imgUrl?: string;
  description?: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  id?: string;
}

export default function Card({
  onClick,
  style,
  imgUrl,
  description,
  className,
  id,
}: CardProps) {
  return (
    <Wrapper id={id} style={style} className={className} onClick={onClick}>
      <ImageWrapper>
        <StyledImg src={imgUrl} />
      </ImageWrapper>

      <ContentWrapper>
        <ContentTitle>{description || "No description"}</ContentTitle>
      </ContentWrapper>
    </Wrapper>
  );
}
