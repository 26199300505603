import { CardResultDetail } from "@api/card.result.api";
import { deleteCard } from "@api/cards.api";
import MoreButton from "@components/atoms/MoreButton";
import ResultCard from "@components/atoms/ResultCard";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";

const Wrapper = styled.div`
  margin: 0 auto 60px;
  max-width: 1200px;
  min-width: 1200px;
  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("mobileL")} {
    margin: 0 15px;
    max-width: calc(100%);
    min-width: calc(100% - 40px);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px 30px;
  ${mediaQueries("tablet")} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mediaQueries("mobileL")} {
    display: block;
  }
`;

interface CardRowProps {
  list?: CardResultDetail[];
  onCardClick?: (id: string, data: any) => void;
  reload: () => Promise<void>;
  onMoreClick: () => Promise<void>;
  totalCount: number;
}

const ResultCardTable = ({
  list,
  onCardClick,
  reload,
  onMoreClick,
  totalCount,
}: CardRowProps) => {
  const _handleDelete = async (resultId: string) => {
    try {
      await deleteCard({ result_id: resultId });
      await reload();
    } catch (e) {
      console.log("when handle delete failed");
    }
  };

  return (
    <Wrapper>
      <Grid>
        {list &&
          list.map((v) => (
            <ResultCard
              key={"idx-" + v.card_result_id}
              id={v.card_result_id}
              imgUrl={v.thumbnail_url}
              name={v.name}
              birthdate={v.birth_datetime}
              gender={v.gender}
              name2={v.name_2}
              birthdate2={v.birth_datetime_2}
              gender2={v.gender_2}
              card_description={v.card_description}
              onClick={() =>
                onCardClick && onCardClick(v.card_result_id, { name: v.name })
              }
              onDelete={_handleDelete}
            />
          ))}
      </Grid>
      {list && totalCount > list?.length && (
        <MoreButton onClick={onMoreClick} />
      )}
    </Wrapper>
  );
};

export default ResultCardTable;
