import icPageLeft from '@assets/imgs/ic_page_left.png';
import icPageRight from '@assets/imgs/ic_page_right.png';
import styled from '@emotion/styled';
import { mediaQueries } from '@styles/common';
import { useMemo } from 'react';

const range = (start: number, end: number) => {
  const length = end - start;
  return Array.from({ length }, (_, i) => start + i);
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  padding-right: 20px;
  ${mediaQueries('tablet')} {
    justify-content: center;
  }
`;
const Arrow = styled.img`
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

const LeftArrow = styled(Arrow)`
  margin-right: 14px;
`;
const RightArrow = styled(Arrow)`
  margin-left: 14px;
`;

interface PageWrapperProps {
  active: boolean;
}
const PageWrapper = styled.div<PageWrapperProps>`
  cursor: pointer;
  padding: 5px 14px;
  font-size: 20px;
  font-weight: 900;
  color: ${props => props.active ? '#713bdb' : '#b8b8b8'};
  background-color: ${props => props.active ? 'white' : 'transparent'};
  border-radius: 10px;
  box-shadow: ${props => props.active ? '0 0 20px 0 rgba(0, 0, 0, 0.06)' : 'none'};
  margin-right: 14px;
  &:last-child {
    margin-right: 0px;
  }
`;

interface TablePaginationProps {
  currentPage: number;
  totalCount: number;
  setCurrentPage: (n: number) => void;
}

const pageCount = 3;
const pageSize = 5;

const TablePagination = ({ currentPage, totalCount, setCurrentPage }: TablePaginationProps) => {
  const totalPage = useMemo(() => {
    const totalPage = Math.ceil(totalCount / pageSize);
    return totalPage;
  }, [totalCount]);

  const minPage = useMemo(() => {
    let q = Math.floor(currentPage / pageCount);
    if (currentPage % pageCount !== 0) q += 1;

    return (q * 3) - 2;
  }, [currentPage]);

  const maxPage = useMemo(() => {
    const _max = minPage + pageCount;
    if (_max >= totalPage) {
      return totalPage + 1;
    }
    return _max
  }, [minPage, totalPage]);

  const handleLeft = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };
  const handleRight = () => {
    if (currentPage === totalPage) return;
    setCurrentPage(currentPage + 1);
  };

  if (totalPage < pageCount) return null;

  return (
    <Wrapper>
      <LeftArrow src={icPageLeft} onClick={handleLeft} />
      {range(minPage, maxPage).map((n: number) => (
        <PageWrapper active={currentPage === n} onClick={() => setCurrentPage(n)}>
          {n}
        </PageWrapper>
      ))}
      <RightArrow src={icPageRight} onClick={handleRight} />
    </Wrapper >
  );
};

export default TablePagination;