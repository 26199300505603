import styled from "@emotion/styled";
import imgStarCoin from "@assets/imgs/ic_starcoin.png";
import { mediaQueries } from "@styles/common";

interface CharactersShowMoreButtonProps {
  price: number;
  onClickShowMore: () => void;
}
const CharactersShowMoreButton = ({
  price,
  onClickShowMore,
}: CharactersShowMoreButtonProps) => {
  return (
    <ShowMoreWrapper>
      <ShowMoreBox>
        <ShowMoreBoxText>좀 더 자세히 볼까?</ShowMoreBoxText>
        <ButtonWrapper>
          <Button
            onClick={onClickShowMore}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              margin: 0,
            }}
          >
            <RequiredStarCoinWrapper>
              <IcStarCoin src={imgStarCoin} />
              <div style={{ display: "flex" }}>
                <TextStarCoin style={{ marginRight: 10 }}>
                  {`x${price}`}
                </TextStarCoin>
              </div>
            </RequiredStarCoinWrapper>
          </Button>
        </ButtonWrapper>
      </ShowMoreBox>
    </ShowMoreWrapper>
  );
};

const ShowMoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const ShowMoreBox = styled.div`
  max-width: 296px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
`;
const ShowMoreBoxText = styled.p`
  color: #642ad6;
  font-size: 18px;
  font-weight: 500;
  margin-top: 3px;
  margin-bottom: 3px;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 120px;
  margin: 15px;
`;
const RequiredStarCoinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IcStarCoin = styled.img`
  width: 24px;
  height: 30px;
  margin-right: 5px;
`;
const TextStarCoin = styled.div`
  color: #ffde00;
  font-weight: 700;
`;

interface ButtonProps {
  displayed?: boolean;
}

const Button = styled.div<ButtonProps>`
  margin: 35px 135px 20px;
  height: 50px;
  padding: 25px 35px;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  ${mediaQueries("mobileL")} {
    margin: 35px 22px 20px;
  }
`;
export default CharactersShowMoreButton;
