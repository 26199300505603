import { GetCardResultResModel } from "@api/card.result.api";
import icRevision from "@assets/imgs/revision-regular@2x.png";
import ListWithSideBar from "@components/molecules/ListWithSideBar";
import ResultShare from "@components/molecules/ResultShare";

import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { BirthModel } from "@api/births.api";
import usePayForCard from "@hooks/usePayForCard";
import CharactersShowMoreButton from "@components/atoms/CharactersShowMoreButton";
interface ButtonProps {
  displayed?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-width: 768px;
  margin: 0 auto;
  border-radius: 36px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  overflow: hidden;

  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("tablet")} {
    max-width: calc(100%);
    min-width: calc(100%);
    border-radius: 0;
  }
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    margin: 0;
    padding-top: 0;
    padding-bottom: 40px;
  }
`;
const TopImg = styled.img`
  width: 100%;
  height: 100%;
  ${mediaQueries("tablet")} {
    border-bottom-right-radius: 36px;
  }
`;
const BottomWrapper = styled(Wrapper)`
  padding: 50px 100px;
  ${mediaQueries("mobileL")} {
    padding: 15px;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 100px;
  ${mediaQueries("mobileL")} {
    padding: 50px 40px;
  }
`;
const Title = styled.div`
  font-family: Noto Sans KR;
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 60px;
`;
const Comment = styled.div`
  margin-top: 60px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.16px;
  text-align: left;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    margin-top: 40px;
  }
`;
const CommentAgain = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  text-align: center;
  margin-top: 10px;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    margin-top: 40px;
  }
`;
const Button = styled.div<ButtonProps>`
  margin: 35px 135px 20px;
  height: 50px;
  padding: 25px 35px;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  ${mediaQueries("mobileL")} {
    margin: 35px 22px 20px;
  }
`;
const IcRevision = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 14px;
`;
const Text = styled.div`
  margin: 3px 0 1px 14px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: -0.36px;
  text-align: center;
  color: #ffffff;
`;

const ShowMoreTitleBox = styled.div`
  width: 200px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff9dbd;
  border-radius: 20px;
  margin-bottom: 50px;
`;
interface ObesessionResultProps {
  result: GetCardResultResModel;
  result_id: string;
  scoop_button_displayed?: boolean;
  thumbnail_url: string;
  title?: string;
  result_img?: string;
  otherBirth?: BirthModel;
  secondOtherBirth?: BirthModel;
  curId?: number;
  characters: any;
}

const Content = ({ characters }: { characters: any }) => {
  const [cardResult, setCardResult] = useState<
    { title: string; content: string[] }[]
  >([]);

  useEffect(() => {
    for (const prop in characters) {
      setCardResult((prev) =>
        prev.concat({
          title: prop,
          content: characters[prop],
        })
      );
    }
  }, [characters]);

  return (
    <>
      {cardResult.map((item, index) => (
        <ListWithSideBar title={item.title} result={item.content} />
      ))}
    </>
  );
};
const PaidContent = ({ characters }: { characters: any }) => {
  const [cardResult, setCardResult] = useState<
    { title: string; content: string[] }[]
  >([]);
  useEffect(() => {
    for (const prop in characters.겉모습행동과_속마음) {
      setCardResult((prev) =>
        prev.concat({
          title: prop,
          content: characters.겉모습행동과_속마음[prop],
        })
      );
    }
  }, [characters.겉모습행동과_속마음]);
  return (
    <>
      <ShowMoreTitleBox>
        <span style={{ fontSize: 20 }}>자세한 분석 내용</span>
      </ShowMoreTitleBox>
      {cardResult.map((item, index) => (
        <ListWithSideBar title={item.title} result={item.content} />
      ))}
    </>
  );
};
const HiddenCharacters6Each = ({
  result,
  result_id,
  result_img,
  scoop_button_displayed,
  thumbnail_url,
  title,
  otherBirth,
  secondOtherBirth,
  curId,
  characters,
}: ObesessionResultProps) => {
  const history = useHistory();
  const [isPaid, setIsPaid] = useState(false);

  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const toggleShowMore = () => {
    setShowConfirmModal((prev) => !prev);
  };

  const PRICE = 2;
  useEffect(() => {
    if (result?.type === "hidden_characters_6_1_each") {
      setIsPaid(true);
      return;
    }
    setIsPaid(false);
  }, [result?.type]);

  const { getModals } = usePayForCard({
    otherBirth,
    secondOtherBirth,
    cardId: "2030",
    result,
    price: PRICE,
    showConfirmModal,
    toggleShowMore,
    endpoint: "hidden_characters_6_1_each",
    isRequiredPerson: false,
  });

  const onClickShowMore = () => {
    setShowConfirmModal(true);
  };

  return (
    <>
      <Wrapper>
        <TopImg src={result_img} />
        <ContentWrapper>
          {isPaid ? (
            <PaidContent characters={characters} />
          ) : (
            <>
              <Title>{title}</Title>
              <Content characters={characters} />
              <CharactersShowMoreButton
                price={2}
                onClickShowMore={onClickShowMore}
              />
            </>
          )}
          <ResultShare
            link={result.link}
            thumbnail_url={thumbnail_url}
            description={result.description}
          />
          {getModals()}
        </ContentWrapper>
      </Wrapper>
      <BottomWrapper style={{ marginTop: "30px" }}>
        <CommentAgain>{result.comment[3]}</CommentAgain>
        <Button onClick={() => history.push(`/cards/${result_id}`)}>
          <IcRevision src={icRevision} />
          <pre>
            <Text>{t("card_result.button")}</Text>
          </pre>
        </Button>
      </BottomWrapper>
    </>
  );
};

export default HiddenCharacters6Each;
