import axios from "axios";
import apiAgent from "./api.agent";
import config from "./config";
import { ProductsModel } from "@models/products/Product";

interface GetProductListReqModel {}
interface GetProductListResModel {
  products?: ProductsModel[];
}

export const getProductList = async (req: GetProductListReqModel) => {
  try {
    const { data } = await apiAgent.axios.get<GetProductListResModel>(
      config.API_URL + "/v1/products"
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};
