import styled from "@emotion/styled";

interface CharacterCardProps {
  title: string;
  titleBoxColor: string;
  titleCaptionColor?: string;
  contentBoxColor: string;
  isShow: boolean;
  toggleIsShow: (id: string) => void;
  data: string[];
  id: string;
}
const CharacterCard = ({
  title,
  titleBoxColor,
  contentBoxColor,
  isShow,
  toggleIsShow,
  data,
  id,
  titleCaptionColor = "#000000",
}: CharacterCardProps) => {
  return (
    <>
      <Title>{title}</Title>
      {isShow ? (
        <Wrapper>
          <TitleBox
            titleBoxColor={titleBoxColor}
            titleCaptionColor={titleCaptionColor}
          >
            자세한 설명
          </TitleBox>
          <ContentBox contentBoxColor={contentBoxColor}>
            <ul>
              {data.map((item) => (
                <Li>{item}</Li>
              ))}
            </ul>
            <CloseButtonWrapper>
              <CloseButton>
                <CloseButtonCaption onClick={() => toggleIsShow(id)}>
                  접기
                </CloseButtonCaption>
              </CloseButton>
            </CloseButtonWrapper>
          </ContentBox>
        </Wrapper>
      ) : (
        <ShowMoreButton
          titleBoxColor={titleBoxColor}
          showMoreButtonCaptionColor={titleCaptionColor}
          onClick={() => toggleIsShow(id)}
        >
          <u>자세한 설명</u>
        </ShowMoreButton>
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 75px;
`;
interface TitleBoxProps {
  titleBoxColor: string;
  titleCaptionColor: string;
}
const TitleBox = styled.div<TitleBoxProps>`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  background-color: ${({ titleBoxColor }) => titleBoxColor};
  color: ${({ titleCaptionColor }) => titleCaptionColor};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

interface ContentBoxProps {
  contentBoxColor: string;
}
const ContentBox = styled.div<ContentBoxProps>`
  width: 100%;
  padding: 16px;
  background-color: ${({ contentBoxColor }) => contentBoxColor};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

interface ShowMoreButtonProps {
  titleBoxColor: string;
  showMoreButtonCaptionColor: string;
}
const ShowMoreButton = styled.button<ShowMoreButtonProps>`
  border: none;
  border-radius: 20px;
  height: 30px;
  background-color: ${({ titleBoxColor }) => titleBoxColor};
  color: ${({ showMoreButtonCaptionColor }) => showMoreButtonCaptionColor};
  padding-left: 8px;
  padding-right: 8px;
`;
const Li = styled.li`
  margin-bottom: 20px;
`;
const Title = styled.p`
  font-family: "Noto Sans KR";
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 26px;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CloseButton = styled.button`
  border: none;
  background-color: transparent;
`;
const CloseButtonCaption = styled.u`
  color: #9d9d9d;
  font-size: 17px;
  font-weight: 700;
  font-family: "Noto Sans KR";
`;
export default CharacterCard;
