import styled from "@emotion/styled";

export const breakpoints = {
  mobileSE: "320",
  mobileL: "425",
  tablet: "820",
  desktopSmall: "1080",
  desktop: "1200",
};

export const mediaQueries = (key: keyof typeof breakpoints) => {
  return `@media (max-width: ${breakpoints[key]}px)`;
};

export const theme = {
  content: {
    width: "1200px",
    containerCSS: `
      width: 100%;
      min-width: 1060px;
      max-width: 1060px;
      margin: 0 auto;
      ${mediaQueries("tablet")} {
        min-width: 100%;
      }
    `,
    swiper: {
      laptopHeight: "480px",
      mobileHeight: "202px",
    },
  },
  radius: {
    small: "2px",
    medium: "4px",
    xmedium: "8px",
    large: "10px",
    xlarge: "16px",
  },
  // TODO: design guide 에 맞게 수정
  text: {
    h_xxlarge: "40px",
    h_xlarge: "36px",
    h_large: "32px",
    h_medium: "28px",
    h_small: "24px",
    h_tiny: "22px",
    h_xsmall: "20px",
    h_xxsmall: "18px",
    p_medium: "16px",
    p_small: "14px",
    p_xsmall: "12px",
    p_xlsmall: "11px",
    p_xxsmall: "10px",
  },
  weight: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
  colors: {
    red: "#ff0000",
    pastelRed: "#ff7568",
    liveorange: "#ff6800",
    white: "#ffffff",
    black: "#1f1f1f",
    ventablack: "#000000",
    whites: {
      100: "#f9f9f9",
    },
    greys: {
      100: "#f4f4f4",
      200: "#eaeaea",
      300: "#d3d3d3",
      400: "#bcbcbc",
      500: "#a5a5a5",
      600: "#959595",
      700: "#747474",
      800: "#505050",
      900: "#333333",
    },
  },
  zIndexes: {
    hide: -1,
    icon: 1,
    swiperPage: 1,
    toggle: 1,
    selectOption: 2,
    courseDetailNav: 2,
    bottomAction: 4,
    headerDropdown: 5,
    movingBox: 20,
    backdrop: 999999,
    homeVideo: 1000000, // highest priority
  },
} as const;

export const MobileView = styled.div`
  display: none;
  ${mediaQueries("tablet")} {
    display: block;
  }
`;

export const DesktopView = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${mediaQueries("tablet")} {
    display: none;
  }
`;

export type IText = keyof typeof theme.text;
export type ITextWeight = keyof typeof theme.weight;
export type IColor = keyof typeof theme.colors;
export type IColorGrey = keyof typeof theme.colors.greys;
export type IColorWithGrey = IColor | `grey${keyof typeof theme.colors.greys}`;
export type IRadius = keyof typeof theme.radius;

export type ITheme = typeof theme;
