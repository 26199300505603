import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import KakaoLogin from "react-kakao-login";
import * as React from "react";
import { getUserInfo, login, signup } from "@api/user.api";
import apiAgent from "@api/api.agent";
import { useCommonDispatch } from "@contexts/common.context";
import { useUsersDispatch } from "@contexts/users.context";
import { CSSProperties } from "react";
import { useHistory } from "react-router";
import { useCardIdDispatch, useCardIdState } from "@contexts/cardId.context";
import useRedirectCardDetail from "@hooks/useRedirectCardDetail";

const KakaoButtonStyle: CSSProperties = {
  display: "inline-block",
  padding: "0px",
  width: "360px",
  height: "50px",
  lineHeight: "49px",
  color: "rgb(60, 30, 30)",
  backgroundColor: "rgb(255, 235, 0)",
  border: "none",
  borderRadius: "16px",
  fontSize: "16px",
  fontWeight: "bold",
  textAlign: "center",
};
const StyledButton = styled.button`
  width: 240px;
  height: 56px;
  padding: 0px;

  ${mediaQueries("desktop")} {
    max-width: 100%;
    //min-width: 100%;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  //box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  //background-image: linear-gradient(279deg, #ffa000 100%, #ffc300);
  background-color: #ffffff;
  border: none;
  outline: none;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 0.88;
  letter-spacing: -0.32px;
  color: #ffffff;
  cursor: pointer;
`;

interface LoginResponse {
  token_type: string;
  access_token: string;
  expires_in: string;
  refresh_token: string;
  refresh_token_expires_in: number;
  scope: string;
}

interface KakaoResp {
  response: LoginResponse;
  profile?: any;
}

export interface KakaoButtonProps {
  style?: React.CSSProperties;
}

export default function KakaoLoginButton({ style }: KakaoButtonProps) {
  const commonDispatch = useCommonDispatch();
  const usersDispatch = useUsersDispatch();
  const { redirectCardDetail } = useRedirectCardDetail();

  const history = useHistory();

  const getKakaoToken = async (token: string) => {
    if (!token) {
      return;
    }
    commonDispatch({ type: "SET_IS_LOADING", isLoading: true });
    const res = await login({ sns_token: token, sns_type: "kakao" });

    if (!res) {
      commonDispatch({ type: "SET_IS_LOADING", isLoading: false });
      return alert("로그인에 실패하였습니다.");
    }

    if (res === "NOT_REGISTERED") {
      const signupRes = await signup({ sns_token: token, sns_type: "kakao" });
      if (!signupRes) return alert("회원가입에 실패하였습니다.");

      const { access_token } = signupRes;
      await handleTokenUpdated(access_token);
      await getUserAndUpdate();

      return history.push("/extraInfo", { agreement_displayed: true });
    }

    const { access_token, is_once_modified } = res;
    await handleTokenUpdated(access_token);

    if (!is_once_modified) {
      await handleNotModified();
    } else {
      await handleModified();
    }
  };

  const handleTokenUpdated = async (access_token: string) => {
    commonDispatch({ type: "SET_IS_LOADING", isLoading: false });
    await apiAgent.updateToken(access_token);
    localStorage.setItem("token", access_token);
  };

  const getUserAndUpdate = async () => {
    const user = await getUserInfo({});
    if (user) usersDispatch({ type: "SET_USER_INFO", userInfo: user });
  };

  const handleNotModified = async () => {
    usersDispatch({ type: "SET_ADDITINAL_INFO_NEEDED", needed: true });
    history.push("/extraInfo", { agreement_displayed: true });
  };

  const handleModified = async () => {
    await getUserAndUpdate();
    redirectCardDetail();
  };

  const handleKakaoFail = (resp: any) => {
    console.log(resp);
  };

  return (
    <StyledButton style={style}>
      <KakaoLogin
        token={"ae62d23782cc54962babd3ff47be9ebb"}
        onSuccess={(resp: KakaoResp) =>
          getKakaoToken(resp.response.access_token)
        }
        onFail={handleKakaoFail}
        style={KakaoButtonStyle}
      />
    </StyledButton>
  );
}
