import i18next from "i18next";

export const genderTranslation = (gender: string) => {
  const lang = i18next.language.slice(0, 2);
  if (lang === "ko" && gender === "MAN") {
    return "남자";
  } else if (lang === "ko" && gender !== "MAN") {
    return "여자";
  } else if (lang === "en" && gender === "MAN") {
    return "male";
  } else if (lang === "en" && gender !== "MAN") {
    return "female";
  } else {
    return;
  }
};
