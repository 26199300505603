import styled from "@emotion/styled";
import {mediaQueries} from "@styles/common";
import Product from "@components/atoms/Product";
import {ProductsModel} from "@models/products/Product";

const Wrapper = styled.div`
  margin: 0 auto 160px;
  max-width: 800px;
  min-width: 800px;
  min-height: calc(100vh - 700px);
  ${mediaQueries('desktop')} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 0.4fr);
  gap: 0px 30px;
  ${mediaQueries('tablet')} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mediaQueries('desktop')} {
    display: block;
  }
`;

interface ProductRowProps {
  list?: ProductsModel[];
  // onCardClick: (card: CardModel) => void;
  onProductClick: (id: string) => void;
}

const ProductRow = ({list, onProductClick}: ProductRowProps) => {
  return (
    <Wrapper>
      <Grid>
        {list && list.map((v) =>
            <Product
                key={'product-idx-' + v.id}
                description={v.description}
                total={v.total}
                onClick={() => onProductClick(v.id!)}
            />
        )}
      </Grid>
    </Wrapper>
  )
}

export default ProductRow;
