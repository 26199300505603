import { CardModel } from "@models/users/Card";
import React, { useReducer, useContext, createContext, Dispatch } from "react";

type State = {
  page: number;
  total_count_cards: number;
  popular_cards: CardModel[];
  recent_cards: CardModel[];
  total_cards: CardModel[];
  current_card: CardModel | null;
  current_popular_cards: CardModel[];
};

type Action =
  | {
      type: "SET_MAIN_CARDS";
      popular_cards: CardModel[];
      recent_cards: CardModel[];
      total_cards: CardModel[];
      page: number;
      total_count_cards: number;
    }
  | { type: "SET_MORE_TOTAL_CARDS"; total_cards: CardModel[] }
  | {
      type: "SET_CURRENT_CARD";
      current_card: CardModel | null;
      current_popular_cards: CardModel[];
    };

type CardsDispatch = Dispatch<Action>;

const CardsStateContext = createContext<State | null>(null);
const CardsDispatchContext = createContext<CardsDispatch | null>(null);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_MAIN_CARDS":
      return {
        ...state,
        ...action,
      };
    case "SET_MORE_TOTAL_CARDS":
      return {
        ...state,
        total_cards: [...state.total_cards, ...action.total_cards],
        page: state.page + 1
      };
    case "SET_CURRENT_CARD":
      return {
        ...state,
        ...action,
      };
    default:
      throw new Error("Unhandled action");
  }
}

function CardsProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, {
    popular_cards: [],
    recent_cards: [],
    total_cards: [],
    current_card: null,
    current_popular_cards: [],
    page: 1,
    total_count_cards: 0,
  });

  return (
    <CardsStateContext.Provider value={state}>
      <CardsDispatchContext.Provider value={dispatch}>
        {children}
      </CardsDispatchContext.Provider>
    </CardsStateContext.Provider>
  );
}

function useCardsState() {
  const state = useContext(CardsStateContext);
  if (!state) throw new Error("Cannot find UsersProvider"); // 유효하지 않을땐 에러를 발생
  return state;
}

function useCardsDispatch() {
  const dispatch = useContext(CardsDispatchContext);
  if (!dispatch) throw new Error("Cannot find UsersProvider"); // 유효하지 않을땐 에러를 발생
  return dispatch;
}

export { CardsProvider, useCardsState, useCardsDispatch };
