import { editUser, getUserInfo } from "@api/user.api";
// import logo from '@assets/imgs/logo.png';
import Input from "@components/atoms/Input";
import Form from "@components/molecules/Form";
import { useCardIdDispatch, useCardIdState } from "@contexts/cardId.context";
import { useCommonDispatch } from "@contexts/common.context";
import { useUsersDispatch } from "@contexts/users.context";
import styled from "@emotion/styled";
import useRedirectCardDetail from "@hooks/useRedirectCardDetail";
import { mediaQueries } from "@styles/common";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 60px;
  max-width: 1200px;
  min-width: 1200px;
  text-align: left;
  display: flex;
  padding-bottom: 100px;
  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    flex-direction: column;
  }
`;

const InfoWrapper = styled.div`
  flex: 1;
`;

const FormWrapper = styled.div`
  flex: 1;
`;

const Logo = styled.img`
  margin-bottom: 40px;
`;
const Title = styled.div`
  width: 400px;
  margin-bottom: 40px;
  text-align: left;
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 900;
  line-height: 1.25;
  letter-spacing: -0.96px;
  color: #2d2d2d;
  ${mediaQueries("tablet")} {
    width: 300px;
  }
`;

const Description = styled.div`
  margin-bottom: 50px;
  width: 400px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: -0.32px;
  color: #2d2d2d;
  ${mediaQueries("tablet")} {
    width: 300px;
  }
`;

const NickNameWrapper = styled.div`
  ${mediaQueries("desktop")} {
    margin-bottom: 30px;
  }
`;

const NickNameTitle = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 900;
  line-height: 0.7;
  letter-spacing: -0.4px;
  color: #2d2d2d;
  margin-bottom: 18px;
`;

interface SignupFormProps {
  agreement_displayed: boolean;
}

const SignupForm = ({ agreement_displayed }: SignupFormProps) => {
  const [nickname, setNickname] = useState<string>("");
  const commonDispatch = useCommonDispatch();
  const { redirectCardDetail } = useRedirectCardDetail();
  const usersDispatch = useUsersDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const handleSubmit = async (formData: any) => {
    try {
      commonDispatch({ type: "SET_IS_LOADING", isLoading: true });
      if (!(formData.latitude >= 18.5 && formData.latitude <= 66.5)) {
        alert(t("birth_modal.latitude_info"));
        return;
      }
      if (formData.hour > 24) {
        alert(t("birth_modal.error_hour"));
        return;
      } else if (formData.am_pm && formData.hour >= 12) {
        alert(t("birth_modal.error_hour"));
        return;
      }
      formData.month = formData.month - 1;
      const _birthdate = moment({ ...formData })
        .format("YYYY-MM-DD HH:mm")
        .toString();
      const data = {
        ...formData,
        is_time_known: formData.isTimeKnown,
        name: nickname,
        birth_datetime: _birthdate,
        nickname,
        is_marketing_agreed: false,
        is_privacy_policy_agreed: formData.privacyPolicy,
        is_terms_of_service_agreed: formData.termsOfService,
      };

      await editUser(data);
      const user = await getUserInfo({});
      if (user) usersDispatch({ type: "SET_USER_INFO", userInfo: user });
      redirectCardDetail();
    } catch (e) {
      console.log("edit user : ", e);
    } finally {
      commonDispatch({ type: "SET_IS_LOADING", isLoading: false });
    }
  };
  return (
    <Wrapper>
      <InfoWrapper>
        {/*<Logo src={logo} />*/}
        <Title>{t("extra_info.welcome")}</Title>
        <Description>{t("extra_info.desc")}</Description>
      </InfoWrapper>
      <FormWrapper>
        <NickNameWrapper>
          <NickNameTitle>{t("extra_info.nickname")}</NickNameTitle>
          <Input
            style={{ textAlign: "left", width: "290px", paddingLeft: "20px" }}
            onValueChange={setNickname}
            value={nickname}
            placeholder={t("extra_info.nickname_placeholder")}
          />
        </NickNameWrapper>
        <Form
          onSumbit={handleSubmit}
          agreement_displayed={agreement_displayed}
        />
      </FormWrapper>
    </Wrapper>
  );
};

export default SignupForm;
