import styled from '@emotion/styled';
import { FC } from 'react';
import { useHistory } from 'react-router';

const Anchor = styled.a`
  font-family: "Noto Sans KR" , sans-serif;
  display: inline-block;
  color: #8743FF;
  opacity: 0.4;
  cursor: pointer;
  &:hover {
    font-family: "Noto Sans KR" , sans-serif;
    font-weight: 700;
    color: #8743FF;
    opacity: 1;
  }
`;

export interface LinkCompProps {
  to: string;
}

const LinkComp: FC<LinkCompProps> = ({
  children,
  to,
  ...props
}) => {
  const history = useHistory();
  const { location } = history;
  const handleClick = () => {
    history.push(to);
  }
  return (
    <span {...props} onClick={handleClick}>
      <Anchor style={location.pathname === to ? { opacity: 1, fontWeight: 900 } : {}}>
        {children}
      </Anchor>
    </span>
  );
};

export default LinkComp;
