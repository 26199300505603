import { ReactNode } from 'react';
// import styled, { css } from 'styled-components';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { mediaQueries } from '@styles/common';
import icArrowDown from '@assets/imgs/ic_arrow_down.png';
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  border-radius: 36px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  max-width: 1200px;
  min-width: 1200px;
  height: 50px;
  padding: 14px 0;
  ${mediaQueries('desktop')} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 150px;
  cursor: pointer;
  ${mediaQueries('mobileL')} {
    max-width: calc(100% - 40px);
    min-width: calc(100%);
  }
`;

const ArrowDown = styled.img`
  margin-right: 10px;
  width: 32px;
  height: 32px;
`;

const MoreText = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #b8b8b8;
`;

export interface MoreButtonProps {
  type?: 'button' | 'submit';
  primary?: boolean;
  active?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  children?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  id?: string;
}

export default function MoreButton({
  onClick,
  className,
  children,
  id,
}: MoreButtonProps) {
  const { t } = useTranslation();
  return (
    <Wrapper
      id={id}
      onClick={onClick}
      className={className}
    >
      <ArrowDown src={icArrowDown}/>
      <MoreText>{t('page_header.more')}</MoreText>
    </Wrapper>
  );
}
