import { GetCardResultResModel } from "@api/card.result.api";
import icRevision from "@assets/imgs/revision-regular@2x.png";
import CharacterCard from "@components/molecules/CharacterCard";
import ResultShare from "@components/molecules/ResultShare";

import styled from "@emotion/styled";
import useIsLoggedIn from "@hooks/useIsLoggedIn";
import { mediaQueries } from "@styles/common";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ConflictResolutionIcon from "@assets/imgs/conflict_resolution_icon.png";
import ResultImg from "@assets/imgs/card_results/hidden_characters_5.png";

interface ButtonProps {
  displayed?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-width: 768px;
  margin: 0 auto;
  border-radius: 36px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  overflow: hidden;

  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("tablet")} {
    max-width: calc(100%);
    min-width: calc(100%);
    border-radius: 0;
  }
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    margin: 0;
    padding-top: 0;
    padding-bottom: 40px;
  }
`;
const TopImg = styled.img`
  width: 100%;
  height: 100%;
  ${mediaQueries("tablet")} {
    border-bottom-right-radius: 36px;
  }
`;
const BottomWrapper = styled(Wrapper)`
  padding: 50px 100px;
  ${mediaQueries("mobileL")} {
    padding: 15px;
  }
`;
const ContentWrapper = styled.div`
  padding: 50px 100px;
  ${mediaQueries("mobileL")} {
    padding: 50px 40px;
  }
`;
const TitleBox = styled.div`
  text-align: left;
  margin-bottom: 60px;
`;
const Title = styled.div`
  font-family: Noto Sans KR;
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 60px;
`;
const Comment = styled.div`
  margin-top: 60px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.16px;
  text-align: left;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    margin-top: 40px;
  }
`;
const CommentAgain = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  text-align: center;
  margin-top: 10px;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    margin-top: 40px;
  }
`;
const Button = styled.div<ButtonProps>`
  margin: 35px 135px 20px;
  height: 50px;
  padding: 25px 35px;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  ${mediaQueries("mobileL")} {
    margin: 35px 22px 20px;
  }
`;
const IcRevision = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 14px;
`;
const Text = styled.div`
  margin: 3px 0 1px 14px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: -0.36px;
  text-align: center;
  color: #ffffff;
`;
const ListFormatOnlyWrapper = styled.div`
  text-align: left;
`;

interface ObesessionResultProps {
  result: GetCardResultResModel;
  result_id: string;
  scoop_button_displayed?: boolean;
  thumbnail_url: string;
}

const HiddenCharacters5 = ({
  result,
  result_id,
  scoop_button_displayed,
  thumbnail_url,
}: ObesessionResultProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isLoggedIn = useIsLoggedIn();
  const [isShow, setIsShow] = useState<Record<string, boolean>>({
    item1: false,
    item2: false,
    item3: false,
    item4: false,
    item5: false,
  });

  const toggleIsShow = (id: string) => {
    setIsShow({ ...isShow, [id]: !isShow[id] });
  };

  return (
    <>
      <Wrapper>
        <TopImg src={ResultImg} />
        <ContentWrapper>
          <TitleBox>
            <img src={ConflictResolutionIcon} />
            <Title>상대가 갈등을 해결하는 중..</Title>
          </TitleBox>
          <ListFormatOnlyWrapper>
            {result?.characters?.상대_행동양식_문제해결방식
              ?.상대_불만속에_공존하는_패턴이나_양식_기질_나중심?.length >
              0 && (
              <CharacterCard
                title="불만속에 공존하는 패턴이나 양식 기질 나중심"
                titleBoxColor="#AE90E9"
                contentBoxColor="#F8F4FF"
                titleCaptionColor="#FFFFFF"
                isShow={isShow.item1}
                toggleIsShow={toggleIsShow}
                data={
                  result?.characters?.상대_행동양식_문제해결방식
                    ?.상대_불만속에_공존하는_패턴이나_양식_기질_나중심
                }
                id="item1"
              />
            )}
            {result?.characters.상대_행동양식_문제해결방식.상대_일반_갈등_기질
              .length > 0 && (
              <CharacterCard
                title="일반 화합 기질"
                titleBoxColor="#AE90E9"
                contentBoxColor="#F8F4FF"
                titleCaptionColor="#FFFFFF"
                isShow={isShow.item2}
                toggleIsShow={toggleIsShow}
                data={
                  result?.characters?.상대_행동양식_문제해결방식
                    ?.상대_일반_갈등_기질
                }
                id="item2"
              />
            )}
            {result?.characters?.상대_행동양식_문제해결방식
              ?.상대_일상적이지_않는_해결방식_기질?.length > 0 && (
              <CharacterCard
                title="일상적이지 않는 해결방식 기질"
                titleBoxColor="#AE90E9"
                contentBoxColor="#F8F4FF"
                titleCaptionColor="#FFFFFF"
                isShow={isShow.item3}
                toggleIsShow={toggleIsShow}
                data={
                  result?.characters?.상대_행동양식_문제해결방식
                    ?.상대_일상적이지_않는_해결방식_기질
                }
                id="item3"
              />
            )}
            {result?.characters?.상대_행동양식_문제해결방식
              ?.상대_조정과_공존추구_기질?.length > 0 && (
              <CharacterCard
                title="조정과 공존추구 기질"
                titleBoxColor="#AE90E9"
                contentBoxColor="#F8F4FF"
                titleCaptionColor="#FFFFFF"
                isShow={isShow.item4}
                toggleIsShow={toggleIsShow}
                data={
                  result?.characters?.상대_행동양식_문제해결방식
                    ?.상대_조정과_공존추구_기질
                }
                id="item4"
              />
            )}
            {result?.characters?.상대_행동양식_문제해결방식
              ?.상대_추구하나_잘_이루어지지_않는_기질?.length > 0 && (
              <CharacterCard
                title="추구하나 잘 이루어지지 않는 기질"
                titleBoxColor="#AE90E9"
                contentBoxColor="#F8F4FF"
                titleCaptionColor="#FFFFFF"
                isShow={isShow.item5}
                toggleIsShow={toggleIsShow}
                data={
                  result?.characters?.상대_행동양식_문제해결방식
                    ?.상대_추구하나_잘_이루어지지_않는_기질
                }
                id="item5"
              />
            )}
          </ListFormatOnlyWrapper>
          {result.comment && result.comment.length > 0 && (
            <Comment style={{ marginTop: "0px", marginBottom: "80px" }}>
              {result.comment[0]}
            </Comment>
          )}
          <ResultShare
            link={result.link}
            thumbnail_url={thumbnail_url}
            description={result.description}
          />
        </ContentWrapper>
      </Wrapper>
      <BottomWrapper style={{ marginTop: "30px" }}>
        <CommentAgain>{result.comment[3]}</CommentAgain>
        <Button onClick={() => history.push(`/cards/${result_id}`)}>
          <IcRevision src={icRevision} />
          <pre>
            <Text>{t("card_result.button")}</Text>
          </pre>
        </Button>
      </BottomWrapper>
    </>
  );
};

export default HiddenCharacters5;
