import Spacing from "@components/atoms/Spacing";
import Footer from "@components/molecules/Footer";
import Header from "@components/molecules/Header";
import * as React from "react";
import {
  getCardResultByLink,
  GetCardResultResModel,
} from "@api/card.result.api";
import MbtiResult from "@components/organisms/MbtiResult";
import ObesessionResult from "@components/organisms/ObesessionResult";
import TwoMbti from "@components/organisms/TwoMbti";
import MakeMbti from "@components/organisms/MakeMbti";
import MakeMbtiDestiny from "@components/organisms/MbtiDestiny";
import MakeMbtiCurrent from "@components/organisms/MbtiCurrent";
import ListFormatOnly from "@components/organisms/ListFormatOnly";
import ThemaLoveResult from "@components/organisms/ThemaLoveResult";
import CleanessCurrentResult from "@components/organisms/CleanessCurrentResult";
import MbtiLoveGen from "@components/organisms/MbtiLoveGen";
import LoveLucks from "../LoveLucks";
import DailyLucks from "../DailyLucks";
import Calendar from "../Calendar";
import LoveLucksChart from "../LoveLucksChart";
import PartnersCharacter from "../PartnersCharacter";
import MarriageLucksChart from "../MarriageLucksChart";
import Stocker from "../Stocker";
import Violence from "../Violence";
import HiddenCharacters from "../HiddenCharacters";
import BasicCharactersEach from "../BasicCharactersEach";
import BasicCharacters from "../BasicCharacters";
import HiddenCharacters5Each from "../HiddenCharacters5Each";
import HiddenCharacters5 from "../HiddenCharacters5";
import GansoriHateLove from "../GansoriHateLove";
import YangdariHateLove from "../YangdariHateLove";

//thumbnail
import HiddenCharactersPng from "@assets/imgs/card_results/hidden_characters_4.png";
import HiddenCharacters6Png from "@assets/imgs/card_results/hidden_characters_6.png";
import BasicCharactersOhang7Png from "@assets/imgs/card_results/basic_characters_ohang_7.png";
import BasicCharactersYukchin7Png from "@assets/imgs/card_results/basic_characters_yukchin_7.png";
import HidddenCharacters6EachPng from "@assets/imgs/card_results/hidden_characters_6_each.png";
import HiddenCharacters3EachPng from "@assets/imgs/card_results/hidden_characters_3_each.png";
import HiddenCharacters3Png from "@assets/imgs/card_results/hidden_characters_3.png";
import HiddenCharacters4Each from "../HiddenCharacters4Each";
import HiddenCharacters6Each from "../HiddenCharacters6Each";
import HiddenCharacters6 from "../HiddenCharacters6";
import HiddenCharactersWithTitle from "../HiddenCharactersWithTitle";
import BasicCharactersYukchin7 from "../BasicCharactersYukchin7";
import GivingCharacters from "../GivingCharacters";

const PublicLinkResult: React.FC = () => {
  const [res, setRes] = React.useState<
    GetCardResultResModel | null | undefined
  >(null);

  React.useEffect(() => {
    const _fetchCardResultByLink = async (link: string) => {
      try {
        const res = await getCardResultByLink(link);
        if (!res) return;
        setRes(res);
      } catch (e) {
        console.log("main list error : ", e);
      }
    };

    const link = window.location.search.split("=")[1];
    _fetchCardResultByLink(link);
  }, []);

  const renderResultPage = (res: GetCardResultResModel, id: string) => {
    if (res.type === "mbti-character-merit") {
      return (
        <MbtiResult
          result={res}
          result_id={id}
          scoop_button_displayed={true}
          type={"mbti-character-merit"}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (res.type === "mbti-character-demerit") {
      return (
        <MbtiResult
          result={res}
          result_id={id}
          scoop_button_displayed={true}
          type={"mbti-character-demerit"}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (res.type === "obsession") {
      return (
        <ObesessionResult
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
          graphColor_1={"#ff4747"}
          graphColor_2={"#8743ff"}
          barColor_1={"#dd2524"}
        />
      );
    } else if (res.type === "two_make_mbti") {
      return (
        <TwoMbti
          result={res}
          result_id={id}
          scoop_button_displayed={true}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (res.type === "make_mbti") {
      return (
        <MakeMbti
          result={res}
          result_id={id}
          scoop_button_displayed={true}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (res.type === "cleaness") {
      return (
        <ObesessionResult
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
          graphColor_1={"#ec6db2"}
          graphColor_2={"#8743ff"}
          barColor_1={"#ad3d80"}
        />
      );
    } else if (res.type === "cleaness_current") {
      return (
        <CleanessCurrentResult
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
          graphColor_2={"#8743ff"}
          barColor_1={"#ad3d80"}
        />
      );
    } else if (res.type === "make_mbti_destiny") {
      return (
        <MakeMbtiDestiny
          result={res}
          result_id={id}
          scoop_button_displayed={true}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (res.type === "make_mbti_current") {
      return (
        <MakeMbtiCurrent
          result={res}
          result_id={id}
          scoop_button_displayed={true}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (
      res.type === "compatibility_samgi" ||
      res.type === "compatibility_ohang" ||
      res.type === "compatibility_ddi" ||
      res.type === "compatibility_ilgi"
    ) {
      return (
        <ListFormatOnly
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (
      res.type === "slie_love_current" ||
      res.type === "loyal_rebel_current"
    ) {
      return (
        <ThemaLoveResult
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
          graphColor_2={"#8743ff"}
          barColor_1={"#ad3d80"}
        />
      );
    } else if (
      res.type === "love_tour" ||
      res.type === "love_likeme_style" ||
      res.type === "love_sat_afternoon" ||
      res.type === "love_shopping"
    ) {
      return (
        <MbtiLoveGen
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (
      res.type === "today_love_trouble_lovers" ||
      res.type === "today_love_trouble_couple" ||
      res.type === "today_love_harmony"
    ) {
      return (
        <LoveLucks
          type={res.type}
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (res.type === "today_general_lucks") {
      return (
        <DailyLucks
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (
      res.type === "specific_love_trouble_lovers_month" ||
      res.type === "specific_love_trouble_couples_month"
    ) {
      return <Calendar result={res} result_id={id} />;
    } else if (
      res.type === "dangerous_when_8years" ||
      res.type === "dangerous_when_thisyear"
    ) {
      return (
        <LoveLucksChart
          result={res}

          // otherBirth={otherBirth}
          // secondOtherBirth={secondOtherBirth}
        />
      );
    } else if (res.type === "total_characters") {
      return (
        <PartnersCharacter
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (
      res.type === "marriage_timing_this_year" ||
      res.type === "marriage_timing_10_years"
    ) {
      return <MarriageLucksChart result={res} result_id={id} />;
    } else if (res.type === "stocker") {
      return (
        <Stocker
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (res.type === "violence") {
      return (
        <Violence
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (res.type === "hidden_characters_3") {
      return (
        <HiddenCharactersWithTitle
          result={res}
          result_id={id}
          result_img={HiddenCharacters3Png}
          thumbnail_url={res.thumbnail_url}
          characters={res.characters.상대의_가려진_개성_배우자만_아는}
          title={`결혼 10년 차, 상대의 모습`}
        />
      );
    } else if (res.type === "hidden_characters_4_each") {
      return (
        <HiddenCharacters4Each
          result={res}
          characters={res.characters.가려진_칼집속_칼_개성}
          thumbnail_url={res.thumbnail_url}
          result_id={id}
        />
      );
    } else if (res.type === "hidden_characters_4") {
      return (
        <HiddenCharactersWithTitle
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
          result_img={HiddenCharactersPng}
          characters={res.characters.상대의_가려진_칼집속_칼_개성}
          title="진짜 모습을 드러내라!"
        />
      );
    } else if (
      res.type === "hidden_characters_6_each" ||
      res.type === "hidden_characters_6_1_each"
    ) {
      return (
        <HiddenCharacters6Each
          result={res}
          result_id={id}
          result_img={HidddenCharacters6EachPng}
          thumbnail_url={res.thumbnail_url}
          characters={res.characters}
          title="조심스러운 팩폭 한마디..."
        />
      );
    } else if (res.type === "basic_characters_each") {
      return (
        <BasicCharactersEach
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (res.type === "basic_characters") {
      return (
        <BasicCharacters
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (res.type === "hidden_characters_5_each") {
      return (
        <HiddenCharacters5Each
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (res.type === "hidden_characters_5") {
      return (
        <HiddenCharacters5
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (
      res.type === "hidden_characters_6" ||
      res.type === "hidden_characters_6_1"
    ) {
      return (
        <HiddenCharacters6
          result={res}
          result_id={id}
          result_img={HiddenCharacters6Png}
          thumbnail_url={res.thumbnail_url}
          characters={res?.characters}
          title="이랬다 저랬다..
          그의 진짜 모습은?"
        />
      );
    } else if (res.type === "basic_characters_ohang_7") {
      return (
        <HiddenCharacters
          result={res}
          result_id={id}
          title={"이럼에도 사랑한다면, 우리는 천생연분?"}
          result_img={BasicCharactersOhang7Png}
          characters={res.characters.상대의_극복하기_어려운_정서나_기질}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (res.type === "basic_characters_yukchin_7") {
      return (
        <BasicCharactersYukchin7
          result={res}
          result_id={id}
          title={"어떤 숨겨진 성격이 있을까?"}
          result_img={BasicCharactersYukchin7Png}
          characters={res.characters.상대의_극복하기_어려운_행동_패턴이나_성향}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (res.type === "giving_characters") {
      return (
        <GivingCharacters
          result={res}
          result_id={id}
          characters={res.characters.상대와_관계조성_속성}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (res.type === "yangdari_hate_love") {
      return (
        <YangdariHateLove
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (res.type === "gansori_hate_love") {
      return (
        <GansoriHateLove
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Header />
      <Spacing height={"116px"} />
      {res && renderResultPage(res, "")}
      <Footer />
    </>
  );
};

export default PublicLinkResult;
