import styled from "@emotion/styled";

const Wrapper = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  width: 42px;
  height: 42px;
  margin: 0 0 0 51px;
  border-radius: 16px;
  background-color: blue;
  overflow: hidden;
`;
const ImgProfile = styled.img`
  width: 100%;
`;

export interface ProfileIconProps {
  user_id?: string;
  img_url?: string;
  onClick?: () => void;
}

export default function ProfileIcon({
  user_id,
  img_url,
  onClick,
}: ProfileIconProps) {
  return (
    <Wrapper onClick={onClick} style={onClick ? { cursor: "pointer" } : {}}>
      <ImgProfile src={img_url} />
    </Wrapper>
  );
}
