import btnSearch from "@assets/imgs/btn_search.png";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import { CSSProperties, FC, KeyboardEvent, useState } from "react";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  max-width: 582px;
  min-width: 582px;
  height: auto;
  margin: 0 auto;
  position: relative;

  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
`;

const StyledInput = styled.input`
  font-family: "Noto Sans KR", sans-serif;
  width: calc(100% - 30px);
  height: 54px;
  border-bottom-left-radius: 36px;
  border-top-left-radius: 36px;
  border: solid 2px #d8d8d8;
  background-color: #ffffff;
  outline: none;
  padding-left: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #2d2d2d;
  &::placeholder {
    color: #d8d8d8;
  }
  ${mediaQueries("mobileL")} {
    font-size: 15px;
  }
`;

const BtnSearch = styled.img`
  cursor: pointer;
  position: absolute;
  right: 0;
  height: 54px;
`;

export interface SearchBarProps {
  onSearch: (w: string) => void;
  style?: CSSProperties;
}

const SearchBar: FC<SearchBarProps> = ({ onSearch, style }: SearchBarProps) => {
  const [keyword, setKeyword] = useState("");
  const { t } = useTranslation();

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch(keyword); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };
  return (
    <Wrapper style={style}>
      <StyledInput
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        placeholder={t("search.word")}
        onKeyPress={handleKeyPress}
      />

      <BtnSearch src={btnSearch} onClick={() => onSearch(keyword)} />
    </Wrapper>
  );
};

export default SearchBar;
