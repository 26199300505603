import { BirthModel, deleteBirth, getBirthList } from "@api/births.api";
import icTrashcan from "@assets/imgs/ic_trashcan.png";
import { useBirthsDispatch } from "@contexts/births.context";
import { useCommonDispatch } from "@contexts/common.context";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import moment from "moment";
import { genderTranslation } from "@utils/gender.util";

interface IdxWrapper {
  idx?: number;
}

const Wrapper = styled.div`
  margin-bottom: 12px;
  padding: 1px 35px;
  border-radius: 24px;
  display: flex;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  align-items: center;
  background-color: #ffffff;
  color: #2d2d2d;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  min-height: 60px;
  ${mediaQueries("tablet")} {
    display: block;
    text-align: left;
    padding: 18px 35px;
  }
  ${mediaQueries("mobileL")} {
    padding: 22px 35px;
  }
`;

const NickName = styled.div<IdxWrapper>`
  font-family: "Noto Sans KR", sans-serif;
  text-align: left;
  flex: 3;
  font-size: 20px;
  font-weight: 900;
  color: ${(props) => (props.idx === 0 ? "#8743FF" : "#2d2d2d")};
  ${mediaQueries("mobileL")} {
    margin-bottom: 10px;
  }
`;
const Gender = styled.div<IdxWrapper>`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  color: ${(props) => (props.idx === 0 ? "#8743FF" : "#2d2d2d")};
  flex: 1;
`;
const Age = styled.div<IdxWrapper>`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  color: ${(props) => (props.idx === 0 ? "#8743FF" : "#2d2d2d")};
  flex: 1;
  ${mediaQueries("tablet")} {
    position: absolute;
    margin-top: -20px;
    left: 50%;
  }
`;
const BirthDate = styled.div<IdxWrapper>`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  color: ${(props) => (props.idx === 0 ? "#8743FF" : "#2d2d2d")};
  flex: 2;
  ${mediaQueries("mobileL")} {
    margin-top: 10px;
  }
`;
// const ModifiedAt = styled.div`
//   flex: 2;
//   ${mediaQueries('tablet')} {
//     display: none;
//   }
// `;
const BtnWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${mediaQueries("tablet")} {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
const IcTrashcan = styled.img`
  width: 60px;
  height: 60px;
`;

interface BirthTableRowProps {
  birth: BirthModel;
  idx: number;
}

const BirthTableRow = ({ birth, idx }: BirthTableRowProps) => {
  const birthsDispatch = useBirthsDispatch();
  const commonDispatch = useCommonDispatch();
  const handleDelete = async () => {
    commonDispatch({ type: "SET_IS_LOADING", isLoading: true });
    await deleteBirth({ birth_id: birth?.birth_id ?? "" });
    try {
      const res = await getBirthList({});
      if (!res) return;
      birthsDispatch({ type: "SET_LIST", list: res.births });
    } catch (e) {
      console.log("main list error : ", e);
    } finally {
      commonDispatch({ type: "SET_IS_LOADING", isLoading: false });
    }
  };
  return (
    <Wrapper>
      <NickName idx={idx}>{birth.name}</NickName>
      <Gender idx={idx}>{genderTranslation(birth.gender)}</Gender>
      <Age idx={idx}>
        {moment().year() - moment(birth.birth_datetime).year()}
      </Age>
      <BirthDate idx={idx}>
        {birth.birth_datetime.toString().slice(0, 10)}{" "}
        {birth.birth_datetime.toString().slice(11, 16)}
      </BirthDate>
      {/*<ModifiedAt>{birth.modified_at.toString().slice(0, 10)} {birth.modified_at.toString().slice(11, 16)}</ModifiedAt>*/}
      <BtnWrapper onClick={handleDelete}>
        {idx !== 0 && <IcTrashcan src={icTrashcan} />}
      </BtnWrapper>
    </Wrapper>
  );
};

export default BirthTableRow;
