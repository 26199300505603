import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

interface NextPathHandlerProps {
  nextPath: string;
}

export default function NextPathHandler({ nextPath }: NextPathHandlerProps) {
  const history = useHistory();
  useEffect(() => {
    if (nextPath === '') return;
    history.push(nextPath);
  }, [nextPath]);

  return null;
}