import Loading from "@components/atoms/Loading";

import Footer from "@components/molecules/Footer";
import Header from "@components/molecules/Header";

import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";

import Card from "@components/atoms/Card";
import useHashQuery from "@query/useHashQuery";
import useSearchQuery from "@query/useSearchQuery";
import useRecommendCardsQuery from "@query/useRecommendCardsQuery";

const Search: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const { keyword } = location.state as { keyword: string };

  const { data: resultCards, isLoading: isCardListLoading } = useSearchQuery({
    hashtag: keyword,
  });
  const {
    data: recommendCards,
    isLoading: isRecommendCardsLoadings,
  } = useRecommendCardsQuery({});

  const handleCardClick = (curId: number) => {
    history.push("/cards/" + curId);
  };

  const {
    data: hashTags,
    isLoading: isHashTagsLoading,
    isError: isHashTagsError,
  } = useHashQuery();

  return (
    <>
      {(isCardListLoading || isRecommendCardsLoadings) && <Loading />}
      <Header />
      <div style={{ position: "relative", width: "100%", paddingTop: 56 }}>
        <Body>
          <Content>
            <TitleSection>
              <Title>{t("search.related_to")}</Title>
              <Keyword>{keyword}</Keyword>
            </TitleSection>

            {resultCards &&
            resultCards?.cards.length > 0 &&
            !isCardListLoading ? (
              <Wrapper>
                <Grid>
                  {resultCards.cards.map((info) => (
                    <Card
                      key={"idx-" + info.title + "-" + info.card_id}
                      imgUrl={info.thumbnail_url}
                      description={info.title}
                      onClick={() => handleCardClick(info.card_id!)}
                    />
                  ))}
                </Grid>
              </Wrapper>
            ) : (
              <>
                <NoResult>{t("search.no_result")}</NoResult>
                <Description>{t("search.description")}</Description>
              </>
            )}

            <TitleSection>
              <Title>{t("search.recommendation")}</Title>
            </TitleSection>
            <Wrapper>
              <Grid>
                <>
                  {recommendCards?.cards ? (
                    recommendCards?.cards.map((info) => (
                      <Card
                        key={"idx-" + info.title + "-" + info.card_id}
                        imgUrl={info.thumbnail_url}
                        description={info.title}
                        onClick={() => handleCardClick(info.card_id!)}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </>
              </Grid>
            </Wrapper>
          </Content>
        </Body>
        <SampleKeywordSection>
          <SampleKeywordTitle>{t("search.recommend")}</SampleKeywordTitle>
          <SampleKeywordTitle>{t("search.search_word")}</SampleKeywordTitle>

          <SampleKeywordContent>
            {!isHashTagsLoading || !isHashTagsError ? (
              hashTags?.map((item: any) => {
                return (
                  <SampleKeyword key={item.hashtag_id}>
                    {item.name}
                  </SampleKeyword>
                );
              })
            ) : (
              <></>
            )}
          </SampleKeywordContent>
        </SampleKeywordSection>
      </div>
      <Footer />
    </>
  );
};

const Wrapper = styled.div`
  margin: 20px auto 40px;

  padding-right: 18px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px 30px;
  ${mediaQueries("desktop")} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${mediaQueries("desktopSmall")} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Content = styled.div`
  max-width: 1100px;
  min-width: 1100px;
  @media (max-width: 1400px) {
    max-width: calc(100% - 220px);
    min-width: calc(100% - 220px);
  }
`;

const Body = styled.div`
  min-height: 100vh;
  display: flex;
  padding-top: 51px;
  padding-bottom: 50px;
  margin: 0 auto;
  max-width: 1200px;
  min-width: 1200px;
  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }

  ${mediaQueries("tablet")} {
    display: none;
  }
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.span`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 26px;
  font-weight: 900;
  color: #2d2d2d;
`;
const Keyword = styled.span`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 26px;
  font-weight: 900;
  color: #713bdb;
  margin-left: 5px;
`;

const SampleKeywordSection = styled.div`
  background-color: #ffde59;
  position: absolute;
  right: 0px;
  height: 100%;
  top: 0px;
  min-width: 220px;
  padding-top: 51px;
  padding-left: 20px;
  padding-right: 20px;

  ${mediaQueries("tablet")} {
    display: none;
  }
`;
const SampleKeywordTitle = styled.p`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 26px;
  font-weight: 900;
  text-align: left;
  color: #713bdb;
  margin-bottom: 0px;
  margin-top: 0px;
`;

const SampleKeywordContent = styled.div`
  margin-top: 30px;
`;
const SampleKeyword = styled.p`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #713bdb;
`;
const NoResult = styled.p`
  font-family: Montserrat;
  text-align: left;
  font-size: 60px;
  font-weight: 900;
  color: #2d2d2d;
  margin-top: 35px;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-family: NotoSansCJKkr;
  font-size: 18px;
  font-weight: 300;

  text-align: left;
  color: #2d2d2d;
  margin-bottom: 50px;
`;
export default Search;
