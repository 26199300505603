import axios from "axios";
import apiAgent from "./api.agent";
import config from "./config";

interface GetBirthListReqModel {}
interface GetBirthListResModel {
  births: BirthModel[];
  total_results: number;
}

export interface BirthModel {
  birth_id?: string;
  name: string;
  gender: string;
  latitude: number;
  longitude: number;
  lunar: string;
  is_time_known: boolean;
  birth_datetime: string | Date;
  modified_at?: string;
}

export const getBirthList = async (req: GetBirthListReqModel) => {
  try {
    const { data } = await apiAgent.axios.get<GetBirthListResModel>(
      config.API_URL + "/v1/births"
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};

interface SaveBirthReqModel
  extends Omit<BirthModel, "birth_id" | "modified_at"> {}
interface SaveBirthResModel {}

export const saveBirth = async (req: SaveBirthReqModel) => {
  const token = localStorage.getItem("token");
  if (!token) return;
  try {
    const { data } = await apiAgent.axios.post<SaveBirthResModel>(
      config.API_URL + "/v1/births",
      { birth: req }
    );
    return data;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};

interface DeleteBirthReqModel {
  birth_id: string;
}
interface DeleteBirthResModel {}

export const deleteBirth = async (req: DeleteBirthReqModel) => {
  const token = localStorage.getItem("token");
  if (!token) return;
  try {
    await apiAgent.axios.delete<DeleteBirthResModel>(
      config.API_URL + "/v1/births/" + req.birth_id
    );
    return true;
  } catch (e) {
    console.log("e : ", e);
    return null;
  }
};
