import apiAgent from './api.agent';
import config from './config';

interface PageReqModel {
  sort_by?: 'created_at' | 'payment' | 'coin'
  page: number;
  // results_per_page: number;
}

interface GetOrderHistoryReqModel extends PageReqModel {}
interface GetOrderHistoryResModel {
  page: number;
  results_per_page: number;
  total_results: number;
  orders: OrderModel[];
}

export interface OrderModel {
  id: number;
  coin: number;
  order_id: string;
  capture_id: string;
  created_at: string;
  status: string;
  type: string;
  payment: number;
}

export const getOrderHistory = async (req: GetOrderHistoryReqModel) => {
  try {
    const { data } = await apiAgent.axios.get<GetOrderHistoryResModel>(config.API_URL + '/v1/orders?page=' + req.page);
    return data;
  } catch (e) {
    console.log('e : ', e);
    return null;
  }
};

interface RefundOrderReqModel {
  capture_id: string;
}
interface RefundOrderResModel {
}
export const refundOrder = async (req: RefundOrderReqModel) => {
  try {
    const {data} = await apiAgent.axios.post<RefundOrderResModel>(config.API_URL + '/v1/paypal/' + req.capture_id + '/refund');
    return data;
  } catch (e) {
    alert('failed to refund');
    console.log('e : ', e);
    return 'failure';
  }
};
