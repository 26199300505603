import React, { useReducer, useContext, createContext, Dispatch } from 'react';

type State = {
  isLoading: boolean;
};

type Action =
  | { type: 'SET_IS_LOADING'; isLoading: boolean }

type CommonDispatch = Dispatch<Action>;

const CommonStateContext = createContext<State | null>(null);
const CommonDispatchContext = createContext<CommonDispatch | null>(null);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return {
        ...state,
        ...action
      };
    default:
      throw new Error('Unhandled action');
  }
}

function CommonProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false
  });

  return (
    <CommonStateContext.Provider value={state} >
      <CommonDispatchContext.Provider value={dispatch}>
        {children}
      </CommonDispatchContext.Provider>
    </CommonStateContext.Provider>
  );
}

function useCommonState() {
  const state = useContext(CommonStateContext);
  if (!state) throw new Error('Cannot find UsersProvider'); // 유효하지 않을땐 에러를 발생
  return state;
}

function useCommonDispatch() {
  const dispatch = useContext(CommonDispatchContext);
  if (!dispatch) throw new Error('Cannot find UsersProvider'); // 유효하지 않을땐 에러를 발생
  return dispatch;
}

export {
  CommonProvider,
  useCommonState,
  useCommonDispatch,
}