import styled from "@emotion/styled";

interface ListWithSideBarProps {
  title?: string;
  result: string[];
}

const ListWithSideBar = ({ title, result }: ListWithSideBarProps) => {
  return (
    <Wrapper>
      {title && result.length > 0 && <Title>{title}</Title>}
      <ListFormatOnlyWrapper>
        {result?.map((item) => (
          <ListFormatWrapper>
            <ListFormatText>{item}</ListFormatText>
          </ListFormatWrapper>
        ))}
      </ListFormatOnlyWrapper>
    </Wrapper>
  );
};

const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

const ListFormatOnlyWrapper = styled.div`
  margin-bottom: 50px;
`;
const Wrapper = styled.div`
  text-align: left;
`;
const ListFormatWrapper = styled.div`
  display: flex;
`;
const ListFormatLongDot = styled.div`
  min-width: 10px;
  width: 10px;
  background-color: #8743ff;
  margin-right: 30px;
`;
const ListFormatText = styled.p`
  text-align: left;
  color: #2d2d2d;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.16px;
  margin-top: 0px;
  margin-bottom: 10px;
`;

export default ListWithSideBar;
