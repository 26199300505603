import imgAdd from "@assets/imgs/group_10200@2x.png";
import imgArrowLeft from "@assets/imgs/chevron-down-solid-3@2x.png";
import Input from "@components/atoms/Input";
import { useBirthsDispatch, useBirthsState } from "@contexts/births.context";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import { useEffect, useState } from "react";
import Form from "../Form";
import SearchBar from "../SearchBar";
import moment from "moment";
import { BirthModel, getBirthList, saveBirth } from "@api/births.api";
import { useTranslation } from "react-i18next";
import { genderTranslation } from "@utils/gender.util";
import { CardModel } from "@models/users/Card";
import { BirthType } from "@containers/CardDetail";

interface TableIdx {
  idx: number;
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;
const Dimmed = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(45, 45, 45, 0.7);
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 100px;
  border-radius: 40px;
  max-width: 768px;
  min-width: 768px;
  overflow: scroll;
  max-height: 700px;
  background-color: white;
  ${mediaQueries("desktopSmall")} {
    max-width: calc(100%);
    min-width: calc(100%);
    max-height: calc(100vh - 80px);
    padding: 38px;
    flex-direction: column;
  }
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    max-height: calc(100vh - 0px);
    padding: 20px;
    flex-direction: column;
    border-radius: 0px;
  }
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 900;
  color: #2d2d2d;
  text-align: center;
  ${mediaQueries("desktopSmall")} {
    margin-top: 60px;
    text-align: left;
  }
`;

const BackIconWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: -60px;
  cursor: pointer;
  text-align: left;
  ${mediaQueries("desktopSmall")} {
    background-color: white;
    position: fixed;
    top: 15px;
    left: 30px;
    border-radius: 40px;
  }
  ${mediaQueries("mobileL")} {
    left: 14px;
  }
`;
const BackIcon = styled.img`
  width: 44px;
  height: 44px;
`;

const FormWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
`;

const NickNameLabel = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 0.7;
  letter-spacing: -0.4px;
  color: #2d2d2d;
  margin-bottom: 20px;
  text-align: left;
  margin-top: 20px;
  ${mediaQueries("tablet")} {
    margin-top: 40px;
    font-size: 15px;
  }
  ${mediaQueries("mobileL")} {
    margin-top: 40px;
    font-size: 15px;
  }
`;

interface BirthModalProps {
  show: boolean;
  onPrevClick: () => void;
  current_card: CardModel | null;
  birthType: BirthType;
}

const BirthModalWithoutLogin = ({
  show,
  onPrevClick,
  current_card,
  birthType,
}: BirthModalProps) => {
  const [nickname, setNickname] = useState("");
  const birthsDispatch = useBirthsDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    setNickname("");
  }, [show]);

  const handleSubmit = async (data: any) => {
    if (2025 < data.year || data.year < 1930) {
      alert(t("birth_modal.error_year"));
      return;
    }
    if (!(data.latitude >= 18.5 && data.latitude <= 66.5)) {
      alert(t("birth_modal.latitude_info"));
      return;
    }
    if (data.hour > 24) {
      alert(t("birth_modal.error_hour"));
      return;
    } else if (data.am_pm && data.hour >= 12) {
      alert(t("birth_modal.error_hour"));
      return;
    }

    data.month = data.month - 1;
    const _birthdate = moment({ ...data })
      .format("YYYY-MM-DD HH:mm")
      .toString();

    const birth = {
      name: nickname,
      gender: data.gender,
      latitude: data.latitude,
      longitude: data.longitude,
      lunar: data.lunar,
      is_time_known: false,
      birth_datetime: _birthdate,
    };

    if (!current_card) {
      alert(t("error.exception_error"));
      return;
    }
    if (current_card?.is_required_person) {
      if (birthType === BirthType.OTHER_BIRTH) {
        birthsDispatch({ type: "SET_OTHER_BIRTH", otherBirth: birth });
      } else {
        birthsDispatch({
          type: "SET_SECOND_OTHER_BIRTH",
          secondOtherBirth: birth,
        });
      }
    } else {
      birthsDispatch({ type: "SET_OTHER_BIRTH", otherBirth: birth });
    }
    handleClose();
  };

  const handleClose = () => {
    onPrevClick();
  };

  if (!show) return null;
  return (
    <Wrapper>
      <Dimmed onClick={handleClose} />
      <ModalWrapper>
        <BackIconWrapper>
          <BackIcon src={imgArrowLeft} onClick={handleClose} />
        </BackIconWrapper>

        <FormWrapper>
          {/* <BackIcon src={imgArrowLeft} onClick={() => setShowForm(false)} /> */}
          <Title>{t("birth_modal.title")}</Title>

          <NickNameLabel>{t("birth_modal.nickname")}</NickNameLabel>
          <Input
            style={{
              width: "300px",
              textAlign: "left",
              paddingLeft: 20,
              marginBottom: "20px",
            }}
            value={nickname}
            onValueChange={setNickname}
            placeholder={t("birth_modal.nickname_placeholder")}
          />
          <Form
            onSumbit={handleSubmit}
            txtAlign={"center"}
            agreement_displayed={false}
          />
        </FormWrapper>
      </ModalWrapper>
    </Wrapper>
  );
};

export default BirthModalWithoutLogin;
