import styled from "@emotion/styled";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import VeryHigh from "@assets/imgs/chart_very_high.png";
import High from "@assets/imgs/chart_high.png";
import Soso from "@assets/imgs/chart_soso.png";
import Low from "@assets/imgs/chart_low.png";
import VeryLow from "@assets/imgs/chart_very_low.png";
import Dot from "@assets/imgs/chart_dot.png";
import SolutionDot from "@assets/imgs/chart_solution_dot.png";
import Arrow from "@assets/imgs/chart_arrow.png";
import Up from "@assets/imgs/chart_up.png";
import Down from "@assets/imgs/chart_down.png";
import imgStarCoin from "@assets/imgs/ic_starcoin.png";
import ChartPoints from "@assets/imgs/chart_points.png";

import useChartOption from "./chartOption";
import { mediaQueries } from "@styles/common";
import useWindowWidthQuery from "./useWindowWidthQuery";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { chartIndexState } from "src/atom/chart";
import Button from "@components/atoms/Button";
import { GetCardResultResModel } from "@api/card.result.api";
import icRevision from "@assets/imgs/revision-regular@2x.png";
import ResultShare from "@components/molecules/ResultShare";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { BirthModel } from "@api/births.api";
import usePayForCard from "@hooks/usePayForCard";
const Content = ({
  isPaid,
  isClickedNext,
  chartData,
  setIsClickedNext,
  onClickShowMore,
}: {
  isPaid: boolean;
  isClickedNext: boolean;
  chartData: any[];
  setIsClickedNext: React.Dispatch<React.SetStateAction<boolean>>;
  onClickShowMore: () => void;
}) => {
  const chartIndex = useRecoilValue(chartIndexState);

  const getIcon = useCallback((totalScore: number) => {
    if (totalScore <= 35) {
      return VeryHigh;
    }
    if (totalScore > 35 && totalScore <= 60) {
      return High;
    }
    if (totalScore > 60 && totalScore <= 80) {
      return Soso;
    }
    if (totalScore > 80 && totalScore <= 95) {
      return Low;
    }
    return VeryLow;
  }, []);

  useEffect(() => {
    setIsClickedNext(false);
  }, [chartIndex]);

  if (isClickedNext) {
    return (
      <ContentWrapper>
        <NextTitle>미래를 보는 데에는</NextTitle>
        <NextTitle>대가가 따라요.</NextTitle>
        <ButtonWrapper>
          <Button
            onClick={onClickShowMore}
            style={{ width: "100%", display: "flex", alignItems: "center" }}
          >
            <RequiredStarCoinWrapper>
              <IcStarCoin src={imgStarCoin} />
              <div style={{ display: "flex" }}>
                <TextStarCoin style={{ marginRight: 10 }}>x4</TextStarCoin>
              </div>
            </RequiredStarCoinWrapper>
          </Button>
        </ButtonWrapper>
      </ContentWrapper>
    );
  }
  return (
    <ContentWrapper>
      <ScoreContainer>
        <div>
          <IconImage src={getIcon(chartData[chartIndex]?.result?.종합점수)} />
          <TotalScore>종합지수</TotalScore>
          <TotalScore>{chartData[chartIndex]?.result?.종합점수}</TotalScore>
        </div>

        <ScoreWrapper>
          <div>
            <ScoreTitle>나의 갈등 지수</ScoreTitle>
            <Score>{chartData[chartIndex]?.result?.본인점수}</Score>
          </div>
          <div>
            <ScoreTitle>상대 갈등 지수</ScoreTitle>
            <Score>{chartData[chartIndex]?.result?.상대점수}</Score>
          </div>
        </ScoreWrapper>
      </ScoreContainer>
      <DescriptContainer>
        <DescriptWrapper>
          <div>
            <DotImage src={Dot} />
          </div>

          <Descript>
            <DescriptTitle>내용</DescriptTitle>
            {chartData[chartIndex]?.result?.Text}
          </Descript>
        </DescriptWrapper>
        <DescriptWrapper>
          <div>
            <DotImage src={SolutionDot} />
          </div>

          <Descript>
            <DescriptTitle>솔루션</DescriptTitle>
            {chartData[chartIndex]?.result?.Solution}
          </Descript>
        </DescriptWrapper>
      </DescriptContainer>
      {!isPaid && (
        <ContentWrapper>
          <NextTitle>
            {chartData[chartData.length - 1]?.year}년 이후 4년 더 알아보기
          </NextTitle>

          <ButtonWrapper>
            <Button
              onClick={onClickShowMore}
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <RequiredStarCoinWrapper>
                <IcStarCoin src={imgStarCoin} />
                <div style={{ display: "flex" }}>
                  <TextStarCoin style={{ marginRight: 10 }}>x4</TextStarCoin>
                </div>
              </RequiredStarCoinWrapper>
            </Button>
          </ButtonWrapper>
        </ContentWrapper>
      )}
    </ContentWrapper>
  );
};

interface LoveLucksChartProps {
  result: GetCardResultResModel;
  otherBirth?: BirthModel;
  secondOtherBirth?: BirthModel;
  curId?: number;
}
const LoveLucksChart = ({
  result,
  otherBirth,
  secondOtherBirth,
  curId,
}: LoveLucksChartProps) => {
  const [chartWidth, setChartWidth] = useState<number | string>(0);
  const [chartHeight, setChartHeight] = useState<number | string>(0);
  const [isPaid, setIsPaid] = useState(false);
  const [isClickedNext, setIsClickedNext] = useState(false);

  const history = useHistory();

  const { t } = useTranslation();
  const onMoveToElement = () => {
    readingRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const chartOptions = useChartOption({
    chartData: result?.marriage_lucks,
    onPointsClick: onMoveToElement,
  });

  const windowWidth = useWindowWidthQuery();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const toggleShowMore = () => {
    setShowConfirmModal((prev) => !prev);
  };

  const { getModals } = usePayForCard({
    otherBirth,
    secondOtherBirth,
    cardId: curId === 1028 ? "1030" : "1034",
    result,
    price: 4,
    showConfirmModal,
    toggleShowMore,
    endpoint: "dangerous_when_8years",
    isRequiredPerson: true,
    is임시로직: true,
  });

  const readingRef = useRef<HTMLDivElement>(null);
  const onShowNextClick = () => {
    setIsClickedNext(true);
    onMoveToElement();
  };

  const setChartIndex = useSetRecoilState(chartIndexState);

  useEffect(() => {
    setIsClickedNext(false);
    setChartIndex(0);
  }, [history.location]);

  useEffect(() => {
    if (result?.type === "dangerous_when_8years") {
      setIsPaid(true);
      return;
    }
    setIsPaid(false);
  }, [result?.type]);

  useEffect(() => {
    if (windowWidth < 1200) {
      setChartWidth(isPaid ? windowWidth - 70 : windowWidth - 110);
      setChartHeight(400);
      return;
    }
    setChartWidth(isPaid ? 698 : 658);
    setChartHeight(500);
  }, [isPaid, windowWidth]);

  return (
    <>
      <Wrapper>
        <CardTitle isPaid={isPaid}>
          {isPaid ? "8년 간의 갈등지수" : "지난 4년의 갈등 지수"}
        </CardTitle>
        {!isPaid && (
          <SubTitleContainer>
            <SubTitleBox>
              <img src={ChartPoints} width={30} height={20} />
              <SubTitle>
                선 위의 흰 원을 누르면 연도 별 정보를 볼 수 있어요!
              </SubTitle>
            </SubTitleBox>
            <SubTitleBox>
              <img src={Arrow} width={20} height={20} />
              <SubTitle>
                그래프 끝의 화살표를 누르면 현재부터 4년 후의 갈등지수까지
                확인할 수 있어요!
              </SubTitle>
            </SubTitleBox>
          </SubTitleContainer>
        )}

        <Container>
          <ContainerWithBorder>
            <Badge>
              <BadgeTitle>그래프</BadgeTitle>
            </Badge>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <RangeWrapper>
                <UpWrapper>
                  <RangeCaption style={{ color: "#FF3175" }}>위험</RangeCaption>
                  <UpImage src={Up} />
                </UpWrapper>
                <DownWrapper>
                  <DownImage src={Down} />
                  <RangeCaption style={{ color: "#FFCE00" }}>안전</RangeCaption>
                </DownWrapper>
              </RangeWrapper>
              <Chart
                options={chartOptions}
                series={[
                  {
                    data: result?.marriage_lucks?.map(
                      (item) => item.result.종합점수
                    ),
                  },
                ]}
                type="line"
                width={chartWidth}
                height={chartHeight}
              />
              {!isPaid && (
                <ArrowButtonWrapper>
                  <ArrowButton onClick={onShowNextClick}>
                    <img src={Arrow} width={20} height={30} />
                  </ArrowButton>
                  <p style={{ position: "absolute", bottom: 10 }}>현재</p>
                </ArrowButtonWrapper>
              )}
            </div>
          </ContainerWithBorder>
          <ContainerWithBorder style={{ marginTop: 58 }}>
            <Badge ref={readingRef}>
              <BadgeTitle>리딩</BadgeTitle>
            </Badge>
            <Content
              isPaid={isPaid}
              isClickedNext={isClickedNext}
              setIsClickedNext={setIsClickedNext}
              chartData={result?.marriage_lucks}
              onClickShowMore={toggleShowMore}
            />
          </ContainerWithBorder>
        </Container>
        <ResultShare
          link={result?.link}
          thumbnail_url={result?.thumbnail_url}
          description={result?.description}
        />
      </Wrapper>
      <BottomWrapper style={{ marginTop: "30px" }}>
        <CommentAgain>{result?.comment[3]}</CommentAgain>
        <Button
          onClick={() => history.push(`/cards/${curId}`)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IcRevision src={icRevision} />
          <pre>
            <Text>{t("card_result.button")}</Text>
          </pre>
        </Button>
      </BottomWrapper>
      {getModals()}
      {/* TODO 영/한 카드 번호가 달라서 각각 처리해야함 영문은 각 카드 번호(한글)의 + 1*/}
    </>
  );
};

interface ICardTitle {
  isPaid: boolean;
}
const CardTitle = styled.p<ICardTitle>`
  color: ${({ isPaid }) => (isPaid ? "#FF3175" : "#642ad6")};
  font-size: 24px;
  font-weight: bold;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-width: 768px;
  margin: 0 auto;
  border-radius: 36px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  overflow: hidden;
  padding-bottom: 50px;

  ${mediaQueries("desktop")} {
    max-width: calc(100%);
    min-width: calc(100%);
  }
  ${mediaQueries("tablet")} {
    max-width: calc(100%);
    min-width: calc(100%);
  }
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    margin: 0;
    padding-top: 0;
    padding-bottom: 40px;
  }
`;
const BottomWrapper = styled(Wrapper)`
  padding: 50px 100px;
  ${mediaQueries("mobileL")} {
    padding: 15px;
  }
`;
const IcRevision = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 14px;
`;
const Text = styled.div`
  margin: 3px 0 1px 14px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: -0.36px;
  text-align: center;
  color: #ffffff;
`;
const Container = styled.div`
  display: flex;
  max-width: 1280px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
`;
const SubTitleContainer = styled.div`
  margin-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 50px;
  ${mediaQueries("tablet")} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const SubTitleBox = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const SubTitle = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 30px;
  font-size: 16px;
  color: #000;
  text-align: left;
  margin-top: -2px;
`;

const ContainerWithBorder = styled.div`
  width: 100%;
  border: 1px solid #642ad6;
  border-radius: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
`;

const Chart = styled(ReactApexChart)`
  .apexcharts-tooltip {
    background-color: transparent !important;
    border: transparent !important;
  }
  .apexcharts-point-annotation-label {
    background-color: red;
    border-radius: 25px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-left: 150px;
  padding-right: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaQueries("tablet")} {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const IconImage = styled.img`
  width: 120px;
  height: 120px;
`;

const ScoreContainer = styled.div`
  display: flex;
`;

const ScoreWrapper = styled.div`
  margin-left: 50px;

  ${mediaQueries("mobileL")} {
    margin-left: 30px;
  }
`;
const CommentAgain = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  text-align: center;
  margin-top: 10px;
  color: #2d2d2d;
  /* ${mediaQueries("mobileL")} {
    margin-top: 40px;
  } */
`;

const DescriptContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;
const DescriptWrapper = styled.div`
  display: flex;
  padding-bottom: 20px;
`;
const Descript = styled.span`
  text-align: left;
  padding-left: 20px;
  font-size: 15px;
`;
const DotImage = styled.img`
  width: 15px;
  height: 20px;
  margin-top: 5px;
`;
const TotalScore = styled.p`
  font-size: 20px;
  color: #ff0049;
  font-weight: 700;
  margin-top: 2px;
  margin-bottom: 2px;
`;
const ScoreTitle = styled.p`
  font-size: 20px;
  margin-bottom: 8px;
  margin-top: 5px;
`;
const Score = styled.p`
  font-size: 32px;
  font-family: Montserrat;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 15px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 120px;
  margin: 15px;
`;
const ArrowButtonWrapper = styled.div`
  display: flex;
  width: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const ArrowButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  @keyframes scaleBounce {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.3);
    }
  }
  animation: scaleBounce 0.6s alternate infinite;
`;

const RequiredStarCoinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IcStarCoin = styled.img`
  width: 24px;
  height: 30px;
  margin-right: 5px;
`;
const TextStarCoin = styled.div``;

const DescriptTitle = styled.p`
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 700;
`;

const RangeWrapper = styled.div`
  display: flex;
  width: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const UpWrapper = styled.div`
  position: absolute;
  top: 10px;
`;
const DownWrapper = styled.div`
  position: absolute;
  bottom: 10px;
`;
const RangeCaption = styled.p`
  margin-top: 0px;
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: bold;
`;
const UpImage = styled.img`
  width: 25px;
  height: 22px;
`;
const DownImage = styled.img`
  width: 26px;
  height: 22px;
`;

const Badge = styled.div`
  background: #713bdb;
  border-radius: 20px;
  height: 27px;
  position: absolute;
  left: 13px;
  top: -10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;
const BadgeTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
`;

const NextTitle = styled.p`
  color: #713bdb;
  font-size: 18px;
  font-weight: 900;
  margin-top: 0px;
  margin-bottom: 5px;
  white-space: nowrap;
`;
export default LoveLucksChart;
