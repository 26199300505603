import styled from '@emotion/styled';
import { mediaQueries } from '@styles/common';
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  margin-bottom: 20px;
  padding: 0px 40px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  color: #2d2d2d;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  text-align: center;
  ${mediaQueries('tablet')} {
    display: none;
    /* display: block;
    text-align: left; */
  }
`;

const CoinWrapper = styled.div`
  flex: 1;
`;
const OrderNumber = styled.div`
  flex: 2;
`;
const OrderDate = styled.div`
  flex: 2;
`;
const Status = styled.div`
  flex: 1;
`;
const PaymentMethod = styled.div`
  flex: 1;
  ${mediaQueries('tablet')} {
    display: none;
  }
`;
const Amount = styled.div`
  flex: 1;
`;
const BtnWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${mediaQueries('tablet')} {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

interface NewPaymentTableRowProps {
}

const NewPaymentTableRow = ({ }: NewPaymentTableRowProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <CoinWrapper />
      <OrderNumber>{t('profile.order_number')}</OrderNumber>
      <OrderDate>{t('profile.order_date')}</OrderDate>
      <Status>{t('profile.status')}</Status>
      <PaymentMethod>{t('profile.payment_method')}</PaymentMethod>
      <Amount>{t('profile.amount')}</Amount>
      <BtnWrapper />
    </Wrapper >
  );
};

export default NewPaymentTableRow;
