import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { chartIndexState } from "src/atom/chart";

interface ChartOptionProps {
  chartData: any[];
  onPointsClick: () => void;
}
const useChartOption = ({ chartData, onPointsClick }: ChartOptionProps) => {
  const [result, setResult] = useState<ApexCharts.ApexOptions>({});
  const [chartIndex, setChartIndex] = useRecoilState(chartIndexState);
  const [maxY, setMaxY] = useState<number>();
  const [minY, setMinY] = useState<number>();

  const maxScore = Math.max(
    ...chartData?.map((item) => item?.result?.종합점수)
  );
  const minScore = Math.min(
    ...chartData?.map((item) => item?.result?.종합점수)
  );

  useEffect(() => {
    if (maxScore > 100) {
      setMaxY(Math.ceil(maxScore / 10) * 10 + 10);
      return;
    }
    setMaxY(100);
  }, [chartData, maxScore]);

  useEffect(() => {
    if (minScore < 0) {
      setMinY(Math.ceil((minScore / 10) * 10));
      return;
    }
    setMinY(0);
  }, [minScore, chartData]);

  useEffect(() => {
    setResult({
      chart: {
        events: {
          markerClick: function (
            event,
            chartContext,
            { seriesIndex, dataPointIndex, config }
          ) {
            setChartIndex(dataPointIndex);
            onPointsClick();
          },
        },

        height: 500,
        background: "linear-gradient(to bottom, #3E119C, #FFD770)",
        type: "line",
        zoom: {
          enabled: false,
        },
        selection: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 7,

        colors: `rgba(255,255,255,0.5)`,
        strokeWidth: 0,
      },
      annotations: {
        points: [
          {
            x: chartData[chartIndex]?.year,
            y: chartData[chartIndex]?.result?.종합점수,
            marker: {
              size: 7,
              fillColor: `rgba(255,255,255,0.5)`,
              strokeColor: `rgba(255,255,255,0.5)`,
            },
            label: {
              borderColor: "#FF3175",
              borderRadius: 5,
              offsetY: -3,
              offsetX: chartData.length / 2 < chartIndex ? -35 : 35,
              style: {
                color: "#fff",
                background: "#FF3175",
                fontSize: "15px",
                fontWeight: "700",
              },

              text: `${chartData[chartIndex]?.result?.종합점수},   (${chartData[chartIndex]?.year})`,
            },
          },
        ],
      },

      stroke: {
        width: 8,
        curve: "smooth",
      },

      xaxis: {
        categories: chartData.map((item) => item.year),
        tickAmount: 10,

        labels: {
          style: {
            colors: "#000",
            fontSize: "15px",
          },
        },
        tooltip: {
          enabled: false,
        },

        crosshairs: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        min: minY,
        max: maxY,
        tickAmount: 5,
        decimalsInFloat: 0,
        // forceNiceScale: true,
        labels: {
          style: {
            colors: ["#FF3175"],
            fontSize: "15px",
            fontWeight: "bold",
          },
        },
      },
      tooltip: {
        marker: {
          show: false,
        },

        x: { show: false },
        custom: () => null,
      },

      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },

      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          colorStops: [
            [
              {
                offset: 0,
                color: "#FFCE00",
                opacity: 1,
              },
              {
                offset: 30,
                color: "#FF0099",
                opacity: 1,
              },
              {
                offset: 60,
                color: "#FFCE00",
                opacity: 1,
              },
              {
                offset: 100,
                color: "#FF0099",
                opacity: 1,
              },
            ],
          ],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 50, 100],
        },
      },
    });
  }, [chartIndex, minY, maxY, chartData]);

  return result;
};

export default useChartOption;
