import styled from "@emotion/styled";
import imgStarCoin from "@assets/imgs/ic_starcoin.png";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 13px 6px;
  border-radius: 24px;
  background-color: #8743ff;

  height: 30px;
`;
const ImgStarCoin = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 30px;
`;
const TextValue = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #ffe328;
`;

export interface BalanceProps {
  balance: number;
  onClick?: () => void;
}

export default function Balance({ balance, onClick }: BalanceProps) {
  return (
    <Wrapper onClick={onClick}>
      <ImgStarCoin src={imgStarCoin} />
      <TextValue>{balance}</TextValue>
    </Wrapper>
  );
}
