import { getUserInfo } from '@api/user.api';
import React from 'react';
import { useUsersDispatch } from '../contexts/users.context';

const useBoot = () => {
  const [isGettingInitialData, setIsGettingIntialData] = React.useState<boolean>(true);
  const [nextPath, setNextPath] = React.useState<string>('');
  const dispatch = useUsersDispatch();

  React.useEffect(() => {
    _getToken();
  }, []);

  const _getToken = async () => {
    const user = await getUserInfo({});
    if (user) {
      dispatch({ type: 'SET_USER_INFO', userInfo: user });
      if (!user.is_once_modified) {
        localStorage.removeItem('token');
        // setNextPath('/extraInfo');
      }
    }
    setIsGettingIntialData(false);
  }

  return { isGettingInitialData, nextPath };
}

export default useBoot;