import axios from "axios";
import i18next from "i18next";
import apiAgent from "./api.agent";
import config from "./config";

export const getHash = async () => {
  const lang = i18next.language.slice(0, 2);
  try {
    const { data } = await apiAgent.axios.get(
      config.API_URL + `/v1/hashtags?lang=${lang}`
    );
    return data.hashtags;
  } catch (e) {}
};
