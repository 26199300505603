import { GetCardResultResModel } from "@api/card.result.api";
import icRevision from "@assets/imgs/revision-regular@2x.png";
import ResultShare from "@components/molecules/ResultShare";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import DailyLucksImg from "@assets/imgs/daily_lucks_result.jpg";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import useIsLoggedIn from "@hooks/useIsLoggedIn";

import CardResultLoginForm from "../CardResultLoginForm";

interface DailyLucksResultProps {
  result: GetCardResultResModel;
  result_id: string;
  thumbnail_url: string;
}

const DailyLucks = ({
  result,
  result_id,
  thumbnail_url,
}: DailyLucksResultProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isLoggedIn = useIsLoggedIn();

  return (
    <>
      <Wrapper>
        <TopImg src={DailyLucksImg} />
        <ContentWrapper>
          <MainContentWrapper>
            {result.list_format_only.map((item) => (
              <MainContent>
                <MainSubTitle>{item.split(":")[0]}</MainSubTitle>
                <MainDescription>{item.split(":")[1]}</MainDescription>
              </MainContent>
            ))}
          </MainContentWrapper>
          {isLoggedIn && (
            <ResultShare
              link={result.link}
              thumbnail_url={thumbnail_url}
              description={result.description}
            />
          )}
        </ContentWrapper>
        <CardResultLoginForm type={result.type} />
      </Wrapper>

      <BottomWrapper style={{ marginTop: "30px" }}>
        <CommentAgain>{result.comment[3]}</CommentAgain>
        <Button onClick={() => history.push(`/cards/${result_id}`)}>
          <IcRevision src={icRevision} />
          <pre>
            <Text>{t("card_result.button")}</Text>
          </pre>
        </Button>
      </BottomWrapper>
    </>
  );
};

interface ButtonProps {
  displayed?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-width: 768px;
  margin: 0 auto;
  border-radius: 36px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  overflow: hidden;

  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("tablet")} {
    max-width: calc(100%);
    min-width: calc(100%);
  }
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    margin: 0;
    padding-top: 0;
    padding-bottom: 40px;
  }
`;

const BottomWrapper = styled(Wrapper)`
  padding: 50px 100px;
  ${mediaQueries("mobileL")} {
    padding: 15px;
  }
`;
const ContentWrapper = styled.div`
  padding: 50px 100px;
  ${mediaQueries("mobileL")} {
    padding: 30px 15px 15px;
  }
`;

const CommentAgain = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  text-align: center;
  margin-top: 10px;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    margin-top: 40px;
  }
`;
const Button = styled.div<ButtonProps>`
  margin: 35px 135px 20px;
  height: 50px;
  padding: 25px 35px;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  ${mediaQueries("mobileL")} {
    margin: 35px 22px 20px;
  }
`;
const IcRevision = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 14px;
`;
const Text = styled.div`
  margin: 3px 0 1px 14px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: -0.36px;
  text-align: center;
  color: #ffffff;
`;
const MainContentWrapper = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 100px;
  ${mediaQueries("tablet")} {
    padding-left: 30px;
    padding-right: 30px;

    margin-bottom: 50px;
  }
`;
const MainContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 30px;
  ${mediaQueries("tablet")} {
    flex-direction: column;
    margin-bottom: 15px;
  }
`;
const MainSubTitle = styled.p`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
  color: #2d2d2d;
  min-width: 200px;
  white-space: nowrap;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 0px;
  ${mediaQueries("tablet")} {
    min-width: auto;
    width: 100%;
    margin-top: 0px;
  }
`;
const MainDescription = styled.p`
  font-family: NotoSansCJKkr-Light;
  font-size: 16px;
  color: #2d2d2d;
  margin-bottom: 0px;
  margin-top: 0px;
  ${mediaQueries("tablet")} {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;

const TopImg = styled.img`
  width: 100%;
  height: 100%;
  ${mediaQueries("tablet")} {
    border-bottom-right-radius: 36px;
  }
`;
export default DailyLucks;
