import {
  CardResultDetail,
  getCardResultList,
  searchCardResultList,
} from "@api/card.result.api";
import Loading from "@components/atoms/Loading";
import Spacing from "@components/atoms/Spacing";
import Footer from "@components/molecules/Footer";
import Header from "@components/molecules/Header";
import PageHeader from "@components/molecules/PageHeader";
import SearchBar from "@components/molecules/SearchBar";
import ResultCardTable from "@components/organisms/ResultCardTable";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import * as React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const SearchBarWrapper = styled.div`
  max-width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  color: #2d2d2d;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 64px;
  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
`;

const PAGE_SIZE = 9;

const Results: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [list, setList] = React.useState<CardResultDetail[]>([]);
  const [page, setPage] = React.useState<number>(1);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const history = useHistory();
  const { t } = useTranslation();

  const _fetchResultList = async () => {
    try {
      const res = await getCardResultList({
        page: 1,
        results_per_page: PAGE_SIZE,
      });
      if (!res) return;
      const { cards, total_results } = res;
      setList(cards);
      setTotalCount(total_results);
    } catch (e) {
      console.log("main list error : ", e);
    } finally {
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    _fetchResultList();
  }, []);

  const handleCardClick = (id: string, data: any) => {
    history.push("/results/" + id, data);
  };

  const handleMore = async () => {
    if (totalCount <= PAGE_SIZE * page) return;
    setIsLoading(true);
    try {
      const res = await getCardResultList({
        page: page + 1,
        results_per_page: PAGE_SIZE,
      });
      if (!res) return;
      const { cards } = res;
      setList([...list, ...cards]);
      setPage(page + 1);
    } catch (e) {
      console.log("main list error : ", e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSerach = async (word: string) => {
    setIsLoading(true);
    const res = await searchCardResultList({ query: word });
    setIsLoading(false);
    if (!res) return;
    setList(res.cards);
    setTotalCount(res.total_results);
  };

  if (!localStorage.getItem("token")) {
    history.push("/login");
  }

  return (
    <>
      {isLoading && <Loading />}
      <Header />
      <Spacing height={"116px"} />
      <PageHeader title={t("results.title")} desc={t("results.desc")} />
      <SearchBarWrapper>
        <SearchBar onSearch={handleSerach} style={{ margin: 0 }} />
      </SearchBarWrapper>
      <ResultCardTable
        list={list}
        totalCount={totalCount}
        onCardClick={handleCardClick}
        reload={_fetchResultList}
        onMoreClick={handleMore}
      />
      <Footer />
    </>
  );
};

export default Results;
