import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import { useHistory } from "react-router";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;
const Dimmed = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(45, 45, 45, 0.5);
`;
const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  //align-items: center;
  padding: 45px 100px;
  border-radius: 40px;
  max-width: 768px;
  min-width: 768px;
  background-color: white;
  ${mediaQueries("desktopSmall")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    max-height: calc(100vh - 80px);
    padding: 20px;
    flex-direction: column;
  }
`;
const Title = styled.div`
  font-size: 25px;
  font-weight: 800;
  color: #2d2d2d;
  text-align: center;
  margin-bottom: 20px;
  ${mediaQueries("desktopSmall")} {
    margin-top: 50px;
  }
`;
const Desc = styled.div`
  font-size: 16px;
  font-weight: normal;
  color: #8d8d8d;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
`;
const Button = styled.div`
  padding: 16px 41px;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  border: none;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
`;

interface StarcoinModalProps {
  show?: boolean;
  onPrevClick: () => void;
  title?: string;
  desc?: string;
  buttonDesc?: string;
  cardId?: string;
}

const StarcoinModal = ({
  show,
  title,
  desc,
  buttonDesc,
  onPrevClick,
  cardId,
}: StarcoinModalProps) => {
  const history = useHistory();
  if (!show) {
    return null;
  }

  return (
    <Wrapper>
      <Dimmed onClick={onPrevClick} />
      <ModalWrapper>
        <Title>{title}</Title>
        <Desc>{desc}</Desc>
        <ButtonWrapper>
          <Button
            onClick={() =>
              history.push({ pathname: "/shop", state: { cardId } })
            }
          >
            {buttonDesc}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </Wrapper>
  );
};

export default StarcoinModal;
