import {
  getCardResultDetail,
  GetCardResultResModel,
} from "@api/card.result.api";
import { getRecommendCards } from "@api/cards.api";
import Loading from "@components/atoms/Loading";
import Footer from "@components/molecules/Footer";
import Header from "@components/molecules/Header";
import CardRow from "@components/organisms/CardRow";
import MbtiResult from "@components/organisms/MbtiResult";
import ObesessionResult from "@components/organisms/ObesessionResult";
import styled from "@emotion/styled";
import { CardModel } from "@models/users/Card";
import { mediaQueries } from "@styles/common";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import TwoMbti from "@components/organisms/TwoMbti";
import MakeMbti from "@components/organisms/MakeMbti";
import MakeMbtiDestiny from "@components/organisms/MbtiDestiny";
import MakeMbtiCurrent from "@components/organisms/MbtiCurrent";
import ListFormatOnly from "@components/organisms/ListFormatOnly";
import ThemaLoveResult from "@components/organisms/ThemaLoveResult";
import CleanessCurrentResult from "@components/organisms/CleanessCurrentResult";
import MbtiLoveGen from "@components/organisms/MbtiLoveGen";
import LoveLucks from "@components/organisms/LoveLucks";
import DailyLucks from "@components/organisms/DailyLucks";
import Calendar from "@components/organisms/Calendar";
import LoveLucksChart from "@components/organisms/LoveLucksChart";
import Violence from "@components/organisms/Violence";
import PartnersCharacter from "@components/organisms/PartnersCharacter";
import MarriageLucksChart from "@components/organisms/MarriageLucksChart";
import Stocker from "@components/organisms/Stocker";
import HiddenCharacters from "@components/organisms/HiddenCharacters";
import HiddenCharacters4Each from "@components/organisms/HiddenCharacters4Each";
import BasicCharactersEach from "@components/organisms/BasicCharactersEach";
import BasicCharacters from "@components/organisms/BasicCharacters";
import HiddenCharacters5Each from "@components/organisms/HiddenCharacters5Each";
import HiddenCharacters5 from "@components/organisms/HiddenCharacters5";

//thumbnail
import HiddenCharactersPng from "@assets/imgs/card_results/hidden_characters_4.png";
import HiddenCharacters6Png from "@assets/imgs/card_results/hidden_characters_6.png";
import BasicCharactersOhang7Png from "@assets/imgs/card_results/basic_characters_ohang_7.png";
import BasicCharactersYukchin7Png from "@assets/imgs/card_results/basic_characters_yukchin_7.png";
import HidddenCharacters6EachPng from "@assets/imgs/card_results/hidden_characters_6_each.png";
import HiddenCharacters3EachPng from "@assets/imgs/card_results/hidden_characters_3_each.png";
import HiddenCharacters3Png from "@assets/imgs/card_results/hidden_characters_3.png";
import HiddenCharacters6Each from "@components/organisms/HiddenCharacters6Each";
import HiddenCharacters6 from "@components/organisms/HiddenCharacters6";
import YangdariHateLove from "@components/organisms/YangdariHateLove";
import GansoriHateLove from "@components/organisms/GansoriHateLove";
import HiddenCharactersWithTitle from "@components/organisms/HiddenCharactersWithTitle";
import GivingCharacters from "@components/organisms/GivingCharacters";
import BasicCharactersYukchin7 from "@components/organisms/BasicCharactersYukchin7";
import Spacing from "@components/atoms/Spacing";

const SubCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  //max-width: 900px;
  //min-width: 900px;
  margin: 0 auto;
  ${mediaQueries("desktop")} {
    max-width: calc(100% - 80px);
    min-width: calc(100% - 80px);
  }
`;

interface ParamTypes {
  id: string;
}

const Result: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [res, setRes] = React.useState<
    GetCardResultResModel | null | undefined
  >(null);
  const [recommendCards, setRecommendCards] = React.useState<CardModel[]>([]);
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const location = useLocation() as any;

  const { t } = useTranslation();

  React.useEffect(() => {
    if (!id) return;

    const _getResultDetail = async () => {
      setIsLoading(true);
      const res = await getCardResultDetail({ resultId: id });
      if (!res) {
        setIsLoading(false);
        alert("sth wrong");
        history.goBack();
      } else {
        setRes(res);
        setIsLoading(false);
      }
    };

    const _fetchRecommendCArds = async () => {
      const res = await getRecommendCards(); //TODO: Recommend Card 로 변경해야함.
      if (!res) return;
      setRecommendCards(res.cards);
    };

    _getResultDetail();
    _fetchRecommendCArds();
  }, []);

  const handleCardClick = (curId: number) => {
    history.replace("/cards/" + curId);
  };

  const renderResultPage = (
    type: string,
    res: GetCardResultResModel,
    id: string
  ) => {
    if (type === "mbti-character-merit") {
      return (
        <MbtiResult
          result={res}
          result_id={id}
          type={"mbti-character-merit"}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "mbti-character-demerit") {
      return (
        <MbtiResult
          result={res}
          result_id={id}
          type={"mbti-character-demerit"}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "obsession") {
      return (
        <ObesessionResult
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
          graphColor_1={"#ff4747"}
          graphColor_2={"#8743ff"}
          barColor_1={"#dd2524"}
        />
      );
    } else if (type === "two_make_mbti") {
      return (
        <TwoMbti
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "make_mbti") {
      return (
        <MakeMbti
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "cleaness") {
      return (
        <ObesessionResult
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
          graphColor_1={"#ec6db2"}
          graphColor_2={"#8743ff"}
          barColor_1={"#ad3d80"}
        />
      );
    } else if (type === "cleaness_current") {
      return (
        <CleanessCurrentResult
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
          graphColor_2={"#8743ff"}
          barColor_1={"#ad3d80"}
        />
      );
    } else if (type === "make_mbti_destiny") {
      return (
        <MakeMbtiDestiny
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "make_mbti_current") {
      return (
        <MakeMbtiCurrent
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (
      type === "compatibility_samgi" ||
      type === "compatibility_ohang" ||
      type === "compatibility_ddi" ||
      type === "compatibility_ilgi"
    ) {
      return (
        <ListFormatOnly
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "slie_love_current" || type === "loyal_rebel_current") {
      return (
        <ThemaLoveResult
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
          graphColor_2={"#8743ff"}
          barColor_1={"#ad3d80"}
        />
      );
    } else if (
      type === "love_tour" ||
      type === "love_likeme_style" ||
      type === "love_sat_afternoon" ||
      type === "love_shopping"
    ) {
      return (
        <MbtiLoveGen
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (
      type === "today_love_trouble_lovers" ||
      type === "today_love_trouble_couple" ||
      type === "today_love_harmony"
    ) {
      return (
        <LoveLucks
          type={type}
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "today_general_lucks") {
      return (
        <DailyLucks
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (
      type === "specific_love_trouble_lovers_month" ||
      type === "specific_love_trouble_couples_month"
    ) {
      return <Calendar result={res} result_id={id} />;
    } else if (
      type === "dangerous_when_8years" ||
      type === "dangerous_when_thisyear"
    ) {
      return (
        <LoveLucksChart
          result={res}
          // otherBirth={otherBirth}
          // secondOtherBirth={secondOtherBirth}
        />
      );
    } else if (type === "total_characters") {
      return (
        <PartnersCharacter
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (
      type === "marriage_timing_this_year" ||
      type === "marriage_timing_10_years"
    ) {
      return <MarriageLucksChart result={res} result_id={id} />;
    } else if (type === "stocker") {
      return (
        <Stocker
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "violence") {
      return (
        <Violence
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "hidden_characters_3_each") {
      return (
        <HiddenCharactersWithTitle
          result={res}
          result_id={id}
          result_img={HiddenCharacters3EachPng}
          thumbnail_url={res.thumbnail_url}
          characters={res.characters.본인의_배우자만_아는_가려진개성}
        />
      );
    } else if (type === "hidden_characters_3") {
      return (
        <HiddenCharactersWithTitle
          result={res}
          result_id={id}
          result_img={HiddenCharacters3Png}
          thumbnail_url={res.thumbnail_url}
          characters={res.characters.상대의_가려진_개성_배우자만_아는}
          title={`결혼 10년 차, 상대의 모습`}
        />
      );
    } else if (type === "hidden_characters_4_each") {
      return (
        <HiddenCharacters4Each
          result={res}
          characters={res.characters.가려진_칼집속_칼_개성}
          thumbnail_url={res.thumbnail_url}
          result_id={id}
        />
      );
    } else if (type === "hidden_characters_4") {
      return (
        <HiddenCharactersWithTitle
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
          result_img={HiddenCharactersPng}
          characters={res.characters.상대의_가려진_칼집속_칼_개성}
          title="진짜 모습을 드러내라!"
        />
      );
    } else if (
      type === "hidden_characters_6_each" ||
      type === "hidden_characters_6_1_each"
    ) {
      return (
        <HiddenCharacters6Each
          result={res}
          result_id={id}
          result_img={HidddenCharacters6EachPng}
          thumbnail_url={res.thumbnail_url}
          characters={res.characters}
          title="조심스러운 팩폭 한마디..."
        />
      );
    } else if (type === "basic_characters_each") {
      return (
        <BasicCharactersEach
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "basic_characters") {
      return (
        <BasicCharacters
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "hidden_characters_5_each") {
      return (
        <HiddenCharacters5Each
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "hidden_characters_5") {
      return (
        <HiddenCharacters5
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (
      type === "hidden_characters_6" ||
      type === "hidden_characters_6_1"
    ) {
      return (
        <HiddenCharacters6
          result={res}
          result_id={id}
          result_img={HiddenCharacters6Png}
          thumbnail_url={res.thumbnail_url}
          characters={res?.characters}
          title="이랬다 저랬다..
          그의 진짜 모습은?"
        />
      );
    } else if (type === "basic_characters_ohang_7") {
      return (
        <HiddenCharacters
          result={res}
          result_id={id}
          title={"이럼에도 사랑한다면, 우리는 천생연분?"}
          result_img={BasicCharactersOhang7Png}
          characters={res.characters.상대의_극복하기_어려운_정서나_기질}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "basic_characters_yukchin_7") {
      return (
        <BasicCharactersYukchin7
          result={res}
          result_id={id}
          title={"어떤 숨겨진 성격이 있을까?"}
          result_img={BasicCharactersYukchin7Png}
          characters={res.characters.상대의_극복하기_어려운_행동_패턴이나_성향}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "giving_characters") {
      return (
        <GivingCharacters
          result={res}
          result_id={id}
          characters={res.characters.상대와_관계조성_속성}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "yangdari_hate_love") {
      return (
        <YangdariHateLove
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else if (type === "gansori_hate_love") {
      return (
        <GansoriHateLove
          result={res}
          result_id={id}
          thumbnail_url={res.thumbnail_url}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <Header />
      <Spacing height={"116px"} />
      {res && renderResultPage(res.type, res, id)}
      {/*<Wrapper>*/}
      {/*<ChartTitle>*/}
      {/*  Are you interested in<br/>*/}
      {/*  anyone else’s flavor?*/}
      {/*</ChartTitle>*/}
      {/*<Button onClick={() => console.log("SCOOP HIM / HER")}>*/}
      {/*  <Text>SCOOP HIM / HER</Text>*/}
      {/*</Button>*/}
      {/*</Wrapper>*/}
      <SubCardWrapper>
        <CardRow
          centerTitle={false}
          title={t("card_result.more_cards")}
          list={recommendCards}
          onCardClick={handleCardClick}
        />
      </SubCardWrapper>
      <Footer />
    </>
  );
};

export default Result;
