import { getBirthList } from "@api/births.api";
import Loading from "@components/atoms/Loading";
import Spacing from "@components/atoms/Spacing";
import Footer from "@components/molecules/Footer";
import Header from "@components/molecules/Header";
import PageHeader from "@components/molecules/PageHeader";
import BirthTable from "@components/organisms/BirthTable";
import { useBirthsDispatch, useBirthsState } from "@contexts/births.context";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const Births: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  // const [totalCount, setTotalCount] = React.useState<number>(0);
  const { list } = useBirthsState();
  const birthsDispatch = useBirthsDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  React.useEffect(() => {
    const _fetchBirthList = async () => {
      try {
        const res = await getBirthList({});

        if (!res) return;
        birthsDispatch({ type: "SET_LIST", list: res.births });
        // setTotalCount(res.total_results);
      } catch (e) {
        console.log("main list error : ", e);
      } finally {
        setIsLoading(false);
      }
    };

    _fetchBirthList();
  }, []);

  if (!localStorage.getItem("token")) {
    history.push("/login");
  }

  return (
    <>
      {isLoading && <Loading />}
      <Header />
      <Spacing height={"116px"} />
      <PageHeader title={t("births.title")} desc={t("births.desc")} />
      <BirthTable list={list} />
      <Footer />
    </>
  );
};

export default Births;
