import { BirthModel } from "@api/births.api";
import React, { useReducer, useContext, createContext, Dispatch } from "react";

type State = {
  list: BirthModel[];
  otherBirth: BirthModel | null;
  secondOtherBirth: BirthModel | null;
};

type Action =
  | { type: "SET_LIST"; list: BirthModel[] }
  | { type: "SET_OTHER_BIRTH"; otherBirth: BirthModel | null }
  | { type: "SET_SECOND_OTHER_BIRTH"; secondOtherBirth: BirthModel | null };

type BirthsDispatch = Dispatch<Action>;

const BirthsStateContext = createContext<State | null>(null);
const BirthsDispatchContext = createContext<BirthsDispatch | null>(null);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_LIST":
      return {
        ...state,
        ...action,
      };
    case "SET_OTHER_BIRTH":
      return {
        ...state,
        ...action,
      };
    case "SET_SECOND_OTHER_BIRTH":
      return {
        ...state,
        ...action,
      };
    default:
      throw new Error("Unhandled action");
  }
}

function BirthsProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, {
    list: [],
    otherBirth: null,
    secondOtherBirth: null,
  });

  return (
    <BirthsStateContext.Provider value={state}>
      <BirthsDispatchContext.Provider value={dispatch}>
        {children}
      </BirthsDispatchContext.Provider>
    </BirthsStateContext.Provider>
  );
}

function useBirthsState() {
  const state = useContext(BirthsStateContext);
  if (!state) throw new Error("Cannot find UsersProvider"); // 유효하지 않을땐 에러를 발생
  return state;
}

function useBirthsDispatch() {
  const dispatch = useContext(BirthsDispatchContext);
  if (!dispatch) throw new Error("Cannot find UsersProvider"); // 유효하지 않을땐 에러를 발생
  return dispatch;
}

export { BirthsProvider, useBirthsState, useBirthsDispatch };
