import { AxiosError } from "axios";
import { getHash } from "./../api/hash";
import { useQuery, UseQueryOptions } from "react-query";

const QUERY_KEY = "HASH_TAGS";

interface IUseHashQueryRes {
  hashtag_id: number;
  name: string;
}

const useHashQuery = (
  queryOptions?: UseQueryOptions<IUseHashQueryRes[], AxiosError>
) => {
  return useQuery<IUseHashQueryRes[], AxiosError>(
    QUERY_KEY,
    () => getHash(),
    queryOptions
  );
};
export default useHashQuery;
