import { useEffect, useState } from "react";
import { debounce } from "lodash";
function useWindowWidthQuery() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = debounce(() => {
    setWindowWidth(window.innerWidth);
  }, 200);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize, windowWidth]);
  return windowWidth;
}

export default useWindowWidthQuery;
