import styled from '@emotion/styled';
import Card from '@components/atoms/Card';
import { mediaQueries } from '@styles/common';
import { CardModel } from '@models/users/Card';
import Spacing from "@components/atoms/Spacing";

const Wrapper = styled.div`
  margin: 0 auto 140px;
  max-width: 1200px;
  min-width: 1200px;
  ${mediaQueries('desktop')} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries('mobileL')} {
    max-width: calc(100% - 30px);
    min-width: calc(100% - 30px);
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 33px;
  text-align: center;
  padding: 0px 0 30px 0;
  color: #2d2d2d;
  ${mediaQueries('mobileL')} {
    font-size: 22px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 30px;
  ${mediaQueries('desktop')} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mediaQueries('mobileL')} {
    display: block;
  }
  ${mediaQueries('tablet')} {
    //display: block;
  }
`;

interface CardRowProps {
  title: string;
  centerTitle?: boolean;
  list?: CardModel[];
  // onCardClick: (card: CardModel) => void;
  onCardClick: (id: number) => void;
}

const CardRow = ({ title, centerTitle, list, onCardClick }: CardRowProps) => {
  return (
    <Wrapper>
      <pre>
        <Title style={!!centerTitle ? { textAlign: 'center' } : {}}>{title}</Title>
      </pre>
      <Grid>
        {list && list.map((v) =>
          <Card
            key={'idx-' + title + '-' + v.card_id}
            imgUrl={v.thumbnail_url}
            description={v.title}
            onClick={() => onCardClick(v.card_id!)}
          />
        )}
        {/* {[1, 2, 3].map((v) => <Card key={'idx-' + v} imgUrl={mockImgUrl} />)} */}
      </Grid>
    </Wrapper>
  );
};

export default CardRow;
