import { getRecommendCards, GetRecommendCardsResModel } from "../api/cards.api";
import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";

const QUERY_KEY = "RECOMMEND_RESULT";

type IUseRecommendCardsQueryParams = {
  queryOptions?: UseQueryOptions<GetRecommendCardsResModel, AxiosError>;
};

const useRecommendCardsQuery = ({
  queryOptions,
}: IUseRecommendCardsQueryParams) => {
  return useQuery<GetRecommendCardsResModel, AxiosError>(
    QUERY_KEY,
    () => getRecommendCards(),
    queryOptions
  );
};
export default useRecommendCardsQuery;
