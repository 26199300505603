import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import imgStarCoin from "@assets/imgs/ic_starcoin.png";
import { User } from "@models/users/User";
import moment from "moment";
import Button from "@components/atoms/Button";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const Wrapper = styled.div`
  width: 100%;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60px;
`;
const ProfileImg = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 20px;
  box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.15);
  margin-right: 22px;
`;
const ProfileName = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 30px;
  font-weight: 900;
  color: #000000;
  ${mediaQueries("mobileL")} {
    font-size: 24px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const InfoKey = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #b8b8b8;
  width: 200px;
  ${mediaQueries("mobileL")} {
    font-size: 16px;
  }
`;

const InfoValue = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    font-size: 16px;
  }
`;

const CoinWrapper = styled.div`
  border-radius: 100px;
  background-color: #8743ff;
  padding: 7px 13px;
  width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconCoin = styled.img`
  width: 28px;
  height: 28px;
`;
const CoinText = styled.div`
  padding-right: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #ffe328;
`;

interface MyInfoProps {
  userInfo: User;
}

const MyInfo = ({ userInfo }: MyInfoProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Wrapper>
      <ProfileWrapper>
        <ProfileImg src={"https://picsum.photos/200"} />
        <ProfileName>{userInfo.nickname}</ProfileName>
      </ProfileWrapper>
      <InfoWrapper>
        <InfoKey>{t("profile.starcoin")}</InfoKey>
        <InfoValue>
          <CoinWrapper>
            <IconCoin src={imgStarCoin} />
            <CoinText>
              {(userInfo.normal_token_balance || 0) +
                (userInfo.bonus_token_balance || 0)}
            </CoinText>
          </CoinWrapper>
        </InfoValue>
      </InfoWrapper>
      <InfoWrapper>
        <InfoKey>{t("profile.email")}</InfoKey>
        <InfoValue>{userInfo.email}</InfoValue>
      </InfoWrapper>
      <InfoWrapper>
        <InfoKey>{t("profile.joined")}</InfoKey>
        <InfoValue>
          {moment(userInfo.created_at).format("YYYY-MM-DD")}
        </InfoValue>
      </InfoWrapper>
      <div style={{ textAlign: "center" }}>
        <Button
          style={{
            padding: "13px 70px",
            marginTop: "40px",
            fontFamily: "Noto Sans KR",
            marginBottom: "100px",
          }}
          onClick={() =>
            history.push("/extraInfo", { agreement_displayed: false })
          }
        >
          {t("profile.modify_button").toUpperCase()}
        </Button>
      </div>
    </Wrapper>
  );
};

export default MyInfo;
