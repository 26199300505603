import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { getCardsByCategory, GetCardsByCategoryResModel } from "@api/cards.api";

const QUERY_KEY = "CARDS_BY_CATEGORY";

interface IUseCardsByCategoryQueryParams {
  category: string;
  queryOptions?: UseQueryOptions<GetCardsByCategoryResModel, AxiosError>;
}
const useCardsByCategoryQuery = ({
  category,
  queryOptions,
}: IUseCardsByCategoryQueryParams) => {
  return useQuery<GetCardsByCategoryResModel, AxiosError>(
    [QUERY_KEY, category],
    () => getCardsByCategory({ category }),
    queryOptions
  );
};
export default useCardsByCategoryQuery;
