import Loading from "@components/atoms/Loading";
import CardDetail from "@containers/CardDetail";
import Login from "@containers/Login";
import { useCommonState } from "@contexts/common.context";
import useBoot from "@hooks/useBoot";
import * as React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Home from "src/containers/Home";
import CardResult from "@containers/CardResult";
import Births from "@containers/Births";
import Mypage from "@containers/Mypage";
import Results from "@containers/Results";
import Result from "@containers/Result";
import ScrollToTop from "@components/ScrollToTop";
import Shop from "@containers/Shop";
import ExtraInfo from "@containers/ExtraInfo";
import NextPathHandler from "@components/NextPathHandler";
import Checkout from "@containers/Checkout";
import PublicLinkResult from "@components/organisms/PublicLinkResult";
import TermsOfService from "@containers/TermsOfService";
import PrivacyPolicy from "@containers/PrivacyPolicy";
import Search from "@containers/Search";
import LoveLucksChart from "@components/organisms/LoveLucksChart";

const Root: React.FC = () => {
  const { isGettingInitialData, nextPath } = useBoot();
  const { isLoading } = useCommonState();

  if (isGettingInitialData) return <Loading />;

  return (
    <BrowserRouter>
      {isLoading && <Loading />}
      <ScrollToTop />
      <NextPathHandler nextPath={nextPath} />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/mypage" exact component={Mypage} />
        <Route path="/births" exact component={Births} />
        <Route path="/results/:id" exact component={Result} />
        <Route path="/results" exact component={Results} />
        <Route path="/cards/:id/result" exact component={CardResult} />
        <Route path="/cards/:id" exact component={CardDetail} />
        <Route path="/checkout/:id" exact component={Checkout} />
        <Route path="/shop" exact component={Shop} />
        <Route path="/login" exact component={Login} />
        <Route path="/extraInfo" exact component={ExtraInfo} />
        <Route path="/public-link" exact component={PublicLinkResult} />
        <Route path="/terms-of-service" exact component={TermsOfService} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="/search" exact component={Search} />
        <Redirect path="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Root;
