import styled from "@emotion/styled";
import { useState } from "react";
import starcoin from "../../../assets/imgs/image_starcoin_s.png";
import {mediaQueries} from "@styles/common";

const Wrapper = styled.div`
  width: 340px;
  margin-bottom: 30px;
  border-radius: 24px;
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  cursor: pointer;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  ${mediaQueries('mobileSE')} {
    width: 280px;
  }
`;
const CoinWrapper = styled.div`
  width: 340px;
  text-align: left;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: #ffffff;
  border-radius: 24px;
  border-bottom-right-radius: 0px;
  display: flex;
  align-items: center;
  padding: 15px 35px;
  ${mediaQueries('mobileSE')} {
    width: 280px;
  }
`;
const StyledImg = styled.img`
  width: 44px;
  height: 44px;
  margin-right: 15px;
`;
const TextCoin = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #8d8d8d;
`;
const TextPrice = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #8d8d8d;
  margin-right: 15px;
  padding: 20px;
  text-align: right;
`;

export interface CardProps {
  description?: string;
  total?: number;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  id?: string;
}

export default function Product({
  onClick,
  description,
  total,
  className,
  id,
}: CardProps) {
  const [hover, setHover] = useState(false);
  return (
    <Wrapper
      id={id}
      style={hover ? {
        boxShadow: '0 0 0 6px #713bdb inset',
        backgroundColor: 'white',
      } : {}}
      className={className}
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <CoinWrapper style={hover ? { backgroundImage: 'linear-gradient(103deg, #4938f3 1%, #7f41fd)' } : {}}>
        <StyledImg src={starcoin} />
        <TextCoin style={hover ? { color: 'white' } : {}}>
          x {description}
        </TextCoin>
      </CoinWrapper>
      <TextPrice style={hover ? { color: '#713bdb' } : {}}>{total} <text style={{fontSize: "16px"}}>$</text></TextPrice>
    </Wrapper >
  )
};
