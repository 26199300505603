import Spacing from "@components/atoms/Spacing";
import Footer from "@components/molecules/Footer";
import Header from "@components/molecules/Header";
import SignupForm from "@components/organisms/SignupForm";
import * as React from "react";
import { useHistory } from "react-router-dom";

const ExtraInfo: React.FC = () => {
  const history = useHistory();
  const { location } = history;
  const { state }: any = location;

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) history.replace("/");
  }, []);

  return (
    <>
      <Header />
      <Spacing height={"116px"} />
      <SignupForm agreement_displayed={state.agreement_displayed} />
      <Footer />
    </>
  );
};

export default ExtraInfo;
