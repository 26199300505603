import Loading from "@components/atoms/Loading";
import Spacing from "@components/atoms/Spacing";
import Footer from "@components/molecules/Footer";
import Header from "@components/molecules/Header";
import PageHeader from "@components/molecules/PageHeader";
import MyInfo from "@components/organisms/MyInfo";
import { useUsersState } from "@contexts/users.context";
import * as React from "react";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import MyPageSelector from "@components/molecules/MyPageSelector";
import PaymentHistory from "@components/organisms/PaymentHistory";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const Wrapper = styled.div`
  max-width: 1200px;
  min-width: 1200px;
  min-height: calc(100vh - 800px);
  margin: 0 auto;
  color: #2d2d2d;
  text-align: left;
  margin-bottom: 50px;
  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    min-height: 0px;
  }
`;

const Mypage: React.FC = () => {
  const { userInfo } = useUsersState();
  const [tab, setTab] = React.useState(0);
  const { t } = useTranslation();
  const history = useHistory();

  if (!localStorage.getItem("token")) {
    history.push("/login");
  }

  if (!userInfo) return <Loading />;

  return (
    <>
      <Header />
      <Spacing height={"116px"} />
      <PageHeader title={t("profile.mypage")} />
      <Wrapper>
        <MyPageSelector tab={tab} setTab={setTab} />
        {tab === 0 ? <MyInfo userInfo={userInfo} /> : <PaymentHistory />}
      </Wrapper>
      <Footer />
    </>
  );
};

export default Mypage;
