import InfoPng from "@assets/imgs/info.png";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";

interface InfoTextProps {
  text: string;
}
const InfoText = ({ text }: InfoTextProps) => {
  return (
    <Wrapper>
      <InfoImg src={InfoPng} alt="info icon" />
      <Text>{text}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const InfoImg = styled.img`
  margin-right: 15px;
  margin-top: 5px;
  width: 20px;
  height: 20px;
`;

const Text = styled.span`
  font-size: 20px;
  font-family: "Noto Sans KR", sans-serif;
  ${mediaQueries("mobileL")} {
    font-size: 16px;
  }
`;
export default InfoText;
