// import {useUsersDispatch} from '@contexts/users.context';
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import React from "react";

import { useTranslation } from "react-i18next";

import LoginButtons from "../LoginButtons";

const Wrapper = styled.div`
  margin: 0 auto 200px;
  max-width: 1200px;
  min-width: 1200px;
  text-align: left;
  ${mediaQueries("desktop")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("mobileL")} {
    margin: 0 15px 100px;
  }
`;

const Title = styled.div`
  margin-bottom: 20px;
  text-align: left;
  font-size: 36px;
  font-weight: 900;
  line-height: 1.3;
  letter-spacing: -0.96px;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    margin: 0 10px 20px;
    text-align: center;
    font-size: 30px;
  }
`;

const Description = styled.div`
  margin-bottom: 50px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: -0.32px;
  color: #2d2d2d;
  width: 500px;
  ${mediaQueries("mobileL")} {
    width: 100%;
    margin: 0 10px 70px;
  }
`;

// const PasswordFind = styled.div`
//   font-family: Montserrat;
//   font-size: 16px;
//   font-weight: normal;
//   line-height: 1.63;
//   letter-spacing: -0.32px;
//   color: #b8b8b8;
//   text-decoration: underline;
//   cursor: pointer;
// `;

const LoginForm = () => {
  // const usersDispatch = useUsersDispatch();
  // const navigateToSignup = () => {
  //   usersDispatch({type: 'SET_ADDITINAL_INFO_NEEDED', needed: true});
  // };
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>{t("login.title").toUpperCase()}</Title>
      <Description>{t("login.subtitle")}</Description>

      <LoginButtons />
      {/*<PasswordFind>비밀번호 찾기</PasswordFind>*/}
    </Wrapper>
  );
};

export default LoginForm;
