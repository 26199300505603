import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import styled from "@emotion/styled";
import RightArrow from "@assets/imgs/carousel_right_arrow.png";
import LeftArrow from "@assets/imgs/carousel_left_arrow.png";
import { mediaQueries } from "@styles/common";

const CustomCarousel: React.FC = ({ children }) => {
  const CustomRightArrow = ({ onClick, ...rest }: any) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <RightArrowButton onClick={() => onClick()} style={{}}>
        <ArrowImg src={RightArrow} alt="next page button" />
      </RightArrowButton>
    );
  };
  const CustomLeftArrow = ({ onClick, ...rest }: any) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <LeftArrowButton onClick={() => onClick()} style={{}}>
        <ArrowImg src={LeftArrow} alt="next page button" />
      </LeftArrowButton>
    );
  };
  return (
    <Carousel
      customLeftArrow={<CustomLeftArrow />}
      customRightArrow={<CustomRightArrow />}
      additionalTransfrom={0}
      arrows
      centerMode={false}
      className=""
      containerClass="container"
      dotListClass=""
      focusOnSelect={false}
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover={false}
      draggable={false}
      swipeable={true}
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 100000,
            min: 1200,
          },
          items: 5,
          partialVisibilityGutter: 40,
        },

        tablet: {
          breakpoint: {
            max: 1200,
            min: 600,
          },
          items: 3,
          partialVisibilityGutter: 30,
        },
        mobile: {
          breakpoint: {
            max: 600,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
    >
      {children}
    </Carousel>
  );
};

const RightArrowButton = styled.button`
  position: absolute;
  z-index: 700;
  cursor: pointer;
  right: 20px;

  height: 100%;
  /* opacity: 0.5; */
  border: 0;
  background-color: transparent;
  &:hover img {
    transform: scale(1.4);
  }
`;

const LeftArrowButton = styled.button`
  position: absolute;
  z-index: 700;
  cursor: pointer;
  left: 20px;

  height: 100%;
  /* opacity: 0.5; */
  border: 0;
  background-color: transparent;

  &:hover img {
    transform: scale(1.4);
  }
`;

const ArrowImg = styled.img`
  width: 40px;
  height: 50px;
  opacity: 1;
  transition: all 0.2s linear;
`;
export default CustomCarousel;
