import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import imgArrowLeft from "@assets/imgs/ic_arrow_left.png";
import { useTranslation } from "react-i18next";
import { useScript } from "@hooks/useScript";
import { useHistory } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useUsersDispatch, useUsersState } from "@contexts/users.context";
import { postPaypalCapture, postPaypalOrder } from "@api/paypal.api";
import config from "@api/config";
import { ProductsModel } from "@models/products/Product";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;
const Dimmed = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(45, 45, 45, 0.5);
`;
const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 100px;
  border-radius: 40px;
  max-width: 582px;
  min-width: 582px;
  max-height: calc(100vh - 80px);
  overflow-y: scroll;
  background-color: white;
  ${mediaQueries("tablet")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    max-height: calc(100vh - 80px);
    padding: 40px;
    flex-direction: column;
  }
`;
const TitleWrapper = styled.div`
  width: 100%;
  margin-bottom: 25px;
`;
const Title = styled.div`
  text-align: left;
  font-size: 36px;
  font-weight: 900;
  color: #2d2d2d;
  margin-top: 25px;
`;
const DescWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const DescTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const DescContentWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;
const DescContentNumber = styled.div`
  font-size: 36px;
  font-weight: bold;
  margin-right: 5px;
`;
const DescContentUnit = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const Separator = styled.div`
  width: 100%;
  height: 1.5px;
  margin: 18px 0;
  background-color: #eaeaea;
`;
const MethodOfPayment = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
`;

const BackIconWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: -60px;
  cursor: pointer;
  text-align: left;
  ${mediaQueries("desktopSmall")} {
    background-color: white;
    position: fixed;
    top: 15px;
    left: 0px;
    border-radius: 40px;
  }
`;
const BackIcon = styled.img`
  width: 44px;
  height: 44px;
`;

declare global {
  interface Window {
    paypal: any;
  }
}

interface CheckoutModalProps {
  show?: boolean;
  onPrevClick: () => void;
  product: ProductsModel;
  cardId?: string;
}

const CheckoutModal = ({
  show,
  onPrevClick,
  product,
  cardId,
}: CheckoutModalProps) => {
  const { t } = useTranslation();
  const [paypalLoaded, paypalError] = useScript(
    `https://www.paypal.com/sdk/js?client-id=${config.PAYPAL_CLIENT_ID}&currency=USD`
  );
  const history = useHistory();
  const paypalRef = useRef(null);
  const { userInfo } = useUsersState();
  const usersDispatch = useUsersDispatch();

  // const {location} = history;
  // const {product}: any = location.state;

  useEffect(() => {
    if (paypalLoaded) _initPaypal(window.paypal);
  }, [paypalLoaded]);

  const _initPaypal = (windowPaypal: any) => {
    if (!windowPaypal) return;

    windowPaypal
      .Buttons({
        createOrder: async () => {
          const orderReq = { id: product.id! };
          const resp = await postPaypalOrder(orderReq);
          if (!resp) {
            alert("paypal에 문제가 생겼습니다.");
            return;
          }
          return resp.order_id;
        },
        onApprove: async (data: any) => {
          const captureReq = { id: product.id!, order_id: data.orderID };

          const resp = await postPaypalCapture(captureReq);

          if (!userInfo) return;
          const user_normal_starcoin_balance =
            userInfo.normal_token_balance || 0;
          userInfo.normal_token_balance =
            user_normal_starcoin_balance +
            (product.normal_starcoin_increment || 0);
          usersDispatch({ type: "SET_USER_INFO", userInfo: userInfo });

          if (cardId) {
            // history.push(`/cards/${cardId}`);
            history.goBack();
            return;
          }
          history.push("/home");
        },
        onErr: (err: any) => {
          alert("paypal에 문제가 생겼습니다.");
          console.log(err);
        },
      })
      .render(paypalRef.current);
  };

  if (!show) return null;

  return (
    <Wrapper>
      <Dimmed onClick={onPrevClick} />
      <ModalWrapper>
        <BackIconWrapper>
          <BackIcon src={imgArrowLeft} onClick={onPrevClick} />
        </BackIconWrapper>
        <TitleWrapper>
          <Title>
            {product.normal_starcoin_increment +
              product.bonus_starcoin_increment}{" "}
            Coins
          </Title>
        </TitleWrapper>
        <DescWrapper>
          <DescTitle>TOTAL</DescTitle>
          <DescContentWrapper>
            <DescContentNumber>{product.total}</DescContentNumber>
            <DescContentUnit>USD $</DescContentUnit>
          </DescContentWrapper>
        </DescWrapper>
        <Separator />
        <MethodOfPayment>Method of Payment</MethodOfPayment>
        {paypalLoaded && !paypalError && (
          <div style={{ textAlign: "center", width: "100%" }} ref={paypalRef} />
        )}
      </ModalWrapper>
    </Wrapper>
  );
};

export default CheckoutModal;
