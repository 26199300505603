import { GetCardResultResModel } from '@api/card.result.api';
import imgArrowLeft from '@assets/imgs/ic_arrow_left.png';
import icRevision from "@assets/imgs/ic_revision.png";
import ResultShare from '@components/molecules/ResultShare';
import styled from "@emotion/styled";
import { mediaQueries } from '@styles/common';
import React from 'react';
import { useHistory } from 'react-router';

interface ButtonProps {
  displayed?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-width: 768px;
  margin: 0 auto;
  border-radius: 36px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;

  ${mediaQueries('desktop')} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
`;
const Title = styled.div`
  margin: 80px 0 70px 0;
  font-size: 40px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: -0.4px;
  text-align: center;
  color: #2d2d2d;
`;
const TwoMbtiDescWrapper = styled.div`
  margin: 0 0 15px 50px;
  max-width: 768px;
  min-width: 768px;
  ${mediaQueries('desktop')} {
    margin: 0 0 0 50px;
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
`
const TwoMbtiDescTitle = styled.div`
  margin: 0 0 10px 0;
  width: 280px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  text-align: left;
  color: #2d2d2d;
  ${mediaQueries('desktop')} {
    font-size: 16px;
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
`;

const Button = styled.div<ButtonProps>`
  margin: 50px 50px 47px;
  padding: 25px 35px;
  border-radius: 24px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  display: ${props => props.displayed? "flex" : "none"};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
`
const IcRevision = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 14px;
  //margin-top: 14px;
`
const Text = styled.div`
  margin: 3px 0 1px 14px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: -0.36px;
  text-align: center;
  color: #ffffff;
`;
const BackIcon = styled.img`
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  left: -60px;
  cursor: pointer;
`;

const GraphWrapper = styled.div`
  padding: 0 100px;
  ${mediaQueries('tablet')} {
    padding: 0 20px;
  }
`;
const GraphPercentageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const GraphTitle = styled.div`
  font-size: 16px;
  font-weight: 900;
  color: #2d2d2d;
  text-align: left;
  margin-bottom: 15px;
`;
interface GraphBarProps {
  percentage: number;
  backgroundColor: string;
}
const GraphBar = styled.div<GraphBarProps>`
  height: 30px;
  width: ${props => `${(props.percentage / 100) * 100}%`};
  background-color: ${props => props.backgroundColor || 'red'};
  margin-bottom: 25px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
`;
const GraphPercentage = styled.div`
  font-size: 16px;
  font-weight: 900;
  color: #2d2d2d;
  text-align: left;
`

interface ObesessionResultProps {
  result: GetCardResultResModel;
  result_id: string;
  scoop_button_displayed?: boolean;
  thumbnail_url: string;
}

const TwoMbti = ({ result, result_id, scoop_button_displayed, thumbnail_url }: ObesessionResultProps) => {
  const history = useHistory();

  return (
      <Wrapper>
        <BackIcon src={imgArrowLeft} onClick={() => history.goBack()} />
        <Title>{result.title[0]}</Title>
        <div>
          내 mbti {result.born.mbti[0]}
        </div>
        <div>
          상대방 mbti {result.born.mbti[1]}
        </div>
        <div>
          설명: {result.born.desc}
        </div>
        <div>
          내 mbti {result.born.mbti[0]}
        </div>
        <div>
          운 적용
        </div>
        <div>
          내 mbti {result.withluck.mbti[0]}
        </div>
        <div>
          상대방 mbti {result.withluck.mbti[1]}
        </div>
        <div>
          설명: {result.withluck.desc}
        </div>
        <br/>
        <br/>
        <TwoMbtiDescWrapper>
          <TwoMbtiDescTitle>
            {result.comment[1]}
          </TwoMbtiDescTitle>
          <br/>
          <TwoMbtiDescTitle>
            {result.comment[2]}
          </TwoMbtiDescTitle>
        </TwoMbtiDescWrapper>
        <ResultShare  link={result.link} thumbnail_url={thumbnail_url} description={result.description}/>
        <Button onClick={() => history.push(`/cards/${result_id}`)} displayed={scoop_button_displayed}>
          <IcRevision src={icRevision} />
          <Text>SCOOP AGAIN</Text>
        </Button>
      </Wrapper>
  )
}

export default TwoMbti;
