import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";

const Wrapper = styled.div`
  max-width: 1200px;
  min-width: 1200px;
  color: #2d2d2d;
  text-align: left;
  margin: 0 auto 36px;
  ${mediaQueries("desktop")} {
    max-width: calc(100%);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("tablet")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
  }
  ${mediaQueries("mobileL")} {
    margin: 0 15px;
    padding: 0 10px;
  }
`;

const Title = styled.pre`
  font-size: 36px;
  font-weight: 900;
  line-height: 1.3;
  letter-spacing: -0.96px;
  margin-top: 0px;
  margin-bottom: 20px;
  white-space: normal;
  ${mediaQueries("mobileL")} {
    margin-bottom: 40px;
  }
`;

const Desc = styled.pre`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  margin-top: 12px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: -0.32px;
  white-space: pre-wrap;
  ${mediaQueries("tablet")} {
  }
  ${mediaQueries("mobileL")} {
    margin-bottom: 50px;
  }
`;

interface PageHeaderProps {
  title: string;
  desc?: string;
}

const PageHeader = ({ title, desc }: PageHeaderProps) => {
  return (
    <Wrapper>
      <Title>{title.toUpperCase()}</Title>
      {desc && <Desc>{desc}</Desc>}
    </Wrapper>
  );
};

export default PageHeader;
