import { getOrderHistory, OrderModel } from "@api/orders.api";
import { useCommonDispatch } from "@contexts/common.context";
import { useEffect, useState } from "react";

const useOrderHistories = () => {
  const commonDispatch = useCommonDispatch();
  const [list, setList] = useState<OrderModel[]>([]);
  // const [list, setList] = useState<OrderModel[]>(mockOrders);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  // const [totalCount, setTotalCount] = useState<number>(25);

  useEffect(() => {
    commonDispatch({ type: "SET_IS_LOADING", isLoading: true });
    fetchList(pageIndex);
    // console.log('page ins de: ', pageIndex);
    // setList(mockOrders.slice((pageIndex - 1) * 5, pageIndex * 5));
  }, [pageIndex]);

  const fetchList = async (pIndex: number) => {
    const data = await getOrderHistory({ page: pIndex });
    if (!data) return;
    const { orders, total_results } = data;
    setList(orders);
    setTotalCount(total_results);
    commonDispatch({ type: "SET_IS_LOADING", isLoading: false });
  };

  const refreshList = async () => {
    await fetchList(0);
    setPageIndex(0);
  };

  return { list, pageIndex, setPageIndex, totalCount, refreshList };
};

export default useOrderHistories;
