import { getBirthList, saveBirth } from "@api/births.api";
import imgArrowLeft from "@assets/imgs/chevron-down-solid-3@2x.png";
import Input from "@components/atoms/Input";
import { useBirthsDispatch } from "@contexts/births.context";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import moment from "moment";
import { useEffect, useState } from "react";
import Form from "../Form";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;
const Dimmed = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(45, 45, 45, 0.7);
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 100px;
  border-radius: 40px;
  max-width: 768px;
  min-width: 768px;
  background-color: white;
  max-height: 700px;
  overflow: scroll;
  ${mediaQueries("desktopSmall")} {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    max-height: calc(100vh - 80px);
    padding: 20px;
    flex-direction: column;
  }
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    max-height: calc(100vh - 0px);
    flex-direction: column;
    border-radius: 0px;
  }
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 900;
  color: #2d2d2d;
  text-align: center;
  ${mediaQueries("desktopSmall")} {
    margin-top: 50px;
    text-align: left;
  }
  ${mediaQueries("mobileL")} {
    margin-top: 80px;
  }
`;

const Spacing = styled.div`
  height: 10px;
`;
const BackIconWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: -60px;
  cursor: pointer;
  text-align: left;
  ${mediaQueries("desktopSmall")} {
    background-color: white;
    position: fixed;
    top: 15px;
    left: 0px;
    border-radius: 40px;
  }
  ${mediaQueries("mobileL")} {
    background-color: white;
    position: fixed;
    top: 15px;
    left: 10px;
    border-radius: 40px;
  }
`;
const BackIcon = styled.img`
  width: 44px;
  height: 44px;
`;

const FormWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
`;

const NickNameLabel = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 0.7;
  letter-spacing: -0.4px;
  color: #2d2d2d;
  margin-bottom: 12px;
  text-align: left;
  margin-top: 40px;
`;

interface BirthFormModalProps {
  show: boolean;
  onPrevClick: () => void;
}
const BirthFormModal = ({ show, onPrevClick }: BirthFormModalProps) => {
  const [nickname, setNickname] = useState("");
  const birthsDispatch = useBirthsDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setNickname("");
  }, [show]);

  if (!show) return null;

  const handleSubmit = async (data: any) => {
    if (2025 <= data.year || data.year < 1930) {
      alert(t("birth_modal.error_year"));
      return;
    }
    if (!(data.latitude >= 18.5 && data.latitude <= 66.5)) {
      alert(t("birth_modal.latitude_info"));
      return;
    }
    if (data.hour > 24) {
      alert(t("birth_modal.error_hour"));
      return;
    } else if (data.am_pm && data.hour >= 12) {
      alert(t("birth_modal.error_hour"));
      return;
    }
    data.month = data.month - 1;
    const _birthdate = moment({ ...data })
      .format("YYYY-MM-DD HH:mm")
      .toString();
    const payload = {
      ...data,
      is_time_known: data.isTimeKnown,
      name: nickname,
      birth_datetime: _birthdate,
    };
    const res = await saveBirth(payload);
    if (!res) return alert(t("error.save_birth_error"));

    const _l = await getBirthList({});
    if (!_l) return;
    birthsDispatch({ type: "SET_LIST", list: _l.births });
    onPrevClick();
  };

  const handleClose = () => {
    onPrevClick();
  };

  return (
    <Wrapper>
      <Dimmed onClick={handleClose} />
      <ModalWrapper>
        <BackIconWrapper>
          <BackIcon src={imgArrowLeft} onClick={handleClose} />
        </BackIconWrapper>
        <FormWrapper>
          <Title>{t("birth_modal.title").toUpperCase()}</Title>
          <NickNameLabel>{t("birth_modal.nickname")}</NickNameLabel>
          <Input
            style={{ width: "300px", textAlign: "left", paddingLeft: 20 }}
            value={nickname}
            onValueChange={setNickname}
            placeholder={t("birth_modal.nickname_placeholder")}
          />
          <Spacing />
          <Form
            onSumbit={handleSubmit}
            txtAlign={"center"}
            agreement_displayed={false}
          />
        </FormWrapper>
      </ModalWrapper>
    </Wrapper>
  );
};

export default BirthFormModal;
