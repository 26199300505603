import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import icRevision from "@assets/imgs/revision-regular@2x.png";
import {
  addDays,
  endOfMonth,
  format,
  getMonth,
  getYear,
  isSameDay,
  isSameMonth,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import styled from "@emotion/styled";
import getWeatherIcon from "@utils/getWeatherIcon";
import ArrowRight from "@assets/imgs/next_month.png";
import ArrowLeft from "@assets/imgs/prev_month.png";
import Board from "@assets/imgs/board.png";
import BoardKo from "@assets/imgs/board_ko.png";
import {
  getCalendarCardResult,
  GetCardResultResModel,
} from "@api/card.result.api";
import { mediaQueries } from "@styles/common";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ResultShare from "@components/molecules/ResultShare";

const DAY_OF_THE_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const DAY_OF_THE_WEEK_KO = ["일", "월", "화", "수", "목", "금", "토"];
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MONTHS_KO = [
  "1월",
  "2월",
  "3월",
  "4월",
  "5월",
  "6월",
  "7월",
  "8월",
  "9월",
  "10월",
  "11월",
  "12월",
];
interface CalendarProps {
  result: GetCardResultResModel;
  result_id: string;
}

const Calendar: React.FC<CalendarProps> = ({ result, result_id }) => {
  const [index, setIndex] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
  const history = useHistory();

  const baseDate = new Date(result?.month_lucks[index][0].day);
  const { t } = useTranslation();
  const isKo = window.navigator.language.includes("ko");

  const curMonth = getMonth(baseDate) + 1;
  const curYear = getYear(baseDate);

  const getIsIcon = (day: Date) => {
    const obj = result?.month_lucks[index]?.find(
      (item) => item.day === format(day, "yyyy-MM-dd")
    );
    return obj ? getWeatherIcon(obj.score) : undefined;
  };

  function takeWeek(start = new Date()) {
    let date = startOfWeek(startOfDay(start)); //2022-2-26

    return function () {
      const week = [...Array(7)].map((_, index) => addDays(date, index));
      date = addDays(week[6], 1);

      return week;
    };
  }

  function lastDayOfRange(range: Date[][]) {
    return range[range.length - 1][6];
  }

  function takeMonth(start = new Date()) {
    let month: Date[][] = [];
    let date = start;

    return function () {
      const weekGen = takeWeek(startOfMonth(date));
      const endDate = startOfDay(endOfMonth(date));
      month.push(weekGen());

      while (lastDayOfRange(month) < endDate) {
        month.push(weekGen());
      }

      const range = month;
      month = [];
      date = addDays(lastDayOfRange(range), 1);

      return range;
    };
  }

  const hasData = (date: string) => {
    return (
      result?.month_lucks[index]?.findIndex(
        (item) => item.day === format(new Date(date), "yyyy-MM-dd")
      ) > -1
    );
  };
  const goToCardDetail = async (date: string) => {
    if (!hasData(date)) {
      return;
    }
    const res = await getCalendarCardResult({
      cardResultId: result.card_result_id,
      date,
    });

    history.push("/cards/" + result.card_result_id + "/result", {
      res: {
        ...res,
        type: "today_love_trouble_lovers",
      },
      required_starcoin: 0,
    });
  };

  return (
    <>
      <Wrapper>
        <Container>
          <CalendarContainer>
            <ExplanationBoard>
              <ExplanationImg src={isKo ? BoardKo : Board} />
            </ExplanationBoard>
            <YearBadge>
              {index !== 0 && (
                <ArrowButton
                  style={{ position: "absolute", left: 15 }}
                  onClick={() => {
                    swiperInstance?.slidePrev();
                  }}
                >
                  <ArrowImg alt="prev_button" src={ArrowLeft} style={{}} />
                </ArrowButton>
              )}
              <YearCaption>
                {`${
                  isKo ? MONTHS_KO[curMonth - 1] : MONTHS[curMonth - 1]
                }, ${String(curYear)}`}
              </YearCaption>

              {index !== result?.month_lucks.length - 1 && (
                <ArrowButton
                  style={{ position: "absolute", right: 15 }}
                  onClick={() => {
                    swiperInstance?.slideNext();
                  }}
                >
                  <ArrowImg alt="next_button" src={ArrowRight} />
                </ArrowButton>
              )}
            </YearBadge>

            <DescriptionText>{t("calendar.description")}</DescriptionText>
            <SwiperContainer
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              onSlideChange={(swiper) => {
                setIndex(swiper.activeIndex);
              }}
            >
              {result.month_lucks.map((item, index) => {
                return (
                  <SwiperSlide>
                    <CalendarSection>
                      <DayOfTheWeekSection>
                        {(isKo ? DAY_OF_THE_WEEK_KO : DAY_OF_THE_WEEK).map(
                          (text: string, index: number) => (
                            <DayOfTheWeek key={index}>
                              <DayOfWeekText>{text}</DayOfWeekText>
                            </DayOfTheWeek>
                          )
                        )}
                      </DayOfTheWeekSection>

                      {takeMonth(new Date(item[0].day))().map(
                        (week: any, index: number) => {
                          return (
                            <DayOfTheWeekSection key={index}>
                              {week.map((day: any, index2: number) => {
                                return (
                                  <Day key={index2}>
                                    <DayBox>
                                      <DayText>
                                        {isSameMonth(day, baseDate)
                                          ? format(day, "d")
                                          : ""}
                                      </DayText>
                                    </DayBox>
                                    <IconContainer
                                      isToday={
                                        isSameDay(day, new Date()) &&
                                        isSameMonth(day, baseDate)
                                      }
                                    >
                                      <div>
                                        <IconBox
                                          isToday={
                                            isSameDay(day, new Date()) &&
                                            isSameMonth(day, baseDate)
                                          }
                                          isIcon={!!getIsIcon(day)}
                                        >
                                          {isSameMonth(day, baseDate) && (
                                            <IconWrapper
                                              haveData={hasData(
                                                format(day, "yyyy-MM-dd")
                                              )}
                                              onClick={() => {
                                                goToCardDetail(
                                                  format(day, "yyyy-MM-dd")
                                                );
                                              }}
                                            >
                                              {getIsIcon(day) && (
                                                <Icon
                                                  src={getIsIcon(day)}
                                                  alt="icon"
                                                />
                                              )}
                                            </IconWrapper>
                                          )}
                                        </IconBox>
                                        {isSameDay(day, new Date()) &&
                                          isSameMonth(day, baseDate) && (
                                            <TodayText>
                                              {t("calendar.today")}
                                            </TodayText>
                                          )}
                                      </div>
                                    </IconContainer>
                                  </Day>
                                );
                              })}
                            </DayOfTheWeekSection>
                          );
                        }
                      )}
                    </CalendarSection>
                  </SwiperSlide>
                );
              })}
            </SwiperContainer>
          </CalendarContainer>
        </Container>
        <ResultShare
          link={result.link}
          thumbnail_url={result.thumbnail_url}
          description={result.description}
        />
      </Wrapper>
      <BottomWrapper style={{ marginTop: "30px" }}>
        <CommentAgain>{result.comment[3]}</CommentAgain>
        <Button onClick={() => history.push(`/cards/${result_id}`)}>
          <IcRevision src={icRevision} />
          <pre>
            <Text>{t("card_result.button")}</Text>
          </pre>
        </Button>
      </BottomWrapper>
    </>
  );
};

const SwiperContainer = styled(Swiper)`
  &.swiper {
    margin-left: 0px;
    margin-right: 0px;
  }
`;
const Wrapper = styled.div`
  /* width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 200px; */
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-width: 768px;
  margin: 0 auto;
  border-radius: 36px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  overflow: hidden;

  ${mediaQueries("desktop")} {
    max-width: calc(100%);
    min-width: calc(100%);
  }
  ${mediaQueries("tablet")} {
    max-width: calc(100%);
    min-width: calc(100%);
  }
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    margin: 0;
    padding-top: 0;
    padding-bottom: 40px;
  }
`;
const Container = styled.div`
  display: flex;
  max-width: 1280px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;
  border-bottom-width: 1px;
  border-bottom-color: #dddddd;
  padding-left: 26px;
  padding-right: 26px;
`;
const ExplanationBoard = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  margin-top: 50px;
  ${mediaQueries("tablet")} {
    margin-top: 20px;
    margin-bottom: 50px;
  }
`;
const ExplanationImg = styled.img`
  width: 100%;
`;
const DescriptionText = styled.p`
  font-weight: 600;
  font-size: 15px;
  text-align: left;
  ${mediaQueries("tablet")} {
    font-size: 12px;
  }
`;
const YearBadge = styled.div`
  background-color: #713bdb;
  border-radius: 15px;
  position: relative;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: 300px;
  ${mediaQueries("tablet")} {
    height: 32px;
    max-width: 200px;
    border-radius: 10px;
  }
`;

const YearCaption = styled.span`
  font-size: 24px;
  font-weight: 600;
  width: 250px;
  color: #fff;
  ${mediaQueries("tablet")} {
    font-size: 16px;
  }
`;
const DayOfWeekText = styled.span`
  font-weight: 700;
  font-size: 20px;
  font-family: Montserrat, sans-serif;
  ${mediaQueries("tablet")} {
    font-size: 16px;
  }
`;

const CalendarSection = styled.div`
  width: 100%;
  margin-top: 16px;
  flex-direction: column;
  display: flex;
`;
const DayOfTheWeekSection = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
`;

const DayOfTheWeek = styled.div`
  width: calc(100% / 7);
  justify-content: center;
  flex-direction: row;
  display: flex;
  padding-bottom: 12px;
`;
const Day = styled.div`
  width: calc(100% / 7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  border-top: 1px solid #642ad685;
`;
const DayText = styled.span`
  font-weight: 600;
  font-size: 18px;
  font-family: Montserrat, sans-serif;

  ${mediaQueries("tablet")} {
    font-size: 15px;
  }
`;

const DayBox = styled.div`
  width: 100%;
  display: flex;
  background: #c0b2dc78;
  height: 30px;
  justify-content: center;
  align-items: center;
  ${mediaQueries("tablet")} {
    height: 0px;
    background-color: transparent;
    padding-top: 5px;
  }
`;
interface IIconContainer {
  isToday: boolean;
}
const IconContainer = styled.div<IIconContainer>`
  min-height: 150px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: ${({ isToday }) => (isToday ? 15 : 0)}px;
  ${mediaQueries("tablet")} {
    min-height: 100px;
    padding-top: ${({ isToday }) => (isToday ? 20 : 0)}px;
  }
`;
interface IIconBox {
  isToday: boolean;
  isIcon: boolean;
}
const IconBox = styled.div<IIconBox>`
  display: flex;
  align-items: center;

  justify-content: center;
  background: ${({ isIcon }) => (!isIcon ? " #c0b2dc47" : "transparent")};
  border-radius: 30px;
  width: ${({ isToday }) => (isToday ? 88 : 90)}px;
  height: ${({ isToday }) => (isToday ? 88 : 90)}px;
  border: ${({ isToday }) => (isToday ? `3px solid #642AD6` : "none")};
  ${mediaQueries("tablet")} {
    width: 36px;
    height: 36px;
    border-radius: 15px;
  }
`;
interface IIconWrapper {
  haveData: boolean;
}
const IconWrapper = styled.div<IIconWrapper>`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: ${({ haveData }) => (haveData ? "pointer" : "")};
  width: 90px;
  height: 90px;

  ${mediaQueries("tablet")} {
    width: 36px;
    height: 36px;
  }
`;
const Icon = styled.img`
  width: 70px;
  height: 70px;
  ${mediaQueries("tablet")} {
    width: 36px;
    height: 36px;
  }
`;
const TodayText = styled.span`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
  color: #642ad6;
  ${mediaQueries("tablet")} {
    font-size: 12px;
    line-height: 18px;
  }
`;

const ArrowButton = styled.button`
  background: transparent;
  cursor: pointer;
  padding: 0;
  border: none;
  width: 18px;
  height: 20px;
`;
const ArrowImg = styled.img`
  width: 18px;
  height: 20px;
  ${mediaQueries("tablet")} {
    width: 10px;
    height: 10px;
  }
`;

const CommentAgain = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  text-align: center;
  margin-top: 10px;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    margin-top: 40px;
  }
`;
const Button = styled.div`
  margin: 35px 135px 20px;
  height: 50px;
  padding: 25px 35px;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  ${mediaQueries("mobileL")} {
    margin: 35px 22px 20px;
  }
`;
const IcRevision = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 14px;
`;
const Text = styled.div`
  margin: 3px 0 1px 14px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: -0.36px;
  text-align: center;
  color: #ffffff;
`;

const BottomWrapper = styled(Wrapper)`
  padding: 50px 100px;
  ${mediaQueries("mobileL")} {
    padding: 15px;
  }
`;

export default Calendar;
