import styled from "@emotion/styled";
import { jsx, css, keyframes } from "@emotion/react";

const Hop = keyframes`
 	100% {
 			transform: rotate(360deg)
 	}
`;

const Comp = styled.div`
  pointer-events: none;
  width: 2.5em;
  height: 2.5em;
  border: 0.4em solid transparent;
  border-color: #eee;
  border-top-color: #3e67ec;
  border-radius: 50%;
  animation: ${Hop} 1s linear infinite;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  top: 0px;
`;

const Loading = () => {
  return (
    <Wrapper>
      <Comp />
    </Wrapper>
  );
};

export default Loading;
