import { SearchCardsResModel } from "./../api/cards.api";
import { searchCards } from "@api/cards.api";
import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";

const QUERY_KEY = "SEARCH_RESULT";

type IUseSearchQueryParams = {
  hashtag: string;
  queryOptions?: UseQueryOptions<SearchCardsResModel, AxiosError>;
};

const useSearchQuery = ({ hashtag, queryOptions }: IUseSearchQueryParams) => {
  return useQuery<SearchCardsResModel, AxiosError>(
    [QUERY_KEY, hashtag],
    () => searchCards({ hashtag }),
    queryOptions
  );
};
export default useSearchQuery;
