import imgFacebookShare from "@assets/imgs/ic_facebook_share.png";
import imgKakaoShare from "@assets/imgs/kakaotalk.png";
import imgTwitterShare from "@assets/imgs/twitter.png";
import imgShare from "@assets/imgs/share-alt-solid@2x.png";
import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { mediaQueries } from "@styles/common";
import config from "@api/config";

const ShareDesc = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  margin-top: 47px;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: -0.44px;
  text-align: center;
  color: #713bdb;
`;
const ShareWrapper = styled.div`
  //border-top: solid 1.5px #eaeaea;
  padding: 25px 25px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mediaQueries("mobileL")} {
    padding-top: 10px;
  }
`;
const ShareImg = styled.img`
  max-width: 48px;
  height: 48px;
  margin-right: 15px;
`;
const ShareText = styled.div`
  width: 80px;
  ${mediaQueries("mobileL")} {
    display: none;
  }
`;
const ShareButtonWrapper = styled.div`
  padding: 0 40px;
  font-size: 15px;
  font-weight: bold;
  color: #713bdb;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Splitter = styled.div`
  width: 1px;
  height: 48px;
  background-color: #eaeaea;
`;

interface ResultShareProps {
  link: string;
  thumbnail_url: string;
  description: string;
}

const ResultShare = ({
  link,
  thumbnail_url,
  description,
}: ResultShareProps) => {
  const { t } = useTranslation();
  const url = window.location.protocol + "//" + window.location.host;

  React.useEffect(() => {
    onClickKakao();
  }, []);

  const handleClick = async () => {
    await navigator.clipboard.writeText(url + "/public-link?link=" + link);
    alert(t("share.temp"));
  };

  const onClickFacebook = () => {
    const u = `${url}/public-link?link=${link}`;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${u}`);
  };

  const onClickTwitter = () => {
    const u = `${url}/public-link?link=${link}`;
    window.open(
      `https://twitter.com/intent/tweet?original_referer=${u}&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&url=${u}&via=\nSpoon5`
    );
  };

  const onClickKakao = () => {
    const u = `${url}/public-link?link=${link}`;
    if (window.Kakao) {
      const kakao = window.Kakao;
      if (!kakao.isInitialized()) {
        kakao.init(config.KAKAO_KEY);
      }
      kakao.Link.createDefaultButton({
        container: "#kakao-link-btn",
        objectType: "feed",
        content: {
          title: "Spoon5",
          description: description,
          imageUrl: thumbnail_url, // i.e. process.env.FETCH_URL + '/logo.png'
          link: {
            mobileWebUrl: u,
            webUrl: u,
          },
        },
        buttons: [
          {
            title: "웹으로 보기",
            link: {
              mobileWebUrl: u,
              webUrl: u,
            },
          },
        ],
      });
    }
  };

  return (
    <>
      <pre>
        <ShareDesc>{t("card_result.share_title")}</ShareDesc>
      </pre>
      <ShareWrapper>
        <ShareButtonWrapper id="kakao-link-btn">
          <ShareImg src={imgKakaoShare} />
          <ShareText>KakaoTalk</ShareText>
        </ShareButtonWrapper>
        <Splitter />
        {/* <ShareButtonWrapper onClick={() => onClickFacebook()}>
          <ShareImg src={imgFacebookShare} />
          <ShareText>Facebook</ShareText>
        </ShareButtonWrapper> */}
      </ShareWrapper>
      <ShareWrapper>
        <ShareButtonWrapper onClick={() => onClickTwitter()}>
          <ShareImg src={imgTwitterShare} />
          <ShareText>Twitter</ShareText>
        </ShareButtonWrapper>
        <Splitter />
        <ShareButtonWrapper onClick={handleClick}>
          <ShareImg src={imgShare} />
          <ShareText>Copy URL</ShareText>
        </ShareButtonWrapper>
      </ShareWrapper>
    </>
  );
};

export default ResultShare;
