import imgArrowLeft from "@assets/imgs/chevron-down-solid-3@2x.png";
import starcoin from "@assets/imgs/image_starcoin_s.png";
import styled from "@emotion/styled";
import { mediaQueries } from "@styles/common";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;
const Dimmed = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(45, 45, 45, 0.5);
`;
const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 100px;
  border-radius: 40px;
  max-width: 582px;
  min-width: 582px;
  background-color: white;
  ${mediaQueries("tablet")} {
    border-radius: 0px;
    max-width: 100%;
    min-width: 100%;
    height: 100vh;
    padding: 40px;
    flex-direction: column;
  }
`;
const SubTitle = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 22px;
  font-weight: 900;
  color: #2d2d2d;
  margin: 20px 0;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  /* max-width: 300px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto 0;
`;
const Button = styled.div`
  padding: 12px 41px;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  color: white;
  border: none;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 700;
  flex: 1;
  height: 50px;
`;

const StyledImg = styled.img`
  width: 44px;
  height: 44px;
`;

const BackIconWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: -60px;
  cursor: pointer;
  text-align: left;
  ${mediaQueries("desktopSmall")} {
    position: fixed;
    top: 15px;
    left: 0;
    border-radius: 40px;
  }
  ${mediaQueries("mobileL")} {
    position: fixed;
    top: 15px;
    left: 11px;
    border-radius: 40px;
  }
`;
const BackIcon = styled.img`
  width: 44px;
  height: 44px;
`;

interface NotEnoughCoinModalProps {
  show?: boolean;
  onPrevClick: () => void;
  onConfirmClick: () => void;
}

const NotEnoughCoinModal = ({
  show,
  onPrevClick,
  onConfirmClick,
}: NotEnoughCoinModalProps) => {
  const { t } = useTranslation();

  if (!show) return null;

  return (
    <Wrapper>
      <Dimmed onClick={onPrevClick} />
      <ModalWrapper>
        <BackIconWrapper>
          <BackIcon src={imgArrowLeft} onClick={onPrevClick} />
        </BackIconWrapper>
        <StyledImg src={starcoin} />
        <SubTitle>{t('not_enough_coin_modal.title')}</SubTitle>
        <ButtonWrapper>
          <Button onClick={onConfirmClick}>{t('not_enough_coin_modal.button')}</Button>
        </ButtonWrapper>
      </ModalWrapper>
    </Wrapper>
  );
};

export default NotEnoughCoinModal;
