import Spacing from "@components/atoms/Spacing";
import Footer from "@components/molecules/Footer";
import Header from "@components/molecules/Header";
import LoginForm from "@components/organisms/LoginForm";
import SignupForm from "@components/organisms/SignupForm";
import { useUsersState, useUsersDispatch } from "@contexts/users.context";
import * as React from "react";
import { useLocation } from "react-router";

const Login: React.FC = () => {
  const location = useLocation();
  const { additionalInfoNeeded } = useUsersState();
  const usersDispatch = useUsersDispatch();

  React.useEffect(() => {
    if (location.hash === "" && additionalInfoNeeded) {
      usersDispatch({ type: "SET_ADDITINAL_INFO_NEEDED", needed: false });
    }
  }, [location.hash, additionalInfoNeeded]);

  return (
    <>
      <Header />
      <Spacing height={"116px"} />
      {additionalInfoNeeded ? (
        <SignupForm agreement_displayed={true} />
      ) : (
        <LoginForm />
      )}
      <Footer />
    </>
  );
};

export default Login;
