import {GetCardResultResModel} from "@api/card.result.api";
import imgArrowLeft from "@assets/imgs/chevron-down-solid-3@2x.png";
import icRevision from "@assets/imgs/revision-regular@2x.png";
import ResultShare from "@components/molecules/ResultShare";
import styled from "@emotion/styled";
import {mediaQueries} from "@styles/common";
import React from "react";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";

interface ButtonProps {
  display?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-width: 768px;
  margin: 0 auto;
  padding: 50px 100px;
  border-radius: 36px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;

  ${mediaQueries("desktop")} {
    max-width: calc(100%);
    min-width: calc(100%);
  }
  ${mediaQueries("tablet")} {
    padding: 25px;
  }
  ${mediaQueries("mobileL")} {
    max-width: calc(100%);
    min-width: calc(100%);
    margin: 0;
    padding: 15px;
  }
`;
const MainTitle = styled.div`
  font-size: 36px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: -0.4px;
  margin-top: 30px;
  margin-bottom: 60px;
  text-align: center;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    margin: 100px 10px 40px 10px;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 900;
  line-height: 1.5;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: white;
  margin-bottom: 40px;
`;
const CommentAgain = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  text-align: center;
  margin-top: 10px;
  color: #2d2d2d;
  ${mediaQueries("mobileL")} {
    margin-top: 40px;
  }
`;
const Button = styled.div<ButtonProps>`
  margin: 35px 135px 20px;
  height: 50px;
  padding: 25px 35px;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to left, #8743ff, #4136f1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  ${mediaQueries("mobileL")} {
    margin: 35px 22px 20px;
  }
`;
const IcRevision = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 14px;
`;
const Text = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  margin: 3px 0 1px 14px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: -0.36px;
  text-align: center;
  color: #ffffff;
`;
const BackIcon = styled.img`
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  left: -60px;
  cursor: pointer;
`;

const Board = styled.div`
  border-radius: 36px;
  background-color: #fff;
  padding: 60px 50px;
  margin-bottom: 80px;
  ${mediaQueries("mobileL")} {
    padding: 60px 34px;
  }
`;

const Comment = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 2;
  text-align: left;
  color: #2d2d2d;
  margin-bottom: 80px;
  ${mediaQueries("mobileL")} {
    margin: 0 10px 80px 10px;
  }
`;

const CommentTitle = styled.div`
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #2d2d2d;
  margin-bottom: 20px;
  ${mediaQueries("mobileL")} {
    margin: 0px 10px 15px 10px;
  }
`;

const GraphWrapper = styled.div``;
const GraphPercentageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const GraphTitle = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: white;
  text-align: left;
  margin-bottom: 4px;
`;

const GraphBackground = styled.div`
  flex: 1;
  background-color: white;
  margin-right: 30px;
  border-radius: 8px;
  height: 16px;
`;
interface GraphDescProps {
  percentage: number;
}
const GraphDesc = styled.div<GraphDescProps>`
  flex: 1;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: white;
  text-align: left;
  margin-bottom: 40px;
`;

interface GraphBarProps {
  percentage: number;
  backgroundColor?: string;
}

const GraphBar = styled.div<GraphBarProps>`
  width: ${(props) => `${(props.percentage / 100) * 100}%`};
  height: 16px;
  background-color: ${(props) => props.backgroundColor || "red"};
  border-radius: 8px;
`;
const GraphPercentage = styled.div`
  font-family: Montserrat, sans-serif;
  letter-spacing: 0.5px;
  width: 30px;
  font-size: 22px;
  font-weight: bold;
  color: white;
  text-align: center;
`;

const ScoreRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ScoreTitle = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  width: 70px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  text-align: left;
  margin-right: 40px;
  ${mediaQueries("mobileL")} {
    width: 100px;
    margin-right: 0px;
  }
`;
const ScoreValue = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.07px;
  text-align: left;
  color: #fff;
`;
const Separator = styled.div`
  height: 40px;
  width: 100%;
`;

interface ObesessionResultProps {
  result: GetCardResultResModel;
  result_id: string;
  scoop_button_displayed?: boolean;
  thumbnail_url: string;
  graphColor_1: string;
  graphColor_2: string;
  barColor_1: string;
}

const ObesessionResult = ({
  result,
  result_id,
  scoop_button_displayed,
  thumbnail_url,
  graphColor_1,
  graphColor_2,
  barColor_1,
}: ObesessionResultProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
      <>
        <Wrapper>
          <BackIcon src={imgArrowLeft} onClick={() => history.goBack()}/>
          <MainTitle>{result.title[0]}</MainTitle>
          <Comment>{result.title[1]}</Comment>
          <Board style={{backgroundColor: graphColor_1}}>
            <Title>{result.title && result.title[2].toUpperCase()}</Title>
            {result.graph[0].born.map((g, idx) => (
                <GraphWrapper key={"graph-" + idx}>
                  <GraphTitle>{g.title}</GraphTitle>
                  <GraphPercentageWrapper>
                    <GraphBackground style={{backgroundColor: barColor_1}}>
                      <GraphBar percentage={g.percentage} backgroundColor={"white"}/>
                    </GraphBackground>
                    <GraphPercentage>{g.percentage}</GraphPercentage>
                  </GraphPercentageWrapper>
                  <GraphDesc percentage={g.percentage} >
                    {g.desc}
                  </GraphDesc>
                </GraphWrapper>
            ))}
            <Separator/>
            {result.score_scoop && result.score_scoop.map((scoop) => (
                <ScoreRow>
                  <ScoreTitle>{scoop.title}</ScoreTitle>
                  <ScoreValue>{scoop.desc}</ScoreValue>
                </ScoreRow>
            ))}
          </Board>
          <Comment>{result.comment && result.comment[0]}</Comment>
          <Board style={{backgroundColor: graphColor_2}}>
            <Title>{result.title && result.title[3].toUpperCase()}</Title>
            {result.graph[1].withluck.map((g, idx) => (
                <GraphWrapper key={"graph-" + idx}>
                  <GraphTitle>{g.title}</GraphTitle>
                  <GraphPercentageWrapper>
                    <GraphBackground style={{backgroundColor: "#713bdb"}}>
                      <GraphBar percentage={g.percentage} backgroundColor={"white"}/>
                    </GraphBackground>
                    <GraphPercentage>{g.percentage}</GraphPercentage>
                  </GraphPercentageWrapper>
                  <GraphDesc percentage={g.percentage} >
                    {g.desc}
                  </GraphDesc>
                </GraphWrapper>
            ))}
          </Board>
          {/*<Comment>{result.comment && result.comment[1]}</Comment>*/}
          <CommentTitle>{t('card_result.closing_comment')}</CommentTitle>
          <Comment>{result.comment && result.comment[2]}</Comment>
          <ResultShare link={result.link} thumbnail_url={thumbnail_url} description={result.description}/>
        </Wrapper>
        <Wrapper style={{marginTop: '30px'}}>
          <CommentAgain>{result.comment[3]}</CommentAgain>
          <Button
              onClick={() => history.push(`/cards/${result_id}`)}
              display={scoop_button_displayed}
          >
            <IcRevision src={icRevision}/>
            <pre>
              <Text>{t('card_result.button')}</Text>
            </pre>
          </Button>
        </Wrapper>
      </>
  );
};

export default ObesessionResult;
